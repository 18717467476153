import { environment } from '@env';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import ArcGISMap from '@arcgis/core/Map.js';
import MapView from '@arcgis/core/views/MapView.js';
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';
import { EquipmentService } from '../../core/services/data/equipment.service';
import { VfmBaseComponent } from '../VfmBaseComponent';
import { takeUntil } from 'rxjs';
import { Device } from 'src/app/data';
import * as moment from 'moment';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent extends VfmBaseComponent implements AfterViewInit {
  @Input() isPopup: boolean = false;
  @Input() deviceId: string = '';

  momentJs = moment;
  device: any;

  constructor(private equipService: EquipmentService) {
    super();
  }

  ngAfterViewInit(): void {
    this.equipService
      .getLastPositionByDeviceId(this.site.code, this.deviceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((device: any) => {
        console.log('device', device);
        this.loadMap(device);
      });
  }

  loadMap(device: any) {
    setTimeout(() => {
      const map = new ArcGISMap({
        basemap: 'satellite',
      });

      const view = new MapView({
        map: map,
        container: 'map',
        center: [device.longitude, device.latitude],
        zoom: 17,
      });

      view.ui.remove('zoom');

      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      const point = new Point({
        longitude: device.longitude,
        latitude: device.latitude,
      });

      const simpleMarkerSymbol = {
        type: 'simple-marker',
        color: [226, 119, 40], // Orange
        outline: {
          color: [255, 255, 255], // White
          width: 1,
        },
      };

      const pointGraphic = new Graphic({
        symbol: simpleMarkerSymbol,
        geometry: point,
      });

      graphicsLayer.add(pointGraphic);

      view.when(() => {});
    }, 500);
  }
}
