import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EquipmentService } from '../../../../../../core/services/data/equipment.service';
import { CategoryService } from '@app/services/data/category.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, takeUntil, Subject, debounceTime, map } from 'rxjs';
import { Equipment, PaginatedItems, Category } from 'src/app/data';

@Component({
  selector: 'app-reservation-equipment-select',
  templateUrl: './reservation-equipment-select.component.html',
  styleUrls: ['./reservation-equipment-select.component.scss'],
})
export class ReservationEquipmentSelectComponent
  extends VfmBaseComponent
  implements OnInit
{
  favEquipments = false;
  crewEquipments = false;
  isFavorite = false;
  selectedItem!: Equipment;
  categories: Category[] | undefined;
  equipments!: Equipment[];
  categoryId: string = '';
  searchText: string = '';
  term = new Subject<string>();

  category: any[] = [
    { value: 'truck', viewValue: 'truck' },
    { value: 'car', viewValue: 'car' },
  ];

  expandedIndex = 0;

  constructor(
    public dialogRef: MatDialogRef<ReservationEquipmentSelectComponent>,
    private equipmentService: EquipmentService,
    private catService: CategoryService
  ) {
    super();

    this.term.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchEquipments();
    });
  }

  ngOnInit(): void {
    this.searchEquipments();
    this.loadCategories();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(this.selectedItem);
  }

  selectEquipment(item: Equipment) {
    this.selectedItem = item;
  }

  toggleAccordion(event: Event) {
    event.stopImmediatePropagation();
  }

  loadCategories() {
    this.catService
      .getCategories(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((equipments) => {
        this.equipments = equipments.data;
      });
  }

  clearCategory(e: any) {
    e.stopImmediatePropagation();
    this.categoryId = '';

    this.searchEquipments();
  }

  clearSearchText(e: any) {
    e.stopImmediatePropagation();
    this.searchText = '';

    this.searchEquipments();
  }

  searchEquipments() {
    this.equipmentService
      .searchEquipment(
        this.site.code,
        this.searchText,
        this.categoryId,
        '',
        true,
        this.isFavorite
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.equipments = result.data;
      });
  }
}
