<div class="page-title">
    <h3>
        <a *ngIf="showChevron && route && !actionFn" aria-label="back-link" class="back" [routerLink]="route"
            [queryParams]="queryParams">
            <i class="back-link fas fa-fw fa-angle-left"></i>
        </a>

        <a *ngIf="showChevron && actionFn && !route" aria-label="back-link" class="back" (click)="onClick()">
            <i class="back-link fas fa-fw fa-angle-left"></i>
        </a>

        <i [class]="iconClass + ' title-icon-class'"></i>
        <span class="page-title-label">{{title}}</span>
        <div *ngIf="actionTemplate">
            <ng-container [ngTemplateOutlet]="actionTemplate"></ng-container>
        </div>

        <ng-content select="[content]"></ng-content>
    </h3>
</div>