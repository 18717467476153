import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '@env';
import { msalConfig } from './auth-config';

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const api_base_url = environment.backendBaseUrl;
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${api_base_url}`, [
   environment.AccessScope, 
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}
