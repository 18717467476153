import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import {
  MbscEventcalendarOptions,
  MbscCalendarEvent,
  setOptions,
  MbscDatepickerOptions,
  localeEn,
  localeFr,
} from '@mobiscroll/angular';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserProfile } from 'src/app/data';
import { SHIFTS } from 'src/app/core/constants/shifts.constants';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Shift, ShiftTemplate } from 'src/app/data';
import { ShiftService } from '@app/services/data/shift.service';
import { SiteService } from '@app/services/data/site.service';

interface ListItem {
  id: number;
  title: string;
  tooltip: string;
  icon: string;
}

@Component({
  selector: 'app-working-shift-edit',
  templateUrl: './working-shift-edit.component.html',
  styleUrls: ['./working-shift-edit.component.scss'],
})
export class WorkingShiftEditComponent
  extends VfmBaseComponent
  implements OnInit
{
  pageTitle = 'Edit Working Shift';
  pageIconClass = 'fa-solid fa-pen';
  startDate: any = null;
  endDate: any = null;
  settingsForm!: FormGroup;
  isSubmitted = false;
  userProfile!: UserProfile;
  locale = this.profile.language === 0 ? localeEn : localeFr;
  list: ListItem[] = SHIFTS;
  shiftsTemplate!: ShiftTemplate[];

  currentId: any;

  datepickerOptDate: MbscDatepickerOptions = {
    select: 'date',
    controls: ['calendar'],
    onCellClick: (event: any, inst: any) => {
      this.startDate = moment(event.date);

      if (this.currentId) {
        this.shiftService
          .getShiftById(this.currentId, this.startDate.format(), 0)
          .subscribe((result: any) => {
            this.mapWorkshiftWithMobiScroll(result);
          });
      }
    },
  };

  datepickerOptTime: MbscDatepickerOptions = {
    select: 'range',
    controls: ['time'],
    stepMinute: 5,
  };

  responsiveCal: any = {
    xsmall: {
      controls: ['calendar'],
      display: 'bottom',
      touchUi: true,
    },
    small: {
      controls: ['calendar'],
      display: 'anchored',
      touchUi: true,
    },
    custom: {
      // Custom breakpoint
      breakpoint: 800,
      controls: ['calendar'],
      display: 'anchored',
      touchUi: false,
    },
  };

  constructor(
    public dialogRef: MatDialogRef<WorkingShiftEditComponent>,
    private userProfileService: UserProfileService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private shiftService: ShiftService,
    private siteService: SiteService
  ) {
    super();
  }

  loadShiftsTemplate() {
    this.siteService
      .getSiteById(this.site.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((site) => {
        this.shiftsTemplate = site.shiftTemplates!;
      });
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
        if (this.userProfile.shiftTemplateId) {
          this.startDate = moment(this.userProfile.shiftStartDate);

          this.endDate = moment(this.userProfile.shiftEndDate);

          this.settingsForm.controls['shiftTemplateId'].patchValue(
            this.userProfile.shiftTemplateId
          );

          this.settingsForm.controls['startDate'].patchValue(
            this.startDate.format('MM/DD/YYYY h:mm A')
          );

          this.settingsForm.controls['startHourEndHour'].patchValue([
            this.startDate.format('HH:mm'),
            this.endDate.format('HH:mm'),
          ]);

          this.select(this.userProfile.shiftTemplateId);
        }
      });
  }

  /**
   * define the form for working shift entry
   */
  private buildSiteForm() {
    this.settingsForm = this.formBuilder.group({
      shiftTemplateId: ['', Validators.required],
      startDate: ['', Validators.required],
      startHourEndHour: ['', Validators.required],
    });
  }

  myEvents: MbscCalendarEvent[] = [];

  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    responsive: {
      xsmall: {
        view: {
          calendar: {
            type: 'month',
          },
          agenda: {
            type: 'day',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          calendar: {
            labels: true,
          },
        },
      },
    },
  };

  ngOnInit(): void {
    this.buildSiteForm();
    this.loadMyProfile();
    this.loadShiftsTemplate();
    this.myEvents = [];
  }

  cancel() {
    this.router.navigate(['/settings/profile']);
  }

  save() {
    this.isSubmitted = true;

    if (!this.settingsForm.invalid) {
      const userProfile = Object.assign({}, this.profile);

      const settingsForm = this.settingsForm.getRawValue();

      userProfile.updatedSection = 'shift';
      userProfile.shiftTemplateId = this.currentId;

      userProfile.shiftStartDate = moment(
        moment(settingsForm.startDate).format('DD/MM/YY') +
          'T' +
          moment(settingsForm.startHourEndHour[0]).format('HH:mm'),
        'DD/MM/YY[T]HH:mm'
      ).toDate();

      userProfile.shiftEndDate = moment(
        moment(settingsForm.startDate).format('DD/MM/YY') +
          'T' +
          moment(settingsForm.startHourEndHour[1]).format('HH:mm'),
        'DD/MM/YY[T]HH:mm'
      ).toDate();

      if (!userProfile.phoneNumber) {
        userProfile.phoneNumber = '';
      }

      console.log('SAVE SHIFT', userProfile);

      this.userProfileService
        .updateUserProfile(userProfile)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          localStorage.setItem('VFM_USER_PROFILE', JSON.stringify(userProfile));

          this.toastrService.success(
            'The working shift has been successfully updated!'
          );
          this.router.navigate(['/settings/profile']);
        });
    }
  }

  select(id: number) {
    this.currentId = id;

    if (this.startDate) {
      this.shiftService
        .getShiftById(this.currentId, this.startDate.format(), 0)
        .subscribe((result: any) => {
          this.mapWorkshiftWithMobiScroll(result);
        });
    }
  }

  mapWorkshiftWithMobiScroll(userShift: Shift[]) {
    const shift: any = { colors: null };

    shift.colors = userShift.map((item: Shift) => {
      return {
        background: '#b0ddfb',
        recurring: item.rRule,
        start: item.startDate,
      };
    });

    setTimeout(() => {
      this.eventSettings = shift;
    }, 100);
  }

  /**
   * Get the form control for the shift Id
   */
  get shiftCtrl() {
    return this.settingsForm.get('shiftTemplateId') as FormControl;
  }

  get startHourEndHourCtrl() {
    return this.settingsForm.get('startHourEndHour') as FormControl;
  }

  get startDateCtrl() {
    return this.settingsForm.get('startDate') as FormControl;
  }
}
