<div class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-calendar-days mr-2"></i>
        {{ 'features.addReservation.RESERVATION_AVAILABILITY' | translate}}
    </h3>
</div>

<div class="mx-auto py-6 px-4 sm:pt-8 pb-8 sm:px-6 lg:max-w-7xl lg:px-8">
    <div class="calendar shadow-lg">

        <mbsc-eventcalendar [locale]="locale" #eventCalendar [data]="myEvents" [options]="eventSettings"
            [invalid]="invalidDate" [headerTemplate]="customTemplate">
            <ng-template #customTemplate>
                <mbsc-calendar-nav class="cal-header-nav"></mbsc-calendar-nav>

                <mbsc-calendar-prev class="cal-header-prev"></mbsc-calendar-prev>
                <mbsc-calendar-today class="cal-header-today"></mbsc-calendar-today>
                <mbsc-calendar-next class="cal-header-next"></mbsc-calendar-next>
            </ng-template>
        </mbsc-eventcalendar>

    </div>
</div>