import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '@app/services/common/token.service';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(
    @Inject('BASE_API_URL') private baseUrl: string,
    private tokenService: TokenService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let req = request;

    // Don't change the http request if we retrieve local assets
    if (
      !request.url.includes('./assets') &&
      !request.url.includes('jsonplaceholder')
    ) {
      req = request.clone({ url: `${this.baseUrl}/${request.url}` });
    }

    return next.handle(req);
  }
}
