<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevLink]" [showChevron]=true>
</app-page-title>
<div class="max-w-lg mx-auto mt-20 px-[20px] sm:px-0">
    <div>
        <div class="text-center">
            <i class="fa-solid fa-people-group" style="font-size: 60px;"></i>
            <h2 class="mt-2 text-lg font-medium text-gray-900">{{ 'features.editRoleMember.TITLE_HOME' | translate }}
            </h2>
            <p class="mt-1 text-sm text-gray-500">{{ 'features.editRoleMember.DESCRIPTION_HOME' | translate }}</p>
        </div>
        <form action="#" class="mt-6 flex">
            <app-user-autocomplete class="w-full" [multiple]="true" [name]="user" [ctrlName]="'objectIds'"
                [form]="form">
            </app-user-autocomplete>
            <button (click)="addCrewMember()"
                class="ml-4 flex-shrink-0 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 mt-[53px] h-[40px]">
                <i class="fa-solid fa-plus mr-2"></i>
                {{ 'features.editRoleMember.ADD_MEMBER' | translate }}</button>
        </form>
    </div>
    <div class="mt-10" *ngIf="crewMembers && crewMembers.length > 0">
        <h3 class="text-xs font-semibold text-gray-500 uppercase tracking-wide">{{
            'features.editRoleMember.ALL_TEAM_MEMBERS'
            | translate }}</h3>
        <ul role="list" class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            <li *ngFor="let member of crewMembers; index as i" class="py-4 flex items-center justify-between space-x-3">
                <div class="min-w-0 flex-1 flex items-center space-x-3">
                    <div class="flex-shrink-0">
                        <div class="overflow-hidden relative w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                            <svg class="absolute -left-1 w-12 h-12 text-gray-400" fill="currentColor"
                                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                    clip-rule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                    <div class="min-w-0 flex-1">
                        <p class="text-sm font-medium text-gray-900 truncate">{{ member.name }}</p>
                        <p class="text-sm font-medium text-gray-500 truncate">{{ member.jobTitle }}</p>
                    </div>
                </div>
                <div class="flex-shrink-0">
                    <button type="button" (click)="removeCrewMember(member.id)"
                        class="inline-flex items-center py-2 px-3 border border-transparent rounded bg-gray-100 hover:bg-red-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <i class="fa-solid fa-circle-minus mr-2"></i>
                        <span class="text-sm font-medium text-gray-900"> {{ 'features.editRoleMember.REMOVE' | translate
                            }}
                        </span>
                    </button>
                </div>
            </li>

        </ul>
    </div>
</div>