<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevPageLink]" [showChevron]=true>
</app-page-title>
<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
    <div class="space-y-8 divide-y">

        <form [formGroup]="crewForm" (keydown.enter)="add($event)">
            <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-3 bg-yellow-default sm:px-6 text-black">
                    <h1 class="font-semibold">{{pageTitle}}</h1>
                </div>

                <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                        <mat-form-field [style.width.%]="100">
                            <mat-label>{{ 'features.addCrew.CREW' | translate}}</mat-label>
                            <input variant="outlined" matInput maxlength="50" #crew
                                placeholder="{{ 'features.addCrew.SELECT_CREW' | translate}}" formControlName="name">
                            <mat-hint align="end">{{crew.value.length}} / 50</mat-hint>
                            <mat-error *ngIf="crewNameCtrl.hasError('required')">
                                {{ 'features.addCrew.CREW' | translate}} {{ 'labels.IS' |
                                translate}} <strong>{{ 'labels.REQUIRED' |
                                    translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field [style.width.%]="100">
                            <mat-label>{{ 'features.addCrew.DESCRIPTION' | translate}}</mat-label>
                            <textarea maxlength="200" #description matInput
                                placeholder="{{ 'features.addCrew.SELECT_DESCRIPTION' | translate}}"
                                formControlName="description"></textarea>
                            <mat-hint align="end">{{description.value.length}} / 200</mat-hint>
                        </mat-form-field>
                    </div>

                    <div>
                        <app-user-autocomplete [multiple]="true" [name]="crewManager" [ctrlName]="'crewManagers'"
                            [form]="crewForm">
                        </app-user-autocomplete>
                    </div>

                </div>
                <div class="px-4 py-3 bg-gray-50 sm:px-6 h-[62px]">
                    <button (click)="confirmRemoveCrew()" *ngIf="crewId"
                        class="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-700 hover:bg-red-800 focus:outline-none">
                        {{ 'features.crew.DELETE_CREW_BUTTON' | translate }}
                    </button>

                    <div class="inline-block float-right">
                        <button (click)="cancel()"
                            class="bg-white py-2 px-4 border border-gray-300 rounded-md  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ 'labels.CANCEL' | translate}}
                        </button>

                        <button (click)="add()"
                            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                            {{ 'labels.SAVE' | translate}}
                        </button>
                    </div>

                </div>
            </div>
        </form>

    </div>
</div>