<app-page-title [iconClass]="pageIconClass" title="{{'features.workingShift.EDIT_WORKING_SHIFT' | translate}}"
    [routerLink]="['/settings']" [showChevron]=true>
</app-page-title>
<article>

    <form [formGroup]="settingsForm">
        <div class="mt-24 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 working-shift-edit mb-12">
            <div class="space-y-8 divide-y">

                <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-3 bg-yellow-default sm:px-6 text-black">
                        <h1 class="font-semibold">{{
                            'features.workingShift.EDIT_WORKING_SHIFT' | translate}}</h1>
                    </div>

                    <div class="px-4 mt-6 sm:px-6 lg:px-8">
                        <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">{{
                            'features.workingShift.SELECT_A_WORKING_SHIFT' | translate}}
                        </h2>

                        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 mt-6"
                            *ngIf="!shiftsTemplate || (shiftsTemplate && shiftsTemplate.length === 0)">
                            <div class="rounded-lg bg-red-600 p-2 shadow-lg sm:p-3">
                                <div class="flex flex-wrap items-center justify-between">
                                    <div class="flex w-0 flex-1 items-center">
                                        <span class="flex rounded-lg bg-red-800 p-2">
                                            <i class="fa-solid fa-exclamation h-6 w-6 text-white text-center mt-1"></i>
                                        </span>
                                        <p class="ml-3 truncate font-medium text-white">
                                            <span class="">No Shift available for this site!</span>
                                        </p>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="mt-4 mb-10">

                            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-5 sm:gap-x-5">
                                <label *ngFor="let item of shiftsTemplate" [ngClass]="{
                                'border-transparent': currentId === item.id,
                                'border-gray-300': currentId !== item.id,
                                'ring-2': currentId === item.id,
                                'ring-green-500': currentId === item.id,
                                'border-green-500': currentId === item.id                                
                              }"
                                    class="relative bg-white border rounded-lg shadow-sm p-4 pr-0 flex cursor-pointer focus:outline-none">
                                    <input [checked]="+profile.shift === +item.id" type="radio"
                                        (click)="select(item.id)" [id]="'shift-' + item.id" name="shiftTemplateId"
                                        formControlName="shiftTemplateId" [value]="item.id" class="sr-only">
                                    <span class="flex-1 flex">
                                        <span class="flex flex-col">
                                            <span id="project-type-0-label"
                                                class="block text-base font-medium text-gray-900">
                                                {{ item.name }}

                                            </span>
                                        </span>
                                    </span>
                                    <svg *ngIf="currentId === item.id"
                                        class="h-5 w-5 text-green-600 absolute top-[5px] right-[10px]"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        aria-hidden="true">
                                        <path fill-rule="evenodd"
                                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                            clip-rule="evenodd" />
                                    </svg>
                                    <div [ngClass]="{
                                'border': currentId === item.id,
                                'border-2': currentId !== item.id,
                                'border-green-500': currentId === item.id,
                                'border-transparent': currentId !== item.id
                              }" class="absolute -inset-px rounded-lg border-2 pointer-events-none" aria-hidden="true">
                                    </div>
                                </label>
                            </div>
                            <mat-error *ngIf="isSubmitted && shiftCtrl.hasError('required')">
                                <span class="text-sm mt-2 inline-block">
                                    {{ 'features.workingShift.SELECT_A_WORKING_SHIFT' | translate}} {{ 'labels.IS' |
                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                        translate}}</strong>
                                </span>
                            </mat-error>

                        </div>
                    </div>

                    <div class="px-4 mt-6 sm:px-6 lg:px-8">

                        <div>
                            <mat-form-field [style.width.%]="100">
                                <mat-label>{{ 'features.addRestriction.STARTHOUR' | translate}} - {{
                                    'features.addRestriction.ENDHOUR' | translate}}</mat-label>
                                <input mbsc-datepicker [mbscOptions]="datepickerOptTime" variant="outlined"
                                    [formControl]="startHourEndHourCtrl" matInput>

                                <mat-error *ngIf="startHourEndHourCtrl.hasError('required')">
                                    {{ 'features.addRestriction.STARTHOUR' | translate}} {{ 'labels.IS'
                                    |
                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                        translate}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div style="margin-top: 0 !important;">
                            <mat-form-field [style.width.%]="100">
                                <mat-label>From Date</mat-label>
                                <input mbsc-datepicker [mbscOptions]="datepickerOptDate" variant="outlined"
                                    formControlName="startDate" matInput>
                                <mat-error *ngIf="startDateCtrl.hasError('required')">
                                    {{ 'features.addRestriction.STARTDATE' | translate}} {{ 'labels.IS'
                                    |
                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                        translate}}</strong>
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>


                    <div class="px-4 mt-10 sm:px-6 lg:px-8">
                        <h2 class="mb-2 text-gray-500 text-xs font-medium uppercase tracking-wide">
                            {{ 'features.workingShift.CALENDAR' | translate}}
                        </h2>

                        <div class="calendar working-shift-calendar mb-8">
                            <mbsc-eventcalendar [locale]="locale" [data]="myEvents" [options]="eventSettings ">
                            </mbsc-eventcalendar>
                        </div>
                    </div>

                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button (click)="cancel()"
                            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ 'labels.CANCEL' | translate}}
                        </button>

                        <button (click)="save()"
                            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                            {{ 'labels.SAVE' | translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</article>