<app-page-title [iconClass]="pageIconClass" title="{{ 'features.settings.SETTINGS' | translate}}"
    [routerLink]="['/home']" [showChevron]=true>
</app-page-title>
<div class="flex-1 relative z-0 flex overflow-hidden" style="min-height: calc(100vh);">
    <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last pb-[60px]"
        style="background-color: #f8fafc;">

        <article>
            <!-- header -->
            <div class="hidden sm:block bg-white shadow">
                <div class="px-4 sm:px-6 lg:pr-8 lg:mx-auto lg:px-8">
                    <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                        <div class="flex-1 min-w-0">
                            <div class="flex items-center">

                                <div>
                                    <div class="flex items-center">
                                        <!-- <img class="h-16 w-16 rounded-full sm:hidden"
                                            src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80"
                                            alt=""> -->
                                        <h1
                                            class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                            {{ 'features.settings.SETTINGS' | translate}}</h1>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4" *ngIf="tab === 'profile'">

                        </div>
                    </div>
                </div>
            </div>

            <!-- Tabs -->
            <div class="mt-6 sm:mt-2 2xl:mt-5">
                <div class="border-b border-gray-200">
                    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <!-- Current: "border-pink-500 text-gray-900", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                            <a (click)="selectTab('profile')" href="javascript:void(0)"
                                [ngClass]="{'border-yellow-default text-gray-900': tab === 'profile'}"
                                class="border-transparent text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                                aria-current="page"> {{ 'features.settings.PROFILE' | translate}} </a>

                            <!-- <a (click)="selectTab('notification')" href="javascript:void(0)"
                                [ngClass]="{'border-yellow-default text-gray-900': tab === 'notification'}"
                                class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                                {{ 'features.settings.NOTIFICATION' | translate}} </a> -->

                        </nav>
                    </div>
                </div>
            </div>

            <app-profile-detail-info *ngIf="tab === 'profile'"></app-profile-detail-info>

            <app-notification-settings *ngIf="tab === 'notification'"></app-notification-settings>
        </article>

    </main>
</div>