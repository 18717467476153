import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../core/services/common/menu.service';
import { environment } from '@env';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  mailAPD: string = `mailto:apd.services@agnicoeagle.com?subject=EQUIPMENT TRACKING AND RESERVATION SYSTEM: Help / Feedback`;
  isSidebarOpen = false;
  env = environment;

  constructor(private menuService: MenuService) {
    this.menuService
      .getModifyToggle()
      .subscribe((toggle) => (this.isSidebarOpen = toggle));
  }

  ngOnInit(): void {}

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.menuService.toggleMenu(this.isSidebarOpen);
  }
}
