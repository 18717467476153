import { Component, OnInit, AfterViewInit } from '@angular/core';
import {
  Form,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@app/guards/unsaved-changes.guard';
import { DialogService } from '@app/services/common/dialog.service';
import { Category, PaginatedItems, Site, UserProfile } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { CategoryService } from '@app/services/data/category.service';
import { SiteService } from '@app/services/data/site.service';
import { map, Observable, startWith, Subject, takeUntil, filter } from 'rxjs';
import { MenuService } from '@app/services/common/menu.service';
import { MatDialog } from '@angular/material/dialog';
import { WelcomeDialogComponent } from 'src/app/shared/components/welcome-dialog/welcome-dialog.component';

@Component({
  selector: 'app-profile-detail-edit',
  templateUrl: './profile-detail-edit.component.html',
  styleUrls: ['./profile-detail-edit.component.scss'],
})
export class ProfileDetailEditComponent
  extends VfmBaseComponent
  implements OnInit, AfterViewInit
{
  categories: Category[] | undefined;
  theSites: Site[] | undefined;
  categoryId!: number;
  isFormSaved: boolean = false;
  pageTitle = 'Edit Profile';
  pageIconClass = 'fa-solid fa-pen';
  profileForm!: FormGroup;
  language = new FormControl('');
  noDefaultValue: boolean = false;
  languageList: any[] = [
    { name: 'English', id: 0 },
    { name: 'French', id: 1 },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private userProfileService: UserProfileService,
    private categoryService: CategoryService,
    private siteService: SiteService,
    private menuService: MenuService,
    private dialog: MatDialog
  ) {
    super();

    this.route.queryParams.subscribe((params) => {
      this.noDefaultValue = params.nodefaultvalue;

      if (this.noDefaultValue) {
        this.showWelcomeDialog();
      }
    });
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.profileForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addcategory.CATEGORY_UNSAVED_CHANGES'),
        this.translate.instant('features.addcategory.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  ngOnInit(): void {
    this.buildProfileForm();

    this.profileForm.get('siteId')?.valueChanges.subscribe((val) => {
      const site = this.theSites?.find((site) => site.id === val);

      this.loadCategories(site!.code);
    });
  }

  ngAfterViewInit(): void {
    this.loadSites();
  }

  loadUserProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        if (userProfile.siteId) {
          const site = this.theSites?.find(
            (site) => site.id === userProfile.siteId
          );

          const siteCode = site!.code;

          this.loadCategories(siteCode);
        }

        this.profileForm.patchValue(userProfile);

        if (userProfile.siteId && userProfile.categoryId === 0) {
          this.profileForm.controls['categoryId'].patchValue(null);
        }

        if (userProfile.siteId && userProfile.siteId === 0) {
          this.profileForm.controls['siteId'].patchValue(null);
        }
      });
  }

  loadCategories(siteCode: string) {
    this.categoryService
      .getCategories(siteCode, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadSites() {
    this.siteService
      .getSites()
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Site>) => {
          return resp.data;
        })
      )
      .subscribe((sites) => {
        this.theSites = sites;
        this.loadUserProfile();
      });
  }

  private buildProfileForm() {
    this.profileForm = this.formBuilder.group({
      id: [''],
      name: [''],
      objectId: [''],
      email: [''],
      jobTitle: [''],
      department: [''],
      phoneNumber: [''],
      language: ['', Validators.required],
      categoryName: [''],
      categoryId: ['', Validators.required],
      siteId: ['', Validators.required],
      siteName: [''],
    });
  }

  get languageCtrl() {
    return this.profileForm.get('language') as FormControl;
  }

  get catNameCtrl() {
    return this.profileForm.get('categoryName') as FormControl;
  }

  get categoryCtrl() {
    return this.profileForm.get('categoryId') as FormControl;
  }

  get siteCtrl() {
    return this.profileForm.get('siteId') as FormControl;
  }

  cancel() {
    this.router.navigate(['/settings']);
  }

  save() {
    if (!this.profileForm.invalid) {
      const formValues = this.profileForm.getRawValue();
      const selectedSite = this.theSites?.find((site) => {
        return site.id === formValues.siteId;
      });
      const selectedCat = this.categories?.find((cat) => {
        return cat.id === formValues.categoryId;
      });

      formValues.phoneNumber = '';
      formValues.siteName = selectedSite?.name;
      formValues.categoryName = selectedCat?.categoryName;
      formValues.updatedSection = 'profile';

      this.updateSite(selectedSite!);
      this.updateLanguage(formValues.language);

      this.userProfileService
        .updateUserProfile(formValues)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFormSaved = true;
          localStorage.setItem('VFM_USER_PROFILE', JSON.stringify(formValues));
          this.router.navigate(['/settings']);
        });
    }
  }

  updateSite(site: Site) {
    this.site = site;
    this.siteMenuService.updateSite(site);
    this.menuService.updateDefaultSite(true);
  }

  updateLanguage(lang: number) {
    const language: string = lang === 0 ? 'en' : 'fr';
    this.translate.use(language).toPromise();
  }

  showWelcomeDialog() {
    const dialogRef = this.dialog.open(WelcomeDialogComponent, {
      width: '550px',
      height: '208px',
      panelClass: 'equipment-select',
    });
  }
}
