<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevPageLink]" [showChevron]=true>
    <div content class="sm:hidden inline-flex">
        <button (click)="toggleFilters($event)" type="button"
            class="inline-flex absolute right-[74px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-gray-800">
            <i class="fa-solid fa-sliders">
            </i>
        </button>
        <button (click)="showAdd($event)" type="button"
            class="inline-flex absolute right-[14px] top-[12px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
            <i class="fa-solid fa-plus">
            </i>
        </button>
    </div>
</app-page-title>
<div class="flex-1 relative sm:z-0 flex sm:overflow-hidden">
    <main class="flex-1 relative sm:z-0 overflow-y-auto focus:outline-none md:order-last"
        style="background-color: #f8fafc;">

        <article>
            <app-reservation-search [isTableView]="isTableView" [toggleSearchFilters]="toggleSearchFilters"
                [users]="users" [weekDate]="weekDate" (selectedCategory)="selectedCategory($event)"
                (selectedEquipment)="selectedEquipment($event)" (selectedCrew)="selectedCrew($event)"
                (selectedUser)="selectedUser($event)" (reservationChange)="reservationSearch($event)">
            </app-reservation-search>
            <div class="flex-1 relative sm:z-0 flex sm:overflow-hidden">
                <app-reservation-detail (selectedWeek)="selectedWeek($event)" [equipId]="equipId"
                    (selectedReservationId)="selectedReservationId($event)" (reloadReservations)="getReservations()"
                    (isTableViewSelected)="isTableViewSelected($event)"
                    (deletedReservationId)="deletedReservationId($event)" [reservations]="reservations"
                    [reservation]="reservation"
                    class="shadow bg-white flex-1 relative sm:z-0 overflow-y-auto focus:outline-none md:order-last">
                </app-reservation-detail>

                <app-reservations-list *ngIf="isTableView" [weekDate]="weekDate"
                    (reservation)="selectedReservation($event)" [selectedReservationId]="reservationId"
                    [reservations]="reservations">
                </app-reservations-list>
            </div>
        </article>
    </main>
</div>