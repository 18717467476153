import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscCalendarEvent,
  Notifications,
  localeEn,
  localeFr,
  setOptions,
} from '@mobiscroll/angular';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import {
  Crew,
  Equipment,
  Reservation,
  ReservationMobiscroll,
  UserProfile,
} from 'src/app/data/index';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CrewService } from '@app/services/data/crew.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkingShift } from 'src/app/core/utils/working-shifts';
import { ProfileService } from 'src/app/core/services/common/profile.service';
import { ShiftService } from '@app/services/data/shift.service';
import { Shift, ShiftTemplate } from 'src/app/data';
import { ApprovalStatus } from '@app/enums/approval-status-enum';

@Component({
  selector: 'app-reservation-add-availability',
  templateUrl: './reservation-add-availability.component.html',
  styleUrls: ['./reservation-add-availability.component.scss'],
})
export class ReservationAddAvailabilityComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges, AfterViewInit
{
  view = 'day';
  isNewReservation = false;

  @Input() reccurenceExclusions: any;
  @Input() recurringRule!: Equipment;
  @Input() equipment!: Equipment;
  @Input() startDate!: String;
  @Input() endDate!: String;
  @Input() selectedUser!: UserProfile;
  @Input() isDisplayShift!: boolean;
  @Input() isRecurring!: boolean;
  @Output() date: EventEmitter<any> = new EventEmitter<any>();
  @Output() isReservationOverlap: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild('eventCalendar', { static: false })
  eventCalendar!: MbscCalendarEvent;
  locale = this.profile.language === 0 ? localeEn : localeFr;

  selectedStartDate!: string;
  selectedEndDate!: string;
  reservations!: Reservation[];
  newReservation!: ReservationMobiscroll;
  reservationId!: number;
  crew!: Crew;
  isNewReservationOverlap = false;
  invalidDate: any = [];
  invalidRestrictions: any = [];
  invalidShiftDate: any = [];

  calendarMonthView = {
    responsive: {
      xsmall: {
        view: {
          calendar: { labels: true },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 400,
        view: {
          calendar: { labels: true },
        },
      },
    },
  };

  calendarDailyView = {
    responsive: {
      xsmall: {
        view: {
          schedule: {
            allDay: false,
            type: 'day',
            size: 1,
            startTime: '00:00',
            endTime: '24:00',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 400,
        view: {
          schedule: {
            allDay: false,
            type: 'day',
            size: 3,
            startTime: '00:00',
            endTime: '24:00',
          },
        },
      },
    },
  };

  myEvents: MbscCalendarEvent[] = [];

  eventSettings: MbscEventcalendarOptions = {
    showEventTooltip: false,
    theme: 'ios',
    themeVariant: 'light',
    clickToCreate: true,
    dragToCreate: true,
    dragToMove: true,
    dragToResize: true,
    eventDelete: true,
    invalidateEvent: 'strict',
    onEventClick: (event) => {},
    onEventCreate: (event) => {
      if (!this.isNewReservation) {
        this.isNewReservation = true;
        this.createNewReservation(event.event.start, event.event.end, null);
      } else {
        this.updateNewReservation(event.event.start, event.event.end, null);
      }

      const date = {
        startDate: event.event.start,
        endDate: event.event.end,
      };

      this.date.emit(date);
      return false;
    },
    onEventDragEnd: (event) => {
      this.updateDragDropReservation(event);
    },
    onEventCreateFailed: (event) => {},
    onEventUpdateFailed: (event) => {
      this.updateDragDropReservation(event);
    },
  };

  constructor(
    private notify: Notifications,
    private equipmentService: EquipmentService,
    private route: ActivatedRoute,
    private crewService: CrewService,
    private profileService: ProfileService,
    private cdr: ChangeDetectorRef,
    private shiftService: ShiftService
  ) {
    super();

    this.profileService.getModifyProfile().subscribe((profile) => {
      if (profile) {
        this.profile = profile;
        this.displayShift(this.isDisplayShift);
      }
    });
  }

  ngOnInit(): void {
    const reservationId = this.route.snapshot.paramMap.get('id');

    if (reservationId) {
      this.reservationId = +reservationId;
    }

    setOptions(this.calendarDailyView);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.equipment && changes.equipment.currentValue) {
      this.loadRestrictions();
      this.getReservationsByEquipment();
      this.getCrew();
    }

    if (
      changes.startDate &&
      changes.startDate.currentValue &&
      changes.startDate.currentValue !== 'Invalid date'
    ) {
      this.selectedStartDate = changes.startDate.currentValue;
    }

    if (
      changes.endDate &&
      changes.endDate.currentValue &&
      changes.endDate.currentValue !== 'Invalid date'
    ) {
      this.selectedEndDate = changes.endDate.currentValue;
    }

    if (changes.selectedUser) {
      this.displayShift(this.isDisplayShift);
    }

    if (changes.isDisplayShift) {
      this.displayShift(changes.isDisplayShift.currentValue);
    }

    if (changes.isRecurring && changes.isRecurring.currentValue) {
      this.showReservation(
        this.selectedStartDate,
        this.selectedEndDate,
        changes.isRecurring.currentValue ? this.recurringRule : null
      );
    }

    if (changes.recurringRule && changes.recurringRule.currentValue) {
      this.recurringRule = changes.recurringRule.currentValue;

      console.log('this.recurringRule!!!!!!!!!!!', this.recurringRule);
      this.showReservation(
        this.selectedStartDate,
        this.selectedEndDate,
        this.isRecurring ? this.recurringRule : null
      );
    }

    if (this.selectedStartDate && this.selectedEndDate) {
      this.showReservation(
        this.selectedStartDate,
        this.selectedEndDate,
        this.isRecurring ? this.recurringRule : null
      );
    }

    if (
      this.newReservation &&
      this.newReservation.start &&
      this.newReservation.end
    ) {
      this.updateNewReservation();
    }
  }

  updateDragDropReservation(event: any) {
    console.log('onEventDragEnd', event);

    const date = {
      startDate: event.event.start,
      endDate: event.event.end,
    };

    this.updateNewReservation(event.event.start, event.event.end);

    this.date.emit(date);
  }

  displayShift(isDisplayShift: boolean) {
    if (isDisplayShift) {
      if (this.selectedUser && this.selectedUser.shiftTemplateId > 0) {
        this.shiftService
          .getShiftById(
            this.selectedUser.shiftTemplateId,
            this.selectedUser.shiftStartDate,
            1
          )
          .subscribe((result: any) => {
            let invalidShiftDate = result.map((item: Shift) => {
              return {
                recurring: item.rRule + ';DTSTART=' + item.startDate,
              };
            });

            console.log('invalidShiftDate', invalidShiftDate);

            if (isDisplayShift) {
              this.invalidShiftDate = invalidShiftDate;
              this.invalidDate = [
                ...this.invalidRestrictions,
                ...invalidShiftDate,
              ];
            }
          });
      } else {
        this.invalidDate = this.invalidRestrictions;
      }
    } else {
      this.invalidDate = this.invalidRestrictions;
    }
  }

  loadRestrictions() {
    this.equipmentService
      .getAvailabilitiesByEquipmentId(this.site.code, this.equipment.id!, 0, 0)
      .subscribe((result: any) => {
        let invalidRestrictions = result.data.map(
          (restriction: Reservation) => {
            return {
              start: moment(
                restriction.startDate,
                'YYYY-MM-DD[T]HH:mm:ss'
              ).format('HH:mm'),
              end: moment(restriction.endDate, 'YYYY-MM-DD[T]HH:mm:ss').format(
                'HH:mm'
              ),
              title: this.getTypeRestriction(restriction.availabilityType!),
              recurring:
                restriction.rRule + ';DTSTART=' + restriction.startDate,
            };
          }
        );

        this.invalidRestrictions = invalidRestrictions;
        console.log('invalidRestrictions', invalidRestrictions);

        const invalidShift = this.isDisplayShift ? this.invalidShiftDate : [];

        this.invalidDate = [...this.invalidRestrictions, ...invalidShift];
      });
  }

  getTypeRestriction(availabilityType: number) {
    let type = '';

    switch (availabilityType) {
      case 1:
        type = 'All Restricted';
        break;

      case 2:
        type = 'Crew Restricted';
        break;

      case 3:
        type = 'Soft Restricted';
        break;
    }

    return type;
  }

  showReservation(startDate: string, endDate: string, recurringRule: any) {
    if (this.isNewReservation) {
      this.updateNewReservation(startDate, endDate, recurringRule);
    } else {
      this.createNewReservation(startDate, endDate, recurringRule);
    }
  }

  createNewReservation(startDate: any, endDate: any, recurringRule: any) {
    this.isNewReservation = true;

    this.newReservation = {
      id: -1,
      isNew: true,
      start: moment(new Date(startDate)).format('YYYY-MM-DD[T]HH:mm:ss'),
      end: moment(new Date(endDate)).format('YYYY-MM-DD[T]HH:mm:ss'),
      text: this.getNewReservationText(),
      color: '#009CDE',
      recurring: recurringRule,
    };

    if (this.hasOverlap(this.newReservation)) {
      this.isNewReservationOverlap = true;
      this.newReservation.color = 'red';
    } else {
      this.isNewReservationOverlap = false;
    }

    this.isReservationOverlap.emit(this.isNewReservationOverlap);

    this.myEvents = [...this.myEvents, this.newReservation];
  }

  updateNewReservation(
    startDate: any = this.newReservation.start,
    endDate: any = this.newReservation.end,
    recurringRule: any = this.isRecurring ? this.recurringRule : null
  ) {
    const index = this.myEvents.findIndex((reservation) => {
      return reservation.isNew === true;
    });

    this.newReservation.start = startDate;

    this.newReservation.end = endDate;

    this.newReservation.recurring = recurringRule;

    this.newReservation.recurringException = this.reccurenceExclusions;

    this.newReservation.color = '#009CDE';

    this.myEvents.splice(index, 1);

    this.newReservation.text = this.getNewReservationText();

    if (this.hasOverlap(this.newReservation)) {
      this.newReservation.color = 'red';
      this.isNewReservationOverlap = true;
    } else {
      this.isNewReservationOverlap = false;
    }

    this.isReservationOverlap.emit(this.isNewReservationOverlap);

    this.myEvents = [...this.myEvents, this.newReservation];
  }

  getNewReservationText() {
    return `<div style="text-align: center; margin-bottom: 10px;"></div><div class="my-block"><i class="fa-solid fa-truck mr-1"></i>${this.getEquipmentName()}</div><div class="my-desc"><i class="fa-solid fa-user mr-1"></i>${
      this.selectedUser ? this.selectedUser.name : ''
    }</div>`;
  }

  removeNewReservation() {
    this.isNewReservation = false;
    this.myEvents.pop();
  }

  getEquipmentName(): string {
    return this.equipment ? this.equipment.name : 'N/A';
  }

  getCrew() {
    this.crewService
      .getCrewById(this.equipment.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.crew = result;
      });
  }

  getReservationsByEquipment() {
    this.equipmentService
      .getReservationsByEquipment(this.site.code, this.equipment.id!)
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.reservations = result.data;

        this.reservations = this.reservations.filter(
          (reservation) => this.reservationId !== reservation.id
        );

        const reservations = this.reservations.map(
          (reservation: Reservation) => {
            return {
              id: reservation.id,
              start: moment(reservation.startDate).format(
                'YYYY-MM-DD[T]HH:mm:ss'
              ),
              end: moment(reservation.endDate).format('YYYY-MM-DD[T]HH:mm:ss'),
              text: `<div style="text-align: center; margin-bottom: 10px;"></div><div class="my-block"><i class="fa-solid fa-truck mr-1"></i>${this.equipment.name}</div><div class="my-desc"><i class="fa-solid fa-user mr-1"></i>${reservation.userName}</div>`,
              color:
                reservation.approvalStatus === ApprovalStatus.pending
                  ? '#454444'
                  : this.getReservationColor(reservation.userId!),
              editable: false,
              recurring: reservation.rRule,
              recurringException: [
                ...reservation.exclusions,
                ...reservation.reccurenceExclusions.filter(Boolean),
              ],
            };
          }
        );

        this.myEvents = [...[this.newReservation || []], ...reservations];

        if (
          this.newReservation &&
          this.newReservation.start &&
          this.newReservation.end
        ) {
          setTimeout(() => {
            this.updateNewReservation();
          }, 300);
        }
      });
  }

  getReservationColor(userId: number): string {
    return userId === this.profile.id ? '#008000' : '#F1C400';
  }

  hasOverlap(newReservation: ReservationMobiscroll) {
    let events = this.eventCalendar
      .getEvents(
        moment(newReservation.start).format('YYYY-MM-DD[T]HH:mm'),
        moment(newReservation.end).format('YYYY-MM-DD[T]HH:mm')
      )
      .filter((e: any) => {
        console.log('èvent', e);
        return e.id !== newReservation.id;
      });

    return events.length > 0;
  }

  changeView(inst: any): void {
    setTimeout(() => {
      switch (this.view) {
        case 'month':
          setOptions(this.calendarMonthView);
          break;
        case 'day':
          setOptions(this.calendarDailyView);
          break;
      }
    });
  }
}
