import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  pageIconClass = 'fa-solid fa-user';
  pageTitle = 'Profile';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  showEdit() {
    this.router.navigate(['/profile/edit']);
  }
}
