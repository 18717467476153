import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import * as update_fr from 'src/app/json/faq/update_fr.json'
import * as update_en from 'src/app/json/faq/update_en.json'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq-update',
  templateUrl: './faq-update.component.html',
  styleUrls: ['./faq-update.component.scss'],
})
export class FaqUpdateComponent implements OnInit {

  constructor(public dialog: MatDialog, private translate: TranslateService,) { }

  updateInfo = Object.assign({},this.translate.currentLang === 'en' ? update_en : update_fr)

  ngOnInit(): void {
    

  }

  openDialog() {
    const dialogRef = this.dialog.open(FaqDialogComponent , {
      
      width: '600px',
      data: 
      {
        text:{
          nextUpdate: this.updateInfo.NEXT_UPDATE.DATE,
          descriptionNextUpdate: this.updateInfo.NEXT_UPDATE.NEW
      }
      }
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
