<div class="header sm:pl-14 lg:pl-64 flex flex-col flex-1">
  <div class="bg-blue-default top-0 flex-shrink-0 flex h-16 shadow z-40 fixed right-0 w-full"
    style="z-index: 100000 !important;">

    <button (click)="toggleSidebar()" type="button"
      class="px-4 border-r border-gray-200 text-slate-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:hidden">
      <span class="sr-only">Open sidebar</span>
      <!-- Heroicon name: outline/menu-alt-2 -->
      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
        stroke="currentColor" aria-hidden="true">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h7" />
      </svg>
    </button>
    <div class="flex-1 px-4 flex justify-end">

      <div *ngIf="env.environment !== 'PROD'"
        class="mt-[17px] mr-[20px] font-[600] h-[31px] pt-[3px] relative text-center w-[59px] bg-cyan-900 rounded-2xl shadow text-white">
        {{ env.environment}}
      </div>


      <!-- Site Menu -->
      <app-site-menu class="mt-[20px] mr-[32px]"></app-site-menu>

      <!-- Top bar search -->
      <!-- <app-top-search></app-top-search> -->

      <a [routerLink]="['/faq']" matTooltip="{{ 'labels.HELP_FEEDBACK' | translate }}">

        <i class="fa-solid fa-circle-question mt-[21px] text-[21px] cursor-pointer"></i>
      </a>

      <div class="ml-2 flex items-center md:ml-2">
        <!-- Profile dropdown -->
        <app-profile-menu></app-profile-menu>
      </div>
    </div>
  </div>
</div>