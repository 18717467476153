<div *ngIf="crewMembers"
    class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-users mr-2"></i>
        {{crewMembers.length}} {{ 'features.member.MEMBERS' | translate }}
    </h3>
    <button type="button" (click)="addMember()" *ngIf="editable"
        class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-black-default hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
        <i class="fa-solid fa-pen"></i>
    </button>
</div>

<p-carousel *ngIf="crewMembers && crewMembers.length > 0" [value]="crewMembers" [numVisible]="3" [numScroll]="4"
    [circular]="true" [responsiveOptions]="responsiveOptions" [showIndicators]="false">
    <ng-template let-member pTemplate="item">
        <div (click)="selectMember(member)"
            class="cursor-pointer rounded-lg min-h-[140px] bg-white shadow overflow-hidden relative text-center py-4"
            style="margin: 15px;">
            <div class=" relative">

                <div class="overflow-hidden mx-auto relative w-16 h-16 bg-gray-100 rounded-full dark:bg-gray-600">
                    <svg class="absolute  w-16 h-16 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                            clip-rule="evenodd"></path>
                    </svg>
                </div>
                <h3 class="mt-1 text-gray-900 text-sm font-medium">{{ member.name }}</h3>
            </div>
        </div>
    </ng-template>
</p-carousel>

<div class="mb-4 mx-4">
    <div *ngIf="crewMembers && crewMembers.length === 0"
        class="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
        <p class="mt-6 block text-sm font-medium text-gray-900"> {{ 'features.member.CREW_HAS_NO_MEMBER' | translate }}
        </p>
        <p class="mt-2 block text-sm font-medium text-gray-500"> {{ 'features.member.CLICK_TO_ADD_MEMBER' | translate }}
        </p>
    </div>

    <a *ngIf="crewMembers && crewMembers.length > 0" href="#" [routerLink]="['members/detail']"
        class="hidden md:flex w-full justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        {{ 'features.member.VIEW_ALL' | translate }}
    </a>
</div>