<aside class="hidden xs:w-25 md:order-first md:flex md:flex-col flex-shrink-0 md:w-70 lg:w-80 border-r border-gray-200">
    <div class="px-6 pt-6 pb-4">
        <h2 class="mt-3 text-2xl font-bold text-gray-900 relative" *ngIf="crew"> {{ crew.name }}
        </h2>

        <div class="mt-6 flex space-x-4">
            <div class="flex-1 min-w-0">
                <label for="search" class="sr-only">{{ 'features.member.SEARCH' | translate }}</label>
                <div class="relative mt-4">
                    <mat-form-field [style.width.%]="100">
                        <mat-label>{{ 'features.member.SEARCH_MEMBER' | translate }}</mat-label>
                        <mat-icon matPrefix class="relative top-[7px]">search
                        </mat-icon>
                        <input variant="outlined" matInput maxlength="50" [(ngModel)]="searchmember"
                            (input)="search(searchmember)">
                        <button *ngIf="searchmember" matSuffix mat-icon-button aria-label="Clear"
                            (click)="clearSearch()">
                            <mat-icon class="relative top-[7px]">close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <!-- Directory list -->
    <nav class="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
        <div class="relative">
            <div
                class="z-10 sticky py-4 mt-4 top-0 border-t border-b border-gray-300 bg-gray-200 px-6 text-sm font-medium text-gray-900">
                <i class="fa-solid fa-users mr-1"></i> {{ 'features.member.MEMBERS' | translate }}
                <button type="button" (click)="addMember()"
                    class="relative float-right -mt-1 lg:inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-700">
                    <i class="fa-solid fa-gear"></i>
                </button>
            </div>

            <ul role="list" class="relative z-0 divide-y divide-gray-200">
                <li *ngFor="let member of searchCrewMembers | searchFilter: searchmember; index as i">
                    <div [ngClass]="{'bg-yellow-default selected ': selectedMember.id === member.id}"
                        (click)="selectMember(member)"
                        class="relative px-6 py-5 flex items-center space-x-3 focus:outline-none  ">
                        <div class="flex-shrink-0">
                            <div
                                class="overflow-hidden mx-auto relative w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600">
                                <svg class="absolute  w-10 h-10 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="flex-1 min-w-0">
                            <a href="javascript:void(0)" class="focus:outline-none">
                                <!-- Extend touch target to entire panel -->
                                <span class="absolute inset-0" aria-hidden="true"></span>
                                <p class="text-sm font-medium text-gray-900">{{ member.name }}</p>
                                <p>
                                    <!-- <span
                                        class="px-2 py-1 text-white text-xs font-medium bg-black-default rounded-full">
                                        Owner
                                    </span> -->
                                </p>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

    </nav>
</aside>