import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  MbscEventcalendarOptions,
  MbscCalendarEvent,
  localeEn,
  localeFr,
} from '@mobiscroll/angular';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { Observable, takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/data';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { WorkingShift } from 'src/app/core/utils/working-shifts';
import { ShiftService } from '@app/services/data/shift.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SHIFTS } from 'src/app/core/constants/shifts.constants';
import { Shift, ShiftTemplate } from 'src/app/data';

@Component({
  selector: 'app-profile-detail-info',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss'],
})
export class ProfileDetailInfoComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private shiftService: ShiftService
  ) {
    super();
  }

  userShiftName = '';
  shiftsTemplate!: ShiftTemplate[];
  isSettingsPage = false;
  locale = this.profile.language === 0 ? localeEn : localeFr;
  userProfile!: UserProfile;
  myEvents: MbscCalendarEvent[] = [];

  @Input() member!: UserProfile | null;

  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    responsive: {
      xsmall: {
        view: {
          calendar: {
            type: 'month',
          },
          agenda: {
            type: 'day',
          },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 600,
        view: {
          calendar: {
            labels: false,
          },
        },
      },
    },
  };

  ngOnInit(): void {
    this.loadShiftsTemplate();

    if (this.router.url.includes('/settings')) {
      this.isSettingsPage = true;
    }

    if (this.member) {
      this.loadUserProfile();
    } else {
      this.loadMyProfile();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.loadUserProfile();
  }

  loadShiftsTemplate() {
    this.shiftService.getShiftTemplate().subscribe((result: any) => {
      this.shiftsTemplate = result;
    });
  }

  loadWorkingShift() {
    if (this.userProfile.shiftTemplateId) {
      this.shiftService
        .getShiftById(
          this.userProfile.shiftTemplateId,
          this.userProfile.shiftStartDate,
          0
        )
        .subscribe((result: any) => {
          this.mapWorkshiftWithMobiScroll(result);
        });
    }
  }

  loadUserProfile() {
    this.userProfile = this.member!;
    this.loadWorkingShift();
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: any) => {
        this.userProfile = userProfile;
        this.loadWorkingShift();
        this.displayUserShiftName();
      });
  }

  getLanguage(lang: number) {
    if (lang === 0) {
      return 'En';
    } else {
      return 'Fr';
    }
  }

  showEdit() {
    this.router.navigate(['/settings/profile/edit']);
  }

  showEditWorkingShift() {
    this.router.navigate(['/settings/profile/edit-working-shift']);
  }

  mapWorkshiftWithMobiScroll(userShift: Shift[]) {
    const shift: any = { colors: null };

    shift.colors = userShift.map((item: Shift) => {
      return {
        background: '#b0ddfb',
        recurring: item.rRule,
        start: item.startDate,
      };
    });

    setTimeout(() => {
      this.eventSettings = shift;
    }, 100);
  }

  displayUserShiftName() {
    if (this.userProfile.shiftTemplateId > 0 && this.shiftsTemplate) {
      let userShift = this.shiftsTemplate.find(
        (shift) => shift.id === this.userProfile!.shiftTemplateId
      );

      this.userShiftName = userShift!.name;
    } else {
      this.userShiftName = 'No shift selected';
    }
  }
}
