<div class="content">
    <img class="mx-auto mb-[60px] h-[80px] w-auto" src="./assets/img/ETRS-Logo3_ETRS-02.svg">
    <i class="fa-solid fa-lock text-[100px]"></i>
    <div class="message">
        <h1>Access to this page is restricted</h1>
        <p>Please check with the site admin if you believe this is a mistake.</p>
    </div>
    <button (click)="gotoHome()" cdkFocusInitial
        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow text-sm font-medium rounded-md text-black-default bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
        <i class="fa-solid fa-house mr-2"></i>
        {{ 'features.unauthorize.GO_TO_HOME_PAGE' | translate}}
    </button>
</div>