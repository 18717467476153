<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="['/admin/site']" [showChevron]=true>
</app-page-title>
<div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
    <div class="space-y-8 divide-y">

        <form [formGroup]="siteForm" (keydown.enter)="add($event)">
            <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                <div class="px-4 py-3 bg-yellow-default sm:px-6 text-black">
                    <h1 *ngIf="!siteId" class="font-semibold">{{ 'features.addsite.TITLE_HOME' | translate}}
                    </h1>
                    <h1 *ngIf="siteId" class="font-semibold">{{ 'features.addsite.TITLE_MODIFY' | translate}}
                    </h1>
                </div>

                <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                        <mat-form-field [style.width.%]="100">
                            <mat-label>{{ 'features.addsite.SITE' | translate}}</mat-label>
                            <input variant="outlined" matInput #name maxlength="25"
                                placeholder="{{ 'features.addsite.SELECT_SITE' | translate}}" formControlName="name">
                            <mat-hint align="end">{{name.value.length}} / 25</mat-hint>
                            <mat-error *ngIf="siteNameCtrl.hasError('required')">
                                {{ 'features.addsite.SITE' | translate}} {{ 'labels.IS' |
                                translate}} <strong>{{ 'labels.REQUIRED' |
                                    translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field [style.width.%]="100">
                            <mat-label>{{ 'features.addsite.CODE' | translate}}</mat-label>
                            <input variant="outlined" matInput
                                placeholder="{{ 'features.addsite.SELECT_CODE' | translate}}" formControlName="code">
                            <mat-error *ngIf="codeCtrl.hasError('required')">
                                {{ 'features.addsite.CODE' | translate}} {{ 'labels.IS' |
                                translate}} <strong>{{ 'labels.REQUIRED' |
                                    translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field [style.width.%]="100">
                            <mat-label>{{ 'features.addsite.DESCRIPTION' | translate}}</mat-label>
                            <textarea maxlength="200" #description matInput
                                placeholder="{{ 'features.addsite.SELECT_DESCRIPTION' | translate}}"
                                formControlName="description"></textarea>
                            <mat-hint align="end">{{description.value.length}} / 200</mat-hint>
                        </mat-form-field>
                    </div>

                    <div>
                        <app-user-autocomplete [name]="siteManagers" [ctrlName]="'managerObjectIds'" [form]="siteForm">
                        </app-user-autocomplete>
                    </div>

                    <div>
                        <app-user-autocomplete [required]="false" [name]="sitePlanners" [ctrlName]="'planerObjectIds'"
                            [form]="siteForm">
                        </app-user-autocomplete>
                    </div>

                    <div *ngIf="timezones">
                        <mat-form-field [style.width.%]="100">
                            <mat-label> Time zone </mat-label>
                            <mat-select [formControl]="timeZoneCtrl" name="timeZone">
                                <mat-option *ngFor="let timeZone of timezones" [value]="timeZone.id">
                                    {{timeZone.displayName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="timeZoneCtrl.hasError('required')">
                                {{ 'features.addsite.SELECT_TIMEZONE' | translate}} {{ 'labels.IS' |
                                translate}} <strong>{{ 'labels.REQUIRED' |
                                    translate}}</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <h2 class="mt-3">Shifts</h2>

                    <div class="flex flex-row flex-wrap gap-y-2 items-center gap-x-2 lg:gap-x-8 ">
                        <div *ngFor="let item of shiftsTemplate" (click)="toggleShift(item)"
                            [ngClass]="{'border-green-900 bg-green-600': item.isChecked, 'border-gray-200 bg-gray-100': !item.isChecked}"
                            class="w-full sm:w-1/3 rounded border p-2 cursor-pointer text-center">
                            <input [id]="item.id" type="checkbox" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="item.isChecked" [name]="item.name"
                                class="hidden w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label [for]="item.name"
                                [ngClass]="{'!text-white': item.isChecked, 'text-gray-900': !item.isChecked}"
                                class="py-4 ml-2 w-full text-sm font-medium">
                                {{item.name}}
                            </label>
                        </div>
                    </div>

                </div>
                <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button (click)="cancel()"
                        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {{ 'labels.CANCEL' | translate}}
                    </button>

                    <button (click)="add()"
                        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                        {{ 'labels.SAVE' | translate}}
                    </button>
                </div>

            </div>
        </form>

    </div>
</div>