import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { MsalService } from '@azure/msal-angular';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from '@app/utils/auth-config';
import { environment } from '@env';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private authService: MsalService) {}

  // User is logged out after 24h of connexion
  checkTokenValidaty(): void {
    let vfmLoginDate: any = localStorage.getItem('VFM_LOGIN_DATE');
    const currentDate = moment();

    if (vfmLoginDate) {
      vfmLoginDate = moment(vfmLoginDate, 'YYYY-MM-DD HH:mm:ss').add(1, 'day');

      if (currentDate > vfmLoginDate) {
        try {
          const myMsal = new PublicClientApplication(msalConfig);

          const logoutRequest = {
            account: myMsal.getAccountByHomeId(environment.clientId),
            postLogoutRedirectUri: environment.logoutRedirectUrl,
          };

          myMsal['browserStorage'].clear();

          this.authService.logoutRedirect(logoutRequest);
          localStorage.setItem('VFM_USER_PROFILE', '');
          localStorage.setItem('VFM_LOGIN_DATE', '');
        } catch (error) {}
      }
    }
  }
}
