<div [ngClass]="{'sidenav-collapse' : isSidenavCollapse}">
    <div>
        <div class="relative z-40 md:hidden" role="dialog" aria-modal="true">
            <div class="flex-shrink-0 w-14" aria-hidden="true">
                <!-- Dummy element to force sidebar to shrink to fit close icon -->
            </div>
        </div>
    </div>

    <app-site-nav></app-site-nav>
    <app-header></app-header>

    <div class="content sm:pl-14 lg:pl-64 flex flex-col flex-1">
        <main class="page-content">
            <router-outlet></router-outlet>
        </main>
    </div>
</div>