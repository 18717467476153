<div class="relative h-full">
    <div
        class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center shadow flex-wrap sm:flex-nowrap">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            <i class="fa-solid fa-person-walking-arrow-right mr-2"></i>
            {{ 'features.crew.LEAVE_CREW' | translate}}
        </h3>
    </div>

    <div>
        <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
            <div>
                <h1 class="text-xl font-semibold">
                    {{ 'features.crew.LEAVE_CONFIRMATION' | translate}}
                </h1>
            </div>
        </div>

        <div class="bg-gray-50 px-4 py-3 sm:px-6 text-right absolute w-full bottom-0 left-0">

            <button (click)="onNoClick()"
                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                {{ 'labels.NO' | translate}}
            </button>

            <button (click)="save()"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                {{ 'labels.YES' | translate}}
            </button>

        </div>

    </div>
</div>