import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  pageIconClass = 'fa-solid fa-gear';
  pageTitle = 'Settings';
  tab = 'profile';

  constructor(private router: Router) {}

  ngOnInit(): void {}

  selectTab(tab: string): void {
    this.tab = tab;
  }
}
