import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Member } from 'src/app/data';
import { CrewService } from '@app/services/data/crew.service';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';

@Component({
  selector: 'app-members-detail',
  templateUrl: './members-detail.component.html',
  styleUrls: ['./members-detail.component.scss'],
})
export class MembersDetailComponent extends VfmBaseComponent implements OnInit {
  pageIconClass = 'fa-solid fa-users';
  pageTitle = 'Members';
  isModal = false;
  prevPageLink: string = '';
  crewId!: number;
  selectMember!: Member;
  isAdmin = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private crewService: CrewService,
    private roleUserService: UserRoleService
  ) {
    super();
  }

  async ngOnInit() {
    const crewId = this.route.snapshot.paramMap.get('id');

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    if (crewId) {
      this.crewId = +crewId;
      this.prevPageLink = `/admin/crew/profile/${crewId}`;

      this.getCrew();
    }
  }

  toggleModal() {
    this.isModal = !this.isModal;
  }

  addMember() {
    this.router.navigate([`/admin/crew/profile/${this.crewId}/member/add`], {
      relativeTo: this.route,
      state: { parent: `/admin/crew/profile/${this.crewId}/` },
    });
  }

  selectedMember(member: Member) {
    this.selectMember = member;
  }

  getCrew() {
    this.crewService
      .getCrewById(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((crew) => {
        const userCrewManager = crew.managers?.find((manager) => {
          return manager.id === this.profile.id;
        });

        if (userCrewManager) {
          this.isAdmin = true;
        }
      });
  }
}
