<div class="sm:flex sm:items-start my-7 mx-10 whitespace-pre-wrap">
    <div class="mx-auto flex-shrink-0 flex items-center justify-center mt-[-12px] sm:mx-0 sm:h-16 sm:w-16">
        <img src="./assets/img/ETRS-Logo3_icon.svg" />
    </div>
    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <h3 class="text-lg leading-6 font-semibold text-gray-900" id="modal-title">Welcome to Equipment Tracking and
            Reservation System
        </h3>
        <div class="mt-4">
            <p class="text-base text-black">Please set the default values for your profile.</p>
        </div>
    </div>
</div>

<div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
    <button type="button" (click)="onNoClick()" cdkFocusInitial
        class="sm:ml-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">{{'labels.CLOSE'
        | translate}}
    </button>
</div>