import {
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@app/guards/unsaved-changes.guard';
import { DialogService } from '@app/services/common/dialog.service';
import { CategoryService } from '@app/services/data/category.service';
import { Observable, takeUntil, merge, startWith, switchMap, map } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Category } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { ImagePickerData } from '@shared/components/image-picker/image-picker.component';
import { VfmBaseComponent } from '../../../../../../VfmBaseComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DAYS } from '@app/constants/days.constants';
import * as moment from 'moment';
import {
  MbscEventcalendarOptions,
  MbscCalendarEvent,
  setOptions,
  MbscDatepickerOptions,
  localeEn,
  localeFr,
} from '@mobiscroll/angular';
import { EquipmentService } from '@app/services/data/equipment.service';
import { Reservation } from 'src/app/data/index';
import { RecurringDateComponent } from '../../../../../../reservation/components/reservation-add/components/reservation-add-detail/components/recurring-date/recurring-date.component';

@Component({
  selector: 'app-add-restriction',
  templateUrl: './add-restriction.component.html',
  styleUrls: ['./add-restriction.component.scss'],
})
export class AddRestrictionComponent
  extends VfmBaseComponent
  implements OnInit, CanComponentDeactivate
{
  @ViewChild(RecurringDateComponent) recurringDateComponent!: any;

  equipId!: number;
  restrictionId!: number;
  isFormSaved: boolean = false;
  pageTitle = this.translate.instant('features.addRestriction.TITLE_HOME');
  pageIconClass = 'fa-solid fa-circle-plus';
  restrictionForm!: FormGroup;
  prevLink = '';
  days = JSON.parse(JSON.stringify(DAYS));
  weekDays = [...this.days];
  selectedDays: string[] = [];
  selectedhours: any = null;
  selectedFromDate: any = '';
  selectedType: string = '';
  reccuringRule: string = '';
  fromDate: string = '';

  datepickerOptDate: MbscDatepickerOptions = {
    select: 'date',
    controls: ['calendar'],
  };

  datepickerOptTime: MbscDatepickerOptions = {
    select: 'range',
    controls: ['time'],
    stepMinute: 5,
  };

  types: any[] = [
    { id: 1, text: 'All Restricted' },
    { id: 2, text: 'Crew Restricted' },
    { id: 3, text: 'Soft Restricted' },
  ];

  constructor(
    private catService: CategoryService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private equipmentService: EquipmentService
  ) {
    super();
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.restrictionForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addcategory.CATEGORY_UNSAVED_CHANGES'),
        this.translate.instant('features.addcategory.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  ngOnInit(): void {
    this.equipId = +this.route.snapshot.paramMap.get('equipmentId')!;
    this.restrictionId = +this.route.snapshot.paramMap.get('id')!;

    this.buildForm();

    if (this.restrictionId) {
      this.loadRestriction();
    }

    if (this.router.url.includes('new')) {
      this.prevLink = this.router.url.split('/').slice(0, -1).join('/');
    } else {
      this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
    }

    this.restrictionForm.get('startHourEndHour')?.valueChanges.subscribe(() => {
      const startHourEndHour =
        this.restrictionForm.get('startHourEndHour')?.value;

      if (startHourEndHour && startHourEndHour.length === 2) {
        this.selectedhours =
          this.restrictionForm.get('startHourEndHour')?.value;

        this.selectedhours[0] = moment(this.selectedhours[0]).format('HH:mm');
        this.selectedhours[1] = moment(this.selectedhours[1]).format('HH:mm');
      }
    });

    this.restrictionForm.get('from')?.valueChanges.subscribe(() => {
      const selectedFromDate = this.restrictionForm.get('from')?.value;
    });

    this.restrictionForm.get('availabilityType')?.valueChanges.subscribe(() => {
      this.selectedType = this.getTypeNameById(
        +this.restrictionForm.get('availabilityType')?.value
      );
    });
  }

  /**
   * define the form for category entry
   */
  private buildForm() {
    this.restrictionForm = this.formBuilder.group({
      id: [''],
      availabilityType: ['', Validators.required],
      startHourEndHour: ['', Validators.required],
      from: ['', Validators.required],
      sitecode: [this.site.code],
      rRule: [''],
    });
  }

  confirmSave(event: any = null) {
    this.dialogService
      .confirm(
        this.translate.instant('features.restriction.CONFIRM_TITLE'),
        this.translate.instant('features.restriction.CONFIRM_DESCRIPTION')
      )
      .subscribe((result: any) => {
        if (result) {
          this.add(event);
        }
      });
  }

  /**
   *  Create or update a restriction
   */
  add(event: any = null) {
    event && event.preventDefault();

    this.restrictionForm.markAllAsTouched();

    const restriction: Reservation = {};
    restriction.startDate = this.selectedhours[0];
    restriction.endDate = this.selectedhours[1];
    restriction.availabilityType =
      +this.restrictionForm.controls['availabilityType'].value;
    restriction.rRule = this.reccuringRule || '';

    if (this.restrictionId) {
      restriction.id = this.restrictionId;
    }

    if (this.restrictionForm.controls['from']?.value) {
      const startDate = moment(
        this.restrictionForm.controls['from']?.value
      ).format('DD/MM/YY');

      restriction.startDate = moment(
        `${startDate}T${this.selectedhours[0]}`,
        'DD/MM/YY[T]HH:mm'
      ).format('YYYY-MM-DD[T]HH:mm');

      restriction.endDate = moment(
        `${startDate}T${this.selectedhours[1]}`,
        'DD/MM/YY[T]HH:mm'
      ).format('YYYY-MM-DD[T]HH:mm');
    }

    console.log('restriction', restriction);

    if (this.restrictionForm.invalid) {
    } else {
      this.equipmentService
        .saveAvailability(this.site.code, this.equipId, restriction)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.toastrService.success(
              this.translate.instant(
                this.restrictionId
                  ? 'features.addRestriction.UPDATE_SUCCESS'
                  : 'features.addRestriction.CREATE_SUCCESS'
              )
            );

            this.isFormSaved = true;
            this.goToRestrictionList();
          },
          error: (error: HttpErrorResponse) => {
            console.log('Error message', error.message);
          },
        });
    }
  }

  /**
   *
   */
  cancel() {
    this.goToRestrictionList();
  }

  /**
   * Delete a category
   */
  deleteRestriction() {
    this.equipmentService
      .deleteAvailability(this.site.code, this.equipId, this.restrictionId)
      .subscribe(() => {
        this.goToRestrictionList();

        this.toastrService.success(
          this.translate.instant('features.addRestriction.DELETE_SUCCESS')
        );
      });
  }

  confirmRemoveRestriction() {
    this.dialogService
      .confirm(
        this.translate.instant('features.addRestriction.CONFIRM_DELETE.TITLE'),
        this.translate.instant(
          'features.addRestriction.CONFIRM_DELETE.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteRestriction();
        }
      });
  }

  loadRestriction() {
    this.equipmentService
      .getAvailabilitiesByEquipmentId(this.site.code, this.equipId!)
      .subscribe((result) => {
        const restriction = result.data.find(
          (restriction: Reservation) => restriction.id === this.restrictionId
        );

        this.restrictionForm.patchValue(restriction!);

        this.restrictionForm.controls['from'].patchValue(
          restriction?.startDate
        );

        this.recurringDateComponent.setCustomRule(restriction!.rRule);

        this.restrictionForm.controls['startHourEndHour'].patchValue([
          moment(restriction?.startDate).format('YYYY-MM-DD[T]HH:mm:ss'),
          moment(restriction?.endDate).format('YYYY-MM-DD[T]HH:mm:ss'),
        ]);
      });
  }

  /**
   * Return to restriction List
   */
  private goToRestrictionList() {
    this.router.navigate([this.prevLink]);
  }

  get restrictionTypeCtrl() {
    return this.restrictionForm.get('availabilityType') as FormControl;
  }

  get restrictionStartHourEndHourCtrl() {
    return this.restrictionForm.get('startHourEndHour') as FormControl;
  }

  get restrictionDaysCtrl() {
    return this.restrictionForm.get('days') as FormControl;
  }

  get restrictionFromCtrl() {
    return this.restrictionForm.get('from') as FormControl;
  }

  onDaysChange(event: any) {
    let weekdays: any = this.weekDays.filter((day) => day.checked);

    this.selectedDays = weekdays.map((day: any) => {
      if (day.checked) {
        return day.short;
      }
    });

    this.restrictionForm.get('days')?.patchValue(this.selectedDays);

    console.log('this.selectedDays', this.selectedDays);
  }

  getTypeNameById(id: number): string {
    let typeName: string = '';

    switch (id) {
      case 1:
        typeName = 'All Restricted';
        break;
      case 2:
        typeName = 'Crew Restricted';
        break;
      case 3:
        typeName = 'Soft Restricted';
        break;
    }

    return typeName;
  }

  onRecurringRuleChange(event: any) {
    console.log('onRecurringRuleChange', event);
    this.reccuringRule = event;
  }
}
