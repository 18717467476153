<div class="md:mx-20 ">
    <!-- Update - open in a modal information-->
    <div class="grid grid-cols-6 gap-4 text-left">
        <div  class="  col-span-6 block  m-5 ">
            <h3 class="text-3xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.TITLE'| translate }}</h3>
            
        </div>
        <div  class="  col-span-6 lg:col-span-3 block  m-5 ">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.ADMIN'| translate }}</h3>
            <hr class="hrVideoTutorial">
            <mat-list>
            <mat-list-item *ngFor="let item of videoTutorial.ADMIN"> <button (click)="openDialog(item.URL)" class="AEMhover">{{item.TITLE}}</button></mat-list-item>
            </mat-list>
        </div>

        <div  class="  col-span-6 lg:col-span-3 block  m-5 ">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.SETTINGS'| translate }}</h3>
            <hr class="hrVideoTutorial">
            <mat-list>
            <mat-list-item *ngFor="let item of videoTutorial.SETTINGS"> <button (click)="openDialog(item.URL)" class="AEMhover">{{item.TITLE}}</button></mat-list-item>
            </mat-list>
        </div>

        <div  class="  col-span-6 lg:col-span-3 block  m-5 ">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.CREW'| translate }}</h3>
            <hr class="hrVideoTutorial">
            <mat-list>
            <mat-list-item *ngFor="let item of videoTutorial.CREW"> <button (click)="openDialog(item.URL)" class="AEMhover">{{item.TITLE}}</button></mat-list-item>
            </mat-list>
        </div>

        <div  class="  col-span-6 lg:col-span-3 block  m-5 ">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.EQUIPMENT'| translate }}</h3>
            <hr class="hrVideoTutorial">
            <mat-list>
            <mat-list-item *ngFor="let item of videoTutorial.EQUIPMENT"> <button (click)="openDialog(item.URL)" class="AEMhover">{{item.TITLE}}</button></mat-list-item>
            </mat-list>
        </div>

        <div  class="  col-span-6 lg:col-span-3 block  m-5 ">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 ">{{ 'features.faq.TUTORIAL_VIDEO.RESERVATION'| translate }}</h3>
            <hr class="hrVideoTutorial">
            <mat-list>
            <mat-list-item *ngFor="let item of videoTutorial.RESERVATION"> <button (click)="openDialog(item.URL)" class="AEMhover">{{item.TITLE}}</button></mat-list-item>
            </mat-list>
        </div>
    </div>

    

    
</div>
