import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { Crew, PaginatedItems } from 'src/app/data';
import { handleError } from './handleError';

const CREW_API_ENDPOINT = 'crew/site';

export type CrewCreateRequest = Omit<Crew, 'id' | 'sitecode'>;
export type CrewUpdateRequest = Omit<Crew, 'sitecode'>;

@Injectable({
  providedIn: 'root',
})
export class CrewService {
  constructor(private http: HttpClient) {}

  /**
   * Get paginated crews
   * @returns an observable of a list  of crews
   */
  getPaginatedCrews(sitecode: string, pageSize = 10, pageIndex = 0) {
    return this.http
      .get<PaginatedItems<Crew>>(
        `${CREW_API_ENDPOINT}/${sitecode}?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Get paginated crews by user
   * @returns an observable of a list  of crews
   */
  getPaginatedCrewsByUser(
    sitecode: string,
    userObjectId: string,
    isAdmin: boolean,
    isManager: boolean,
    isMember: boolean,
    pageSize = 10,
    pageIndex = 0
  ) {
    return this.http
      .get<PaginatedItems<Crew>>(
        `${CREW_API_ENDPOINT}/${sitecode}/byuser/${userObjectId}?isAdmin=${isAdmin}&isManager=${isManager}&isMember=${isMember}&pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Save or update a crew
   * @param crew to save
   * @returns an observable of the saved crew
   */
  saveCrew(crew: Crew) {
    if (crew.id) {
      return this.updateCrew(crew);
    }
    return this.createCrew(crew);
  }

  /**
   *
   * @param id
   * @returns
   */
  getCrewById(id: number, sitecode: string) {
    return this.http
      .get<Crew>(`${CREW_API_ENDPOINT}/${sitecode}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param id
   * @returns
   */
  getReservationApprovals(id: number, sitecode: string) {
    return this.http
      .get<Crew>(`${CREW_API_ENDPOINT}/${sitecode}/${id}/reservationapprovals`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  private createCrew(crew: Crew) {
    const newCrew: CrewCreateRequest = {
      name: crew.name,
      description: crew.description,
      managerObjectIds: crew.managerObjectIds,
    };

    return this.http
      .post<CrewCreateRequest>(`${CREW_API_ENDPOINT}/${crew.sitecode}`, newCrew)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  public deleteCrew(siteCode: number, crewId: number) {
    return this.http
      .delete<CrewCreateRequest>(`${CREW_API_ENDPOINT}/${siteCode}/${crewId}`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param crew
   * @returns
   */
  private updateCrew(crew: Crew) {
    const exsitingCrew: CrewUpdateRequest = {
      id: crew.id,
      name: crew.name,
      description: crew.description,
      managerObjectIds: crew.managerObjectIds,
    };

    return this.http
      .put<CrewUpdateRequest>(
        `${CREW_API_ENDPOINT}/${crew.sitecode}/${crew.id}`,
        exsitingCrew
      )
      .pipe(catchError(handleError));
  }

  /**
   * @returns an observable of a list of crews members
   */
  getCrewMembers(id: number, sitecode: string) {
    return this.http
      .get<Crew>(`${CREW_API_ENDPOINT}/${sitecode}/${id}/member`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  addCrewMember(id: number, sitecode: string, objectIds: string) {
    const crewMember = {
      objectIds,
    };

    return this.http
      .post<CrewCreateRequest>(
        `${CREW_API_ENDPOINT}/${sitecode}/${id}/member`,
        crewMember
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param id
   * @param sitecode
   * @param userObjectId
   * @param comment
   * @returns
   */
  joinCrew(
    id: number,
    sitecode: string,
    userObjectId: string,
    comment: string
  ) {
    const payload = {
      userObjectId,
      comment,
    };

    return this.http
      .post<CrewCreateRequest>(
        `${CREW_API_ENDPOINT}/${sitecode}/${id}/member/join`,
        payload
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param id
   * @param sitecode
   * @returns
   */
  leaveCrew(id: number, sitecode: string) {
    return this.http
      .delete<CrewCreateRequest>(
        `${CREW_API_ENDPOINT}/${sitecode}/${id}/member/leave`
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param id
   * @param sitecode
   * @param approvalId
   * @param comment
   * @param status
   * @returns
   */
  updateCrewMemberApproval(
    id: number,
    sitecode: string,
    approvalId: number,
    comment: string,
    status: number
  ) {
    const payload = {
      status,
      comment,
    };

    return this.http
      .put<CrewCreateRequest>(
        `${CREW_API_ENDPOINT}/${sitecode}/${id}/member/complete/approval/${approvalId}`,
        payload
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  deleteCrewMember(id: number, sitecode: string, memberId: number) {
    return this.http
      .delete<CrewCreateRequest>(
        `${CREW_API_ENDPOINT}/${sitecode}/member/${id}/remove/${memberId}`
      )
      .pipe(catchError(handleError));
  }
}
