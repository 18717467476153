import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Reservation, ReservationMobiscroll } from 'src/app/data/index';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.scss'],
})
export class ReservationsListComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  @Output() reservation: EventEmitter<Reservation> =
    new EventEmitter<Reservation>();
  @Input() reservations!: Reservation[] | null;
  @Input() selectedReservationId!: number;
  @Input() weekDate!: any;

  myReservations!: Reservation[];
  selectedReservation!: Reservation;
  reservationId!: number | null;
  isAdmin = false;

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    if (this.router.url.includes('/admin')) {
      this.isAdmin = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedReservationId &&
      changes.selectedReservationId.currentValue
    ) {
      this.reservationId = changes.selectedReservationId.currentValue;
    }

    if (changes.weekDate && changes.weekDate.currentValue) {
      this.reservationId = null;
    }
  }

  getDate(date: string) {
    return moment(date).format('MMM Do YYYY, h:mm a');
  }

  selectReservation(reservation: Reservation) {
    this.selectedReservation = reservation;
    this.reservationId = reservation.id!;
    this.reservation.emit(reservation);
  }

  addMember() {}
}
