import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Shift, ShiftTemplate } from 'src/app/data';
import { handleError } from './handleError';

const SHIFT_API_ENDPOINT = 'shifttemplate';

@Injectable({
  providedIn: 'root',
})
export class ShiftService {
  constructor(private http: HttpClient) {}

  /**
   * Get Result
   * @returns an observable of <Shift>
   */
  getShiftTemplate() {
    return this.http
      .get<ShiftTemplate>(`${SHIFT_API_ENDPOINT}`)
      .pipe(catchError(handleError));
  }

  getShiftById(id: number, startDate: string, ruleType: number) {
    return this.http
      .get<Shift>(
        `${SHIFT_API_ENDPOINT}/template/${id}?startDate=${startDate}&ruleType=${ruleType}`
      )
      .pipe(catchError(handleError));
  }
}
