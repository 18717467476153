import { Injectable, Inject, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(
    @Inject(Injector) private injector: Injector,
    private router: Router
  ) {}

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastrService(): ToastrService {
    return this.injector.get(ToastrService);
  }

  // Need to get TranslateService from injector rather than constructor injection to avoid cyclic dependency error
  private get translate(): TranslateService {
    return this.injector.get(TranslateService);
  }

  // Handling HTTP Errors using Toaster
  public handleError(err: HttpErrorResponse) {
    let errorMessage: string = this.translate.instant('error.ERROR_STATUS.500');
    let errorMessageName: string = this.translate.instant('error.API_ERROR');

    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = this.translate.instant('error.ERROR_OCCURRED');
    } else {
      // The backend returned an unsuccessful response code.
      const error = err.error;

      switch (error?.status) {
        case 500:
          errorMessage = this.translate.instant('error.ERROR_STATUS.500');
          break;
        case 404:
          errorMessage = this.translate.instant('error.ERROR_STATUS.404');
          break;
        case 403:
          errorMessage = this.translate.instant('error.ERROR_STATUS.403');
          break;
        case 400:
          errorMessage = this.translate.instant(
            `error.ERROR_STATUS.400.${error.errorCode}.MESSAGE`
          );
          break;
        default:
          errorMessageName = this.translate.instant('error.ERROR_STATUS.500');
          break;
      }
    }

    errorMessage += `<div>&nbsp;</div><b style='margin-top:10px;'>${this.translate.instant(
      'error.CLICK_TO_VIEW_FAQ_PAGE'
    )}</b>`;

    this.toastrService
      .error(errorMessage, ` ${errorMessageName}:`, {
        enableHtml: true,
        closeButton: true,
      })
      .onTap.pipe(take(1))
      .subscribe(() => this.toasterClickedHandler());
  }

  toasterClickedHandler() {
    this.router.navigate(['faq']);
  }
}
