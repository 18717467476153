<div class="relative" *ngIf="site">
    <button type="button" (click)="toggleSiteMenu()"
        class="text-black-default group bg-blue-default rounded-md inline-flex items-center text-base font-medium focus:outline-none "
        aria-expanded="false">
        <span>{{ site.name }}</span>

        <i class="fa-solid fa-chevron-down ml-2"></i>
    </button>

    <div @opacityTranslateY *ngIf='isSiteMenu'
        class="absolute z-10 transform  mt-3 px-2 w-[300px] sm:px-0 right-[-80px]">
        <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div class="max-h-[400px] overflow-y-auto relative bg-white py-6 px-5 grid gap-6 sm:gap-8 sm:p-8">
                <a *ngFor="let siteMenu of sites; index as i" href="javascript://void(0)"
                    (click)="confirmUpdateSite(siteMenu)" [ngClass]="{'selected': siteMenu.id === site.id}"
                    class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                    <i class="fa-solid fa-house text-[20px] text-black-default"></i>
                    <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">{{ siteMenu.name }}</p>
                        <p class="w-[180px] truncate mt-1 text-sm text-gray-500">{{ siteMenu.description }} </p>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>