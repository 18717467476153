import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Role, PaginatedItems } from 'src/app/data';
import { handleError } from './handleError';

export type RoleCreateRequest = Omit<Role, 'id' | 'sitecode'>;
export type RoleUpdateRequest = Omit<Role, 'sitecode'>;

const CAT_API_ENDPOINT = 'role/site';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Category>
   */
  getRoles(sitecode: string, pageSize = 10, pageIndex = 0) {
    return this.http
      .get<PaginatedItems<Role>>(
        `${CAT_API_ENDPOINT}/${sitecode}?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Save or update a role
   * @param role to save
   * @returns an observable of the saved role
   */
  saveRole(category: Role) {
    if (category.id) {
      return this.updateRole(category);
    }
    return this.createRole(category);
  }

  getRoleById(id: number, sitecode: string) {
    return this.http
      .get<Role>(`${CAT_API_ENDPOINT}/${sitecode}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param role
   * @returns
   */
  private createRole(role: Role) {
    const newCat: RoleCreateRequest = {
      Name: role.Name,
      description: role.description,
      Value: role.Value,
    };

    return this.http
      .post<RoleCreateRequest>(`${CAT_API_ENDPOINT}/${role.sitecode}`, newCat)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  private updateRole(role: Role) {
    const existingCat: RoleUpdateRequest = {
      id: role.id,
      Name: role.Name,
      description: role.description,
      Value: role.Value,
    };

    return this.http
      .put<RoleUpdateRequest>(
        `${CAT_API_ENDPOINT}/${role.sitecode}/${role.id}`,
        existingCat
      )
      .pipe(catchError(handleError));
  }

  /**
   * @returns an observable of a list of crews members
   */
  getRoleMembers(id: number, sitecode: string) {
    return this.http
      .get<Role>(`${CAT_API_ENDPOINT}/${sitecode}/${id}/member`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  addRoleMember(id: number, sitecode: string, objectIds: string) {
    const crewMember = {
      objectIds,
    };

    return this.http
      .post<RoleCreateRequest>(
        `${CAT_API_ENDPOINT}/${sitecode}/${id}/member`,
        crewMember
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  deleteRoleMember(id: number, sitecode: string, memberId: number) {
    return this.http
      .delete<RoleCreateRequest>(
        `${CAT_API_ENDPOINT}/${sitecode}/member/${id}/remove/${memberId}`
      )
      .pipe(catchError(handleError));
  }
}
