import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';

@Component({
  selector: 'app-reject-reservation-request',
  templateUrl: './reject-reservation-request.component.html',
  styleUrls: ['./reject-reservation-request.component.scss'],
})
export class RejectReservationRequestComponent
  extends VfmBaseComponent
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<RejectReservationRequestComponent>,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  rejectReservationForm!: FormGroup;

  ngOnInit(): void {
    this.buildSiteForm();
  }

  /**
   * define the form
   */
  private buildSiteForm() {
    this.rejectReservationForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (!this.rejectReservationForm.invalid) {
      this.dialogRef.close(
        this.rejectReservationForm.controls['comment'].value
      );
    }
  }

  /**
   * Get the form control for the comment
   */
  get commentCtrl() {
    return this.rejectReservationForm.get('comment') as FormControl;
  }
}
