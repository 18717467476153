import { Component, OnInit } from '@angular/core';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { ToastrService } from 'ngx-toastr';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CrewService } from '@app/services/data/crew.service';
import { ApprovalStatus } from '@app/enums/approval-status-enum';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { RejectReservationRequestComponent } from '../reject-reservation-request/reject-reservation-request.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-pending-reservation-request',
  templateUrl: './pending-reservation-request.component.html',
  styleUrls: ['./pending-reservation-request.component.scss'],
})
export class PendingReservationRequestComponent
  extends VfmBaseComponent
  implements OnInit
{
  crewId!: number;
  reservationApprovals: any = [];

  constructor(
    private toastrService: ToastrService,
    private equipmentService: EquipmentService,
    private crewService: CrewService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (crewId) {
      this.crewId = +crewId;
      this.loadReservationApprovals();
    }
  }

  loadReservationApprovals() {
    this.crewService
      .getReservationApprovals(this.crewId, this.site.code)
      .subscribe((result) => {
        this.reservationApprovals = result;
      });
  }

  public getCustomRule(rRule: string) {
    if (rRule) {
      // convert rRule string to object
      const arrOfObjRules = rRule
        .split(';')
        .map((x) => x.split('=').map((y) => y.trim()))
        .reduce((a: any, x: any) => {
          a[x[0]] = x[1];
          return a;
        }, {});

      console.log('arrOfObjRules', arrOfObjRules);

      return arrOfObjRules;
    }
  }

  confirmRejectReservationRequest(id: number, event: any) {
    event.preventDefault();

    const dialogRef = this.dialog.open(RejectReservationRequestComponent, {
      width: '550px',
      height: '350px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.rejectReservationRequest(id, result);
      }
    });
  }

  rejectReservationRequest(reservation: any, comment: string) {
    this.equipmentService
      .completeApproval(
        this.site.code,
        reservation.reservation.equipmentId,
        reservation.reservation.id,
        reservation.id,
        ApprovalStatus.rejected,
        comment
      )
      .subscribe((result) => {
        this.loadReservationApprovals();

        this.toastrService.success(
          this.translate.instant(
            'features.crew.CREW_SUCCESSFULLY_REJECT_REQUEST'
          )
        );
      });
  }

  approveReservationRequest(reservation: any, event: any) {
    event.preventDefault();

    this.equipmentService
      .completeApproval(
        this.site.code,
        reservation.reservation.equipmentId,
        reservation.reservation.id,
        reservation.id,
        ApprovalStatus.approved,
        ''
      )
      .subscribe((result) => {
        this.loadReservationApprovals();

        this.toastrService.success(
          this.translate.instant(
            'features.crew.CREW_SUCCESSFULLY_APPROVE_REQUEST'
          )
        );
      });
  }

  getDate(dateStr: string): string {
    return moment(dateStr).format('YYYY-MM-DD');
  }

  getHour(dateStr: string): string {
    return moment(dateStr).format('HH:mm');
  }
}
