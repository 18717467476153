<div class="reservation-detail">
    <div class="mx-auto">

        <div *ngIf="!isFromMemberList"
            class="py-2 px-4 flex-col flex items-center justify-between mt-[-48px] sm:mt-[-20px]"
            style="background: #f7f7f7;">
            <div class="flex self-end space-x-3 md:mt-0 md:ml-4">
                <div class="md:mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button (click)="showAdd()" type="button"
                        class="hidden sm:inline-flex  items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-800">
                        <i class="fa-solid fa-plus sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.ADD_RESERVATION' | translate}}
                        </span>

                    </button>
                </div>
            </div>
        </div>


        <div class="">
            <div class="flex justify-end">
                <mbsc-switch themeVariant="light" class="mb-10" color="primary"
                    class="switch-calendar-view bg-transparent w-[175px] absolute z-10"
                    [label]="'features.reservation.TABLE_VIEW' | translate" [(ngModel)]="isTableView"
                    (ngModelChange)="onTableViewChanged()">
                </mbsc-switch>
            </div>


            <mbsc-eventcalendar *ngIf="isTableView" [invalid]="invalidDate" [locale]="locale" #eventCalendar
                class="mb-10" [data]="myEvents" [options]="eventSettingsTable"></mbsc-eventcalendar>

            <mbsc-eventcalendar *ngIf="!isTableView" [invalid]="invalidDate" [view]="timelineView" [locale]="locale"
                #eventCalendar class="mb-10" [data]="myEvents" [options]="eventSettings"
                [resourceTemplate]="resourceTemp" [resources]="timelineResources" [headerTemplate]="customTemplate"
                [(selectedDate)]="currentDate">
                <ng-template #customTemplate>
                    <mbsc-calendar-nav class="md-custom-header-nav"></mbsc-calendar-nav>
                    <div class="md-custom-header-controls relative">
                        <div class="flex justify-center items-center">
                            <div class="cursor-pointer mr-4 text-[#009cde]" (click)="navigatePage(true)">
                                <i class="fa-solid fa-chevron-left mr-0 mt-1"></i>
                                <span class="text-[13px] font-bold relative top-[-1.5px]">
                                    {{ 'features.reservation.PREV_WEEK' | translate}}
                                </span>
                            </div>

                            <div class="text-gray-500">
                                <i class="fa-solid fa-calendar mr-2"></i>
                                <span class="text-lg">{{firstDayOfWeek()}}</span>
                                <span class="text-lg text-black ml-1 mr-1">-</span>
                                <span class="text-lg">{{lastDayOfWeek()}}</span>
                            </div>

                            <div class="cursor-pointer ml-4 text-[#009cde]" (click)="navigatePage(false)">
                                <span class="text-[13px] font-bold relative top-[-1.5px]">
                                    {{ 'features.reservation.NEXT_WEEK' | translate}}
                                </span>
                                <i class="fa-solid fa-chevron-right ml-0 mt-1"></i>
                            </div>

                            <div class="cursor-pointer ml-6 rounded-md border bg-white border-[#009cde] h-[24px] leading-[18px]"
                                (click)="navigateToday()">
                                <span class="text-[#009cde] text-[10px] font-bold px-2.5 ">{{
                                    'features.reservation.VIEW_TODAY' | translate}}</span>
                            </div>

                        </div>


                        <!-- <mbsc-switch themeVariant="light" class="mb-10" color="primary"
                            [label]="'features.reservation.TABLE_VIEW' | translate" [style.width.%]="100"
                            [ngModelOptions]="{standalone: true}" [(ngModel)]="isTableView"
                            (ngModelChange)="onTableViewChanged()">
                        </mbsc-switch> -->

                    </div>

                </ng-template>
                <ng-template #resourceTemp let-resource>
                    <div class="equipment-resource m-auto block">
                        <div class="equipment-resource-name text-center">{{resource.name}}</div>
                        <img class="equipment-resource-image m-auto block w-[60%] h-auto " [attr.alt]="resource.name"
                            [src]="resource.img" />
                    </div>
                </ng-template>
            </mbsc-eventcalendar>
        </div>

        <mbsc-popup class="md-tooltip" #popup [anchor]="anchor" [options]="popupOptions">
            <div *ngIf="hoverEvent" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                <div class="md-tooltip-header" [ngStyle]="{background: hoverEvent?.color!}">
                    <span class="md-tooltip-name-age">{{hoverEvent?.userName!}}</span>
                    <span class="md-tooltip-time">{{time}}</span>
                </div>

                <div class="flex justify-around">
                    <div class="mt-3 mb-2 flex justify-center">
                        <button *ngIf="canEdit(hoverEvent?.equipementCrewId, profile.id)" type="button"
                            (click)="editAppointment(hoverEvent?.id,hoverEvent?.start)"
                            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-default w-[140px] text-center">
                            <i class="fa-solid fa-pen sm:mr-2">
                            </i>
                            <span class="hidden md:inline-block">
                                Edit
                            </span>
                        </button>
                    </div>

                    <div class="mt-3 mb-2 flex justify-center">
                        <button type="button" *ngIf="canEdit(hoverEvent?.equipementCrewId, profile.id)"
                            (click)="confirmRemoveReservation(hoverEvent?.equipmentId, hoverEvent?.id)"
                            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 w-[140px] text-center">
                            <i class="fa-solid fa-trash sm:mr-2">
                            </i>
                            <span class="hidden md:inline-block">
                                Delete
                            </span>
                        </button>
                    </div>
                </div>

                <div class="md-tooltip-info">
                    <div class="md-tooltip-title">
          
                        <div *ngIf="hoverEvent?.requestor">
                            <span class="md-tooltip-title pr-1"> {{'features.addReservation.RESERVEDBY' | translate}}</span>
                            <span class="md-tooltip-text">
                                {{hoverEvent?.requestor.name || 'N/A'}}
                            </span>
                        </div>
                        Equipment: <span class="md-tooltip-status md-tooltip-text">{{hoverEvent?.equipmentName}}</span>
                        <div>
                            <span class="md-tooltip-title pr-1">Comment:</span>
                            <span class="md-tooltip-text">
                                {{hoverEvent?.description || 'N/A'}}
                            </span>
                        </div>
               
                        <!-- 
                        <mbsc-button [color]="buttonType" variant="outline" class="md-tooltip-status-button"
                            (click)="setStatus()">{{buttonText}}</mbsc-button> -->
                    </div>

                    <div class="md-tooltip-title">
                        <div *ngIf="hoverEvent?.recurring">
                            <div class="rounded bg-gray-300 w-20 px-1">Recurring:</div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).FREQ" class="">
                                <span class="md-tooltip-title pr-1">Frequency: </span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).FREQ}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).INTERVAL" class="">
                                <span class="md-tooltip-title pr-1">Interval:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).INTERVAL}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).BYDAY" class="">
                                <span class="md-tooltip-title pr-1">Day:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).BYDAY}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).UNTIL" class="">
                                <span class="md-tooltip-title pr-1">Until:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).UNTIL}}</span>
                            </div>
                        </div>
                    </div>

                    <button type="button" (click)="confirmStopCurrentReservation(hoverEvent.equipmentId, hoverEvent.id)"
                        *ngIf="canStopCurrentReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                        <i class="fa-solid fa-circle-stop sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.END_CURRENT_RESERVATION' | translate}}
                        </span>

                    </button>
                   
                    <button type="button"
                        (click)="confirmCancelRecurringReservationInstance(hoverEvent.equipmentId, hoverEvent.id, hoverEvent.start)"
                        *ngIf="canCancelInstanceRecurrenceReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                        <i class="fa-solid fa-circle-stop sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.CANCEL_INSTANCE_RECURRING_RESERVATION' | translate}}
                        </span>
                    </button>
                    <div >
                        <button type="button"
                            (click)="confirmStopRecurringReservationSeries(hoverEvent.equipmentId, hoverEvent.id, hoverEvent.start)"
                            *ngIf="canCancelSeriesRecurrenceReservationHover(hoverEvent)"
                            class="hidden sm:inline-flex items-center my-4 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 ">
                            <i class="fa-solid fa-circle-stop sm:mr-2">
                            </i>
                            <span class="hidden md:inline-block">
                                {{ 'features.reservation.CANCEL_SERIES_RECURRING_RESERVATION' | translate}}
                            </span>
                        </button>
                    </div>

                    <button type="button" (click)="confirmExtendReservation(hoverEvent)"
                        *ngIf="canExtendReservationHover(hoverEvent)"
                        class="hidden sm:inline-flex items-center px-4 py-2 mt-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-800 ">
                        <i class="fa-solid fa-clock sm:mr-2">
                        </i>
                        <span class="hidden md:inline-block">
                            {{ 'features.reservation.EXTEND_RESERVATION' | translate}} (15mn)
                        </span>
                    </button>

                    <app-map *ngIf="hoverEvent?.deviceId && hoverEvent?.isAutoPIEnabled"
                        [deviceId]="hoverEvent?.deviceId" [isPopup]="true" (click)="openDialog(hoverEvent?.deviceId)"
                        class="cursor-pointer"></app-map>

                </div>
            </div>
        </mbsc-popup>

    </div>
</div>

<div *ngIf="!isFromMemberList && isEventSelected"
    class="shadow-xl sm:hidden fixed bottom-0 w-full px-[10px] py-[15px] !pt-[40px]"
    style="background-color: rgba(0, 0, 0, 0.8); z-index: 100; background-color: rgba(0, 0, 0, 0.8);">
    <div class="md:mt-6 flex flex-wrap gap-y-2 space-x-3 md:mt-0 md:ml-4">
        <button (click)="confirmRemoveReservation(null, null)" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600">
            <i class="fa-solid fa-trash-can mr-2">
            </i>
            <span>
                {{ 'features.reservation.DELETE' | translate }}
            </span>
        </button>
        <button type="button"
            (click)="confirmCancelRecurringReservationInstance(selectedReservation.equipmentId, selectedReservation.id, selectedReservation.start)"
            *ngIf="canCancelInstanceRecurrenceReservationHover(selectedReservation)"
            [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800">
            <i class="fa-solid fa-circle-stop mr-2">
            </i>
            <span class="">
                {{ 'features.reservation.CANCEL' | translate}}
            </span>
        </button>
        <button type="button" (click)="confirmExtendReservation(selectedReservation)"
            *ngIf="canExtendReservationHover(selectedReservation)" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800">
            <i class="fa-solid fa-clock mr-2">
            </i>
            <span class="">
                {{ 'features.reservation.EXTEND' | translate}} (15mn)
            </span>
        </button>
        <button (click)="showEdit()" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-darker">
            <i class="fa-solid fa-pen mr-2">
            </i>
            <span>
                {{ 'features.reservation.EDIT' | translate }}
            </span>
        </button>
        <button (click)="confirmStopCurrentReservation(selectedReservation.equipmentId, selectedReservation.id)"
            *ngIf="canStopCurrentReservation()" type="button" [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800">
            <i class="fa-solid fa-circle-stop mr-2">
            </i>
            <span>
                {{ 'features.reservation.END_CURRENT' | translate }}
            </span>
        </button>
        <button (click)="openDialog(selectedReservation.deviceId)" type="button"
            [ngClass]="{'invisible': !isEventSelected}"
            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600">
            <i class="fa-solid fa-location mr-2">
            </i>
            <span>
                {{ 'features.equipmentMapLocation.MAP' | translate }}
            </span>
        </button>

        <i (click)="closeActionbar()"
            class="fa-solid fa-circle-xmark absolute right-[11px] top-[5px] text-white text-[29px] cursor-pointer"
            style="z-index: 100;"></i>
    </div>
</div>