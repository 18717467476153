import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class EquipmentService {
  public equipment: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  updateEquipment(param: boolean): void {
    this.equipment.next(param);
  }

  getModifyEquipment(): Observable<boolean> {
    return this.equipment.asObservable();
  }
}
