import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Category, PaginatedItems } from 'src/app/data';
import { handleError } from './handleError';

export type CategoryCreateRequest = Omit<Category, 'id' | 'sitecode'>;
export type CategoryUpdateRequest = Omit<Category, 'sitecode'>;

const CAT_API_ENDPOINT = 'category/site';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private http: HttpClient) {}

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Category>
   */
  getCategories(sitecode: string, pageSize = 0, pageIndex = 0) {
    return this.http
      .get<PaginatedItems<Category>>(
        `${CAT_API_ENDPOINT}/${sitecode}?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Save or update a category
   * @param category to save
   * @returns an observable of the saved category
   */
  saveCategory(category: Category) {
    if (category.id) {
      return this.updateCategory(category);
    }
    return this.createCategory(category);
  }

  getCategoryById(id: number, sitecode: string) {
    return this.http
      .get<Category>(`${CAT_API_ENDPOINT}/${sitecode}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  private createCategory(category: Category) {
    const newCat: CategoryCreateRequest = {
      categoryName: category.categoryName,
      description: category.description,
      pictureUri: category.pictureUri,
      pictureName: category.pictureName,
    };

    return this.http
      .post<CategoryCreateRequest>(
        `${CAT_API_ENDPOINT}/${category.sitecode}`,
        newCat
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  private updateCategory(category: Category) {
    const existingCat: CategoryUpdateRequest = {
      id: category.id,
      categoryName: category.categoryName,
      description: category.description,
      pictureUri: category.pictureUri,
      pictureName: category.pictureName,
    };

    return this.http
      .put<CategoryUpdateRequest>(
        `${CAT_API_ENDPOINT}/${category.sitecode}/${category.id}`,
        existingCat
      )
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param category
   * @returns
   */
  public deleteCategory(siteCode: number, categoryId: number) {
    return this.http
      .delete(`${CAT_API_ENDPOINT}/${siteCode}/${categoryId}`)
      .pipe(catchError(handleError));
  }
}
