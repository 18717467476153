<div class="px-4 sm:px-6 lg:px-8">

    <div class="mt-8 flow-root">
        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 h-[600px]">
                <div class="overflow-hidden shadow sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    {{ 'features.equipmentMapLocation.STATE' | translate}}
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ 'features.equipmentMapLocation.LOCATION' | translate}}
                                </th>
                                <th scope="col"
                                    class="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ 'features.equipmentMapLocation.DATE' | translate}}
                                </th>
                                <th scope="col"
                                    class="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    {{ 'features.equipmentMapLocation.TIME' | translate}}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr *ngFor="let geoFence of geoFences" class="even:bg-gray-50">
                                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {{geoFence.state}}</td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                                    {{geoFence.fenceName}}
                                    <div class="sm:hidden text-xs text-gray-500">
                                        {{momentJS(geoFence.eventDateUTC).format('YYYY-MM-DD - hh:mm
                                        a')}}</div>
                                </td>
                                <td class="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {{momentJS(geoFence.eventDateUTC).format('YYYY-MM-DD')}}
                                </td>
                                <td class="hidden lg:table-cell whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {{momentJS(geoFence.eventDateUTC).format('hh:mm a')}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>