import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { VfmBaseComponent } from '../../../VfmBaseComponent';
import { EquipmentService } from '@app/services/data/equipment.service';
import { takeUntil } from 'rxjs';
import { Equipment } from 'src/app/data';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
})
export class EquipmentListComponent extends VfmBaseComponent implements OnInit {
  @Output() equipmentChange: EventEmitter<Equipment> =
    new EventEmitter<Equipment>();
  @Input() equipments!: Equipment[] | null;

  selectedEquipment!: Equipment;
  equipmentId!: number;

  constructor(private equipmentService: EquipmentService) {
    super();
  }

  ngOnInit(): void {}

  selectEquipment(equipment: Equipment) {
    this.selectedEquipment = equipment;
    this.equipmentId = equipment.id!;
    this.equipmentChange.emit(equipment);
  }
}
