<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="['/settings']" [showChevron]=true>
</app-page-title>
<article>

    <div class="mt-24 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="space-y-8 divide-y">
            <form [formGroup]="profileForm">

                <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                    <div class="px-4 py-3 bg-yellow-default sm:px-6 text-black">
                        <h1 class="font-semibold">{{pageTitle}}</h1>
                    </div>

                    <div class="px-8 py-10 ">

                        <dl class="grid grid-cols-1 gap-x-24 gap-y-8 sm:grid-cols-2">

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.profile.LANGUAGE' | translate }}</mat-label>
                                    <mat-select [formControl]="languageCtrl" name="language">
                                        <mat-option *ngFor="let lang of languageList" [value]="lang.id">{{lang.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="languageCtrl.hasError('required')">
                                        {{ 'features.profile.LANGUAGE' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.profile.DEFAULT_WORKING_SITE' | translate }}</mat-label>
                                    <mat-select [formControl]="siteCtrl" name="siteId">
                                        <mat-option *ngFor="let site of theSites" [value]="site.id">{{site.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="siteCtrl.hasError('required')">
                                        {{ 'features.profile.DEFAULT_WORKING_SITE' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="sm:col-span-1">
                                <mat-form-field [style.width.%]="100">
                                    <mat-label>{{ 'features.profile.DEFAULT_EQUIPMENT_CATEGORY' | translate }}
                                    </mat-label>
                                    <mat-select [formControl]="categoryCtrl" name="categoryId">
                                        <mat-option *ngFor="let cat of categories" [value]="cat.id">{{cat.categoryName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="categoryCtrl.hasError('required')">
                                        {{ 'features.profile.DEFAULT_EQUIPMENT_CATEGORY' | translate}} {{ 'labels.IS' |
                                        translate}} <strong>{{ 'labels.REQUIRED' |
                                            translate}}</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </dl>

                    </div>

                    <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button (click)="cancel()"
                            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            {{ 'labels.CANCEL' | translate}}
                        </button>

                        <button (click)="save()"
                            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                            {{ 'labels.SAVE' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</article>