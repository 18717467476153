<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="['/admin']" [showChevron]=true>
</app-page-title>
<div class="loading-spinner" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <div class="space-y-8 divide-y divide-gray-200">
        <div class="relative w-[427px] sm:w-auto">
            
            <mat-button-toggle-group value="all">
                <mat-button-toggle (change)="getCrew('all')" value="all">{{ 'features.crew.ALL_CREW' | translate }}</mat-button-toggle>
                <mat-button-toggle (change)="getCrew('myCrew')" value="myCrew">{{ 'features.crew.MY_CREW' | translate }}</mat-button-toggle>
                <mat-button-toggle (change)="getCrew('crewManage' )" value="crewManage">{{ 'features.crew.CREW_I_MANAGE' | translate }}</mat-button-toggle>
            </mat-button-toggle-group>

            <button type="button" *ngIf="isAdmin"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 absolute top-0 right-0 mb-8"
                (click)="addCrew()">
                <i class="fa-solid fa-plus mr-2"></i>
                {{ 'features.crew.ADD_CREW_BUTTON' | translate }}
            </button>
        </div>

        <table mat-table [dataSource]="dataSource" class="cat-table">
            <!-- Position Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_NAME'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Members Column -->
            <ng-container matColumnDef="members">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_MEMBERS'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.members.length}}</span>
                </td>
            </ng-container>

            <!-- Membership Column -->
            <ng-container matColumnDef="membership">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_MEMBERSHIP'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm"
                        [ngClass]="{'not-member': getUsermembership(element) === 'Not a member', 'member': getUsermembership(element) === 'Member', 'pending': getUsermembership(element) === 'Pending' }">{{getUsermembership(element)}}</span>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description" class="hidden sm:block">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_DESC'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.description}}</span>
                </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_ACTION'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button" *ngIf="isAdmin"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 float-right my-3"
                        (click)="confirmRemoveCrew(element.id, $event)">
                        <i class="fa-solid fa-trash-can mr-2"></i>
                        {{ 'features.crew.DELETE_CREW_BUTTON' | translate }}
                    </button>
                    <button type="button" *ngIf="isNotMember(element)"
                        class="inline-flex items-center mr-3 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 float-right my-3"
                        (click)="confirmJoinCrew($event, element.id)">
                        <i class="fa-solid fa-user-plus mr-2"></i>
                        {{ 'features.crew.JOIN' | translate }}
                    </button>
                    <button type="button" *ngIf="isMember(element)"
                        class="inline-flex items-center mr-3 px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 float-right my-3"
                        (click)="confirmLeaveCrew($event, element.id)">
                        <i class="fa-solid fa-person-walking-arrow-right mr-2"></i>
                        {{ 'features.crew.LEAVE' | translate }}
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="showCrew(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="totalCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"
        aria-label="select page for categories"></mat-paginator>
</div>