import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-crew',
  templateUrl: './leave-crew.component.html',
  styleUrls: ['./leave-crew.component.scss'],
})
export class LeaveCrewComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<LeaveCrewComponent>) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close(true);
  }
}
