<div class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-circle-info mr-2"></i>
        {{ 'features.crew.CREW_DETAIL' | translate }}
    </h3>
    <button type="button" [routerLink]="[editCrewLink]" *ngIf="editable"
        class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-black-default hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
        <i class="fa-solid fa-pen"></i>
    </button>
</div>
<div class="border-t border-gray-200 px-4 py-4 sm:p-0" *ngIf="crew">
    <dl class="sm:divide-y sm:divide-gray-200">

        <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ">
            <dt class="text-sm font-semibold text-black">{{ 'features.crewProfile.NAME' | translate }}</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <!-- {{ this.crewForm.controls['name'].value }} -->
                {{ crew.name }}
            </dd>
        </div>
        <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 bg-gray-50">
            <dt class="text-sm font-semibold text-black">{{ 'features.crewProfile.CREW_MANAGERS' | translate }}</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ getCrewManagers(crew.managers) }}
            </dd>
        </div>
        <div class="py-4 sm:py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 ">
            <dt class="text-sm font-semibold text-black">{{ 'features.crewProfile.DESCRIPTION' | translate }}</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {{ crew.description }}
            </dd>
        </div>
    </dl>
</div>