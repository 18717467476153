<div class="user-autocomplete-component">
    <label class="crew-managers-title">{{ name }}</label> <sup *ngIf="required"> * </sup>
    <i class="fa-solid fa-circle-info relative ml-1" style="z-index: 10;" matTooltipPosition="right"
        matTooltip="{{ 'features.addCrew.TYPE_MIN_CHAR_USER_SEARCH' | translate }}"></i>
    <mat-form-field class="user-autocomplete pt-0" [formGroup]="form">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let user of users" [selectable]="selectable" [removable]="removable"
                (removed)="remove(user)">
                {{user.displayName}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input [disabled]="isInputDisabled" #userInput [formControl]="userCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" />
            <mat-error class="auto-complete-error" *ngIf="crewCtrl.hasError('required') && required">
                {{ name }} {{ 'labels.IS' |
                translate}} <strong>{{ 'labels.REQUIRED' |
                    translate}}</strong>
            </mat-error>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option disabled *ngIf="isLoading" class="is-loading">{{ 'features.userAutoComplete.LOADING' | translate
                }}</mat-option>
            <mat-option disabled *ngIf="errorMsg" class="is-loading">{{ errorMsg }}</mat-option>
            <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let user of filteredUsers" [value]="user" class="user-autocomplete-select">
                    <div>
                        <i class="fa-solid fa-user user-autocomplete-avatar"></i>
                        {{user.displayName}}
                        <span class="user-autocomplete-id">({{user.email}})</span>
                    </div>
                    <div>
                        <span class="user-autocomplete-email">{{user.jobTitle}}</span>
                    </div>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</div>