<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="['/admin']" [showChevron]=true>
</app-page-title>
<div class="loading-spinner" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8" *ngIf="timezones">
    <div class="space-y-8 divide-y divide-gray-200">
        <div>
            <button type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 float-right mb-8"
                (click)="addSite()">
                <i class="fa-solid fa-plus mr-2"></i>
                {{ 'features.site.ADD_SITE_BUTTON' | translate }}
            </button>
        </div>

        <table mat-table [dataSource]="dataSource" class="cat-table">
            <!-- Name Column -->
            <ng-container matColumnDef="siteName">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.site.COLUMN_SITE_NAME'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.name}}</span>
                </td>
            </ng-container>

            <!-- Code Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.site.COLUMN_SITE_CODE'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.code}}</span>
                </td>
            </ng-container>

            <!-- Time Zone Column -->
            <ng-container matColumnDef="timeZone">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">Time zone</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{ getTimezone(element.timeOffset) || 'N/A'}}</span>
                    <i class="icon-edit fa-solid fa-pen float-right invisible"></i>
                </td>
            </ng-container>

            <!-- Description Column -->
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.site.COLUMN_SITE_DESC'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.description}}</span>
                    <i class="icon-edit fa-solid fa-pen float-right invisible"></i>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="showSite(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="totalCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"
        aria-label="select page for sites"></mat-paginator>
</div>