

<div *ngIf="data.text">
    <div class="block  m-5 p-6">
        <p> <strong >{{ 'features.faq.UPDATE.NEXT_UPDATE'| translate }}</strong > {{data.text.nextUpdate}}</p>
        <p> <strong >{{ 'features.faq.UPDATE.NEW'| translate }}</strong > <br>{{data.text.descriptionNextUpdate}} </p>
    </div>
</div>    
    
    <div *ngIf="data.video">
        <video  controls>
            <source src="{{data.video.url}}" type="video/mp4">
            Your browser does not support the video tag.
        </video>
    </div>

