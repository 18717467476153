export const environment = {
  environment: 'QA',
  production: true,
  loginRedirectUrl: 'https://aemvfmqa.agnicoeagle.com/',
  logoutRedirectUrl: 'https://aemvfmqa.agnicoeagle.com/auth',
  backendBaseUrl: 'https://aemvfmapiqa.azurewebsites.net/api',
  clientId: 'e8d3c7ca-1738-4502-abc9-20e856e90e67',
  authority:
    'https://login.microsoftonline.com/5f4e175c-44de-4676-ae70-23ec941f455d',
  AccessScope: 'api://3733e864-7e0f-4f7f-859c-f34c48f3c87c/access_as_user',
  arcgis: {
    accessToken:
      'AAPK3d037583d5b54e8c811d76bc586b6f48z_MJZ4RJ2HVwt6CcpqBAhIUn8dTvJS4jMDWbMIeblqZh6MIzs5Iij-K_IBnDn-Dd',
  },
};
