import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs-compat/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  public profile: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public token: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {}

  updateProfile(param: boolean): void {
    this.profile.next(param);
  }

  getModifyProfile(): Observable<boolean> {
    return this.profile.asObservable();
  }

  updateToken(param: boolean): void {
    this.token.next(param);
  }

  getModifyToken(): Observable<boolean> {
    return this.token.asObservable();
  }
}
