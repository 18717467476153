import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {
  map,
  startWith,
  debounceTime,
  tap,
  switchMap,
  finalize,
} from 'rxjs/operators';
import { UserProfileService } from '../../../core/services/data/user-profile.service';
import { UserProfile } from 'src/app/data/UserProfile.model';
import { Observable, empty } from 'rxjs';

@Component({
  selector: 'app-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss'],
})
export class UserAutocompleteComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() ctrlName: string = 'control';
  @Input() name!: string;
  @Input() multiple: boolean = true;
  @Input() required: boolean = true;

  isLoading = false;
  isInputDisabled = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userCtrl = new FormControl();
  filteredUsers: any;
  errorMsg: string = '';

  users: any[] = [];
  allUsers: any[] = [];

  @ViewChild('userInput') userInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  constructor(
    private userService: UserProfileService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.form.get(this.ctrlName)?.valueChanges.subscribe((val) => {
      if (val.length === 0) {
        this.users = [];
        this.isInputDisabled = false;
      } else {
        this.users = val.map((a: any) => {
          return { id: a.id, displayName: a.displayName };
        });

        if (!this.multiple) {
          this.isInputDisabled = true;
        }
      }
    });

    this.userCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.errorMsg = '';
          this.filteredUsers = [];
          this.isLoading = true;
        }),
        switchMap(() => {
          const searchText = this.userInput.nativeElement.value;

          if (searchText.length >= 3) {
            return this.userService
              .getUserProfileSearch(searchText)
              .pipe(finalize(() => {}));
          } else {
            return empty().pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          }
        })
      )
      .subscribe((data: any) => {
        if (data && data.length > 0) {
          this.isLoading = false;
          this.errorMsg = '';
          this.filteredUsers = data;
        } else {
          this.isLoading = false;
          this.errorMsg = this.translate.instant(
            'features.userAutoComplete.NO_USER_FOUND'
          );
          this.filteredUsers = [];
        }
      });
  }

  remove(user: UserProfile): void {
    const index = this.users.indexOf(user);
    this.isInputDisabled = false;

    if (index >= 0) {
      this.users.splice(index, 1);
      this.form.get(this.ctrlName)!.setValue(this.users);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.multiple) {
      this.isInputDisabled = true;
    }

    this.users.push(event.option.value);
    this.userInput.nativeElement.value = '';
    this.form.get(this.ctrlName)!.setValue(this.users);
    this.userCtrl.setValue(null);
    this.filteredUsers = [];
  }

  get crewCtrl() {
    return this.form.get(this.ctrlName) as FormControl;
  }
}
