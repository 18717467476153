import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { RoleService } from '@app/services/data/role.service';
import { Observable, takeUntil } from 'rxjs';
import { Member, PaginatedItems } from 'src/app/data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-edit-member-role',
  templateUrl: './edit-member-role.component.html',
  styleUrls: ['./edit-member-role.component.scss'],
})
export class EditMemberRoleComponent
  extends VfmBaseComponent
  implements OnInit
{
  pageTitle = this.translate.instant('features.role.ROLE');
  user = this.translate.instant('labels.USER');
  prevLink = '';
  pageIconClass = 'fa-solid fa-users';
  form!: FormGroup;
  roleId!: number;
  crewMembers!: Member[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private roleService: RoleService,
    private toastrService: ToastrService
  ) {
    super();
    this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
  }

  ngOnInit(): void {
    this.roleId = +this.route.snapshot.paramMap.get('id')!;
    this.buildForm();
    this.loadCrewMembers();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      objectIds: ['', Validators.required],
    });
  }

  loadCrewMembers() {
    this.roleService
      .getRoleMembers(this.roleId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((members: any) => {
        this.crewMembers = members.data;
      });
  }

  addCrewMember() {
    this.form.markAllAsTouched();

    const objectIds = this.form.controls['objectIds'].value.map(
      (a: any) => a.id
    );

    if (objectIds && objectIds.length > 0) {
      this.roleService
        .addRoleMember(this.roleId, this.site.code, objectIds)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.form.controls['objectIds'].setValue([]);
          this.form.markAsUntouched();
          this.loadCrewMembers();
          this.toastrService.success('The member was successfully added!');
        });
    }
  }

  removeCrewMember(memberId: number) {
    this.roleService
      .deleteRoleMember(this.roleId, this.site.code, memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadCrewMembers();
        this.toastrService.success('The member was successfully removed!');
      });
  }
}
