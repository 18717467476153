import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { UserRoleService } from '@app/services/common/user-role.service';
import { Router } from '@angular/router';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard extends VfmBaseComponent implements CanActivate {
  msalScopes = [];
  hasRight = true;
  that = this;

  constructor(
    private roleUserService: UserRoleService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const patternCodeSiteId = '{siteId}';

    if (route.data) {
      const requiredRoles: [] = route.data.requiredRoles || [];
      const requiredPatternRoles: any = route.data.requiredPatternRoles || [];
      const patternCode: any = route.data.patternCode || [];

      let requiredPatternRolesIndex = 0;

      if (patternCode && patternCode.length > 0) {
        for (const [key, value] of Object.entries(patternCode[0])) {
          if (/^@site.id/.test(<string>value)) {
            const site: any = localStorage.getItem('VFM_SITE');
            patternCode[0][key] = JSON.parse(site).id;
          }

          if (/^:/.test(<string>value)) {
            patternCode[0][key] = this.route.snapshot.paramMap.get(key);
          }
        }
      }

      if (requiredPatternRoles && requiredPatternRoles.length > 0) {
        for (let value of requiredPatternRoles) {
          if (value.includes(patternCodeSiteId)) {
            const patternCodeProp = patternCodeSiteId
              .replace('{', '')
              .replace('}', '');

            value = value.replace(
              patternCodeSiteId,
              patternCode[0][patternCodeProp]
            );

            requiredPatternRoles[requiredPatternRolesIndex] = value;
          }

          requiredPatternRolesIndex++;
        }
      }

      this.hasRight = this.roleUserService.isUserInRole([
        ...requiredPatternRoles,
        ...requiredRoles,
      ]);
    }

    if (!this.hasRight) {
      this.router.navigate(['/unauthorize']);
      return false;
    } else {
      return this.hasRight;
    }
  }
}
