import { Component, OnInit, Output, EventEmitter } from '@angular/core';

export interface ImagePickerData {
  fileName: string;
  imgBase64Path: string;
}

@Component({
  selector: 'app-image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
})
export class ImagePickerComponent implements OnInit {
  fileName: string | undefined;
  @Output() fileChanged: EventEmitter<ImagePickerData> =
    new EventEmitter<ImagePickerData>();
  constructor() {}

  ngOnInit(): void {}

  /**
   * Load the file in a ImageData and pass it to the parent component for further processing
   */
  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file && this.validateFileType(file)) {
      this.fileName = file.name;
      let imgBase64Path = '';

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          // Return Base64 Data URL
          imgBase64Path = e.target.result;
          const imageData: ImagePickerData = {
            fileName: file.name,
            imgBase64Path,
          };
          this.fileChanged.emit(imageData);
        };
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  validateFileType(file: any): boolean {
    const fileName = file.name;
    const idxDot = fileName.lastIndexOf('.') + 1;
    const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

    if (extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png') {
      return true;
    } else {
      alert('Only jpg/jpeg and png files are allowed!');
      return false;
    }
  }
}
