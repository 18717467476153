<main class="flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last"
    style="background-color: #f8fafc; height: calc(100vh - 66px);">

    <article>
        <!-- Profile header -->
        <div class="bg-white shadow">
            <div class="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div class="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                    <div class="flex-1 min-w-0">
                        <!-- Profile -->
                        <div class="flex items-center">
                            <div class="overflow-hidden relative w-16 h-16 bg-gray-100 rounded-full dark:bg-gray-600">
                                <svg class="absolute  w-16 h-16 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                                        clip-rule="evenodd"></path>
                                </svg>
                            </div>
                            <div *ngIf="userProfile">
                                <div class="flex items-center">

                                    <h1
                                        class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                        {{ userProfile.name }}</h1>
                                </div>
                                <dl class="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                    <dd class="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                            aria-hidden="true">
                                            <path fill-rule="evenodd"
                                                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                                                clip-rule="evenodd" />
                                        </svg>
                                        {{ userProfile.jobTitle || 'N/A' }}
                                    </dd>
                                    <dd
                                        class="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">

                    </div>
                </div>
            </div>
        </div>

        <!-- Tabs -->
        <div class="mt-6 sm:mt-2 2xl:mt-5">
            <div class="border-b border-gray-200">
                <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                        <a (click)="selectTab('profile')"
                            [ngClass]="{'border-yellow-default text-gray-900': tab === 'profile'}"
                            class="cursor-pointer text-gray-500 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                            aria-current="page"> {{ 'features.profile.PROFILE' | translate}} </a>

                        <a (click)="selectTab('reservation')"
                            [ngClass]="{'border-yellow-default text-gray-900': tab === 'reservation'}"
                            class="cursor-pointer text-gray-500  whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm">
                            {{ 'features.profile.RESERVATIONS' | translate}} </a>
                    </nav>
                </div>
            </div>
        </div>

        <app-profile-detail-info *ngIf="userProfile && tab === 'profile'" [member]="userProfile">
        </app-profile-detail-info>

        <app-reservation-detail *ngIf="tab === 'reservation'" [isFromMemberList]="true" [reservations]="reservations">
        </app-reservation-detail>
    </article>
</main>