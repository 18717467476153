import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminComponent } from './admin/admin.component';
import { SharedModule } from '@shared/shared.module';
import { CategoryComponent } from './category/category.component';
import { AddCategoryComponent } from './category/components/add-category/add-category.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CrewComponent } from './crew/crew.component';
import { AddCrewComponent } from './crew/components/add-crew/add-crew.component';
import { CrewProfileComponent } from './crew/components/crew-profile/crew-profile.component';
import { CrewDetailComponent } from './crew/components/crew-detail/crew-detail.component';
import { MembersCarouselComponent } from './crew/components/members-carousel/members-carousel.component';
import { AddMemberComponent } from './crew/components/add-member/add-member.component';
import { MembersDetailComponent } from './crew/components/members-detail/members-detail.component';
import { MembersListComponent } from './crew/components/members-list/members-list.component';
import { SiteComponent } from './site/site.component';
import { AddSiteComponent } from './site/components/add-site/add-site.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { AddEquipmentComponent } from './equipment/components/add-equipment/add-equipment.component';
import { EquipmentCarouselComponent } from './crew/components/equipment-carousel/equipment-carousel.component';
import { PendingReservationRequestComponent } from './crew/components/pending-reservation-request/pending-reservation-request.component';
import { PendingMembershipRequestComponent } from './crew/components/pending-membership-request/pending-membership-request.component';
import { RoleComponent } from './role/role.component';
import { EditMemberRoleComponent } from './role/components/edit-member-role/edit-member-role.component';
import { RestrictionComponent } from './equipment/components/restriction/restriction.component';
import { AddRestrictionComponent } from './equipment/components/restriction/components/add-restriction/add-restriction.component';
import { AddRestrictionCalendarComponent } from './equipment/components/restriction/components/add-restriction-calendar/add-restriction-calendar.component';
import { JoinCrewComponent } from './crew/components/join-crew/join-crew.component';
import { RejectCrewMemberRequestComponent } from './crew/components/reject-crew-member-request/reject-crew-member-request.component';
import { LeaveCrewComponent } from './crew/components/leave-crew/leave-crew.component';
import { RejectReservationRequestComponent } from './crew/components/reject-reservation-request/reject-reservation-request.component';

@NgModule({
  declarations: [
    AdminComponent,
    CategoryComponent,
    AddCategoryComponent,
    CrewComponent,
    AddCrewComponent,
    CrewProfileComponent,
    CrewDetailComponent,
    MembersCarouselComponent,
    AddMemberComponent,
    MembersDetailComponent,
    MembersListComponent,
    EquipmentComponent,
    AddEquipmentComponent,
    SiteComponent,
    AddSiteComponent,
    EquipmentCarouselComponent,
    PendingReservationRequestComponent,
    PendingMembershipRequestComponent,
    RoleComponent,
    EditMemberRoleComponent,
    RestrictionComponent,
    AddRestrictionComponent,
    AddRestrictionCalendarComponent,
    JoinCrewComponent,
    RejectCrewMemberRequestComponent,
    LeaveCrewComponent,
    RejectReservationRequestComponent,
  ],
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule],
})
export class AdminModule {}
