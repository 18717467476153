import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent implements OnInit {
  @Input() title: string = '';
  @Input() iconClass: string = '';
  @Input() showChevron: boolean = true;

  @Input() route: any[] = [];
  @Input() queryParams: { [k: string]: any } = {};

  @Input() actionFn: Function | undefined;
  @Output() clickEvent: EventEmitter<any> = new EventEmitter();

  @Input() actionTemplate: TemplateRef<any> | undefined;

  constructor() {}

  ngOnInit(): void {}

  onClick(): void {
    if (this.actionFn) {
      this.actionFn();
    }
  }
}
