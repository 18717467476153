import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { UserProfile, PaginatedItems, Reservation } from 'src/app/data';
import { handleError } from './handleError';

export type UserProfileCreateRequest = Omit<UserProfile, 'id' | 'sitecode'>;
export type UserProfileUpdateRequest = Omit<UserProfile, 'sitecode'>;

const CAT_API_ENDPOINT = 'userprofile';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  constructor(private http: HttpClient) {}

  /**
   * Get UserProfile Search Result
   * @returns an observable of UserProfile
   */
  getUserProfileSearch(displayName: string) {
    return this.http
      .get<UserProfile>(`${CAT_API_ENDPOINT}/searchuser/${displayName}`)
      .pipe(catchError(handleError));
  }

  /**
   * Get UserProfile Me
   * @returns an observable of My UserProfile
   */
  getMyUserProfile() {
    return this.http
      .get<UserProfile>(`${CAT_API_ENDPOINT}/me`)
      .pipe(catchError(handleError));
  }

  /**
   * Get UserProfile
   * @returns an observable of UserProfile
   */
  getUserProfile(id: number) {
    return this.http
      .get<UserProfile>(`${CAT_API_ENDPOINT}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   * Get UserProfile
   * @returns an observable of UserProfile
   */
  getUserProfileByObjectId(objectId: string) {
    return this.http
      .get<UserProfile>(`${CAT_API_ENDPOINT}/obyobjectid/${objectId}`)
      .pipe(catchError(handleError));
  }

  /**
   * Get my Reservations
   * @returns an observable of Reservations
   */
  getReservations(id: number, sitecode: string) {
    return this.http
      .get<PaginatedItems<Reservation>>(
        `${CAT_API_ENDPOINT}/${id}/site/${sitecode}/reservation?pageSize=0&pageIndex=0`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Put UserProfile
   */
  updateUserProfile(userProfile: UserProfile) {
    return this.http
      .put<UserProfile>(`${CAT_API_ENDPOINT}/${userProfile.id}`, userProfile)
      .pipe(catchError(handleError));
  }

  /**
   * Add Favorite Equipment
   */
  addFavoriteEquipment(equipmentId: number) {
    return this.http
      .post<UserProfile>(`${CAT_API_ENDPOINT}/favoriteequipment`, {
        equipmentId,
      })
      .pipe(catchError(handleError));
  }

  /**
   * Remove Favorite Equipment
   */
  deleteFavoriteEquipment(equipmentId: number) {
    return this.http
      .delete<UserProfile>(
        `${CAT_API_ENDPOINT}/favoriteequipment/${equipmentId}`
      )
      .pipe(catchError(handleError));
  }
}
