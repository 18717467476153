import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import * as faq_question_fr from 'src/app/json/faq/faq_question_fr.json'
import * as faq_question_en from 'src/app/json/faq/faq_question_en.json'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq-questions',
  templateUrl: './faq-questions.component.html',
  styleUrls: ['./faq-questions.component.scss']
})
export class FaqQuestionsComponent implements OnInit {

  constructor(public dialog: MatDialog, private translate: TranslateService) { }

  faqQuestion = Object.assign({},this.translate.currentLang === 'en' ? faq_question_en : faq_question_fr)

  ngOnInit(): void {
  }
  openDialog(url:string) {
    const dialogRef = this.dialog.open(FaqDialogComponent , {
      width: '1000px',
      data: 
      {
        video:{
          url: url
        }
      }
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
