export const SHIFTS = [
  {
    id: 1,
    title: '14/14 (day)',
    tooltip: 'work 14 days / off 14 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 2,
    title: '7/7 (day)',
    tooltip: 'work 7 days / off 7 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 3,
    title: '5-4/4-5/5-5 (d)',
    tooltip: 'work 5 days / off 4 days / work 4 days / off 5 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 4,
    title: '5-2/4-5 (day)',
    tooltip: 'work 5 days  off 2 days / work 4 days / off 5 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 5,
    title: '5-2 (day)',
    tooltip: 'work 5 days  off 2 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 6,
    title: '10/10 (day)',
    tooltip: 'work 10 days  off 10 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 7,
    title: '4/3 (day)',
    tooltip: 'work 4 days  off 3 days',
    icon: 'fa-calendar-day',
  },
  {
    id: 8,
    title: '3/2 (week)',
    tooltip: 'work 3 weeks  off 2 weeks (Contractors only)',
    icon: 'fa-calendar-week',
  },
  {
    id: 9,
    title: '3/3 (week)',
    tooltip: 'work 3 weeks  off 3 weeks (Contractors only)',
    icon: 'fa-calendar-week',
  },
  {
    id: 10,
    title: '4/2 (week)',
    tooltip: 'work 4 weeks  off 2 weeks (Contractors only)',
    icon: 'fa-calendar-week',
  },
];
