import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { EquipmentService } from '@app/services/data/equipment.service';
import { Observable, takeUntil } from 'rxjs';
import { Equipment, PaginatedItems } from 'src/app/data';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-equipment-carousel',
  templateUrl: './equipment-carousel.component.html',
  styleUrls: ['./equipment-carousel.component.scss'],
})
export class EquipmentCarouselComponent
  extends VfmBaseComponent
  implements OnInit
{
  @Input() editable: boolean = true;
  @Output() selectedEquipment: EventEmitter<Equipment> =
    new EventEmitter<Equipment>();

  crewId!: number;
  equipments!: Equipment[];
  isAdminMenu:boolean = false;


  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 4,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor(
    private equipmentService: EquipmentService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.router.url.includes('/admin')){
      this.isAdminMenu = true;
    }
    this.crewId = +this.route.snapshot.paramMap.get('id')!;
    this.loadEquipments();
  }

  loadEquipments() {
    this.equipmentService
      .getEquipmentsByCrewId(this.site.code, this.crewId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((equipments: any) => {
        this.equipments = equipments.data;
      });
  }

  addEquipment() {
    if(this.isAdminMenu===true){
      this.router.navigate([`/admin/crew/profile/${this.crewId}/equipment/add`]);
    } 
    else{
      this.router.navigate([`/crew/profile/${this.crewId}/equipment/add`]);
    }
  }

  selectEquipment(equipment: Equipment) {
    this.selectedEquipment.emit(equipment);
  }
}
