<mat-sidenav-container class="crew-profile-container" autosize>
    <mat-sidenav [fixedInViewport]="'fixed'" [fixedTopGap]="63" #drawer class="w-screen md:w-[750px]" mode="over"
        position="end">

        <button (click)="closeDrawer()" type="button"
            class="items-end md:hidden mx-4 mt-2 bg-gray-200 rounded-md p-2 inline-flex justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>

        <app-equipment-detail *ngIf="!isMemberSelected" [equipment]="selectedEquipment"
            class="shadow bg-white flex-1 relative z-0 overflow-y-auto focus:outline-none md:order-last">
        </app-equipment-detail>

        <app-user-profile-detail *ngIf="isMemberSelected" [member]="selectedMember"></app-user-profile-detail>
    </mat-sidenav>

    <app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevLink]" [showChevron]=true>
        <div content class="inline-flex">

            <button type="button" *ngIf="isNotMember(crew)" [ngClass]="{'sidenavExpanded': !isSidenavCollapse}"
                class="inline-flex absolute right-[30px] sm:right-[70px] lg:right-[73px] top-[12px] sm:top-[9px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker"
                (click)="confirmJoinCrew($event, crew.id)">
                <i class="fa-solid fa-user-plus mr-2"></i>
                {{ 'features.crew.JOIN' | translate }}
            </button>

            <button type="button" *ngIf="isMember(crew)" [ngClass]="{'sidenavExpanded': !isSidenavCollapse}"
                class="inline-flex absolute right-[30px] sm:right-[70px] lg:right-[73px] top-[12px] sm:top-[9px] items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker"
                (click)="confirmLeaveCrew($event, crew.id)">
                <i class="fa-solid fa-person-walking-arrow-right mr-2"></i>
                {{ 'features.crew.LEAVE' | translate }}
            </button>
        </div>
    </app-page-title>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-24">
        <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                <div class="relative">
                    <div class="bg-white shadow overflow-hidden sm:rounded-lg h-full">
                        <app-crew-detail [editable]="isAdmin"></app-crew-detail>
                    </div>
                </div>

                <div class="relative">
                    <div class="bg-gray-50 shadow overflow-hidden h-full sm:rounded-lg" *ngIf="isAdmin">
                        <app-pending-reservation-request></app-pending-reservation-request>
                    </div>

                    <div class="bg-gray-50 shadow overflow-hidden sm:rounded-lg h-full" *ngIf="!isAdmin">
                        <app-members-carousel (selectedMember)="selectMember($event)" [editable]="isAdmin">
                        </app-members-carousel>
                    </div>

                </div>
            </dl>
        </div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div class="mt-10">
            <div class="relative">
                <div class="bg-white shadow overflow-hidden sm:rounded-lg">
                    <app-equipment-carousel [editable]="isAdmin" (selectedEquipment)="selectEquipment($event)">
                    </app-equipment-carousel>
                </div>
            </div>
        </div>
    </div>

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-[60px]" *ngIf="isAdmin">
        <div>
            <dl class="space-y-10 md:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
                <div class="relative">
                    <div class="bg-gray-50 shadow overflow-hidden sm:rounded-lg h-full">
                        <app-members-carousel (selectedMember)="selectMember($event)" [udpateCrew]="updateCrew">
                        </app-members-carousel>
                    </div>
                </div>
                <div class="relative">
                    <div class="bg-gray-50 shadow overflow-hidden sm:rounded-lg h-full">
                        <app-pending-membership-request *ngIf="crew" (updateCrew)="loadCrew()"
                            [memberApprovals]="crew.memberApprovals!">
                        </app-pending-membership-request>
                    </div>
                </div>
            </dl>
        </div>
    </div>
</mat-sidenav-container>