<div class="md:mx-20 ">
    
    <div class="grid grid-cols-6 gap-4">
        <div  class="  col-span-6 block  m-5 ">
            <h3 class="text-3xl font-bold tracking-tight text-gray-900">FAQ</h3>
        </div>

        <div class=" col-span-6 lg:col-span-3 block  mx-5 ">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngFor="let item of faqQuestion.FAQ1">
                    <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>{{item.QUESTION}}</strong>
                    </mat-panel-title>
                    
                    </mat-expansion-panel-header>
                    <div class="my-5">
                        <p *ngFor="let answer of item.ANSWER" class="mb-5"><span [innerHTML]="answer.TEXT"></span><br><button (click)="openDialog(answer.URL)" class="AEMhover" *ngIf="answer.URL">{{ 'features.faq.FAQ_QUESTION.WATCH_VIDEO'| translate }}</button></p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        <div class=" col-span-6 lg:col-span-3 block  mx-5 ">
            <mat-accordion class="example-headers-align" multi>
                <mat-expansion-panel *ngFor="let item of faqQuestion.FAQ2">
                    <mat-expansion-panel-header>
                    <mat-panel-title>
                        <strong>{{item.QUESTION}}</strong>
                    </mat-panel-title>
                    
                    </mat-expansion-panel-header>
                    <div class="my-5">
                        <p *ngFor="let answer of item.ANSWER" class="mb-5"><span [innerHTML]="answer.TEXT"></span><br><button (click)="openDialog(answer.URL)" class="AEMhover" *ngIf="answer.URL">{{ 'features.faq.FAQ_QUESTION.WATCH_VIDEO'| translate }}</button></p>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>

        
        

    </div>

    
</div>
