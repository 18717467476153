<div class="relative px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-circle-info mr-2"></i>
        {{ 'features.addReservation.RESERVATION_DETAIL' | translate}}
    </h3>
</div>

<form *ngIf="reservationform" [formGroup]="reservationform" class="reservation-form">
    <div class="px-6">


        <div>
            <app-user-autocomplete [ngClass]="{'pointer-events-none': !isAdmin}" [name]="user" [multiple]="false"
                [ctrlName]="'user'" [form]="reservationform">
            </app-user-autocomplete>
        
        </div>
  
        <div class="flex">
            <mat-form-field *ngIf="isEdit && hasRequestor" [style.width.%]="100" class="-mt-8 pr-2 pointer-events-none" disabled>
                <mat-label>{{ 'features.addReservation.RESERVEDBY' | translate}}</mat-label>
                <input variant="outlined" [formControl]="requestorCtrl" 
                matInput>
              </mat-form-field>

            <mat-form-field [style.width.%]="100" class="-mt-8 pr-2 pointer-events-none">
                <mat-label>{{ 'features.addReservation.EQUIPMENT' | translate}}</mat-label>
                <input variant="outlined" [formControl]="equipmentNameCtrl" matInput
                    value="{{selectedEquipment && selectedEquipment.name}}"
                    placeholder="{{ 'features.addcategory.SELECT_CATEGORY' | translate}}">
                <mat-error *ngIf="equipmentNameCtrl.hasError('required')">
                    {{ 'features.addEquipment.EQUIPMENT_NAME_LABEL' | translate}} {{ 'labels.IS' |
                    translate}} <strong>{{ 'labels.REQUIRED' |
                        translate}}</strong>
                </mat-error>
            </mat-form-field>

            <button type="button" [disabled]="isEdit"
                [ngClass]="{'bg-blue-darker hover:bg-blue-darker': !isEdit,'bg-gray-300 hover:bg-gray-300':isEdit}"
                class="inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-darker float-right mb-8 -mt-[10px]"
                (click)="selectEquipment()" >
                {{ 'features.addReservation.SELECT' | translate }}
            </button>

        </div>

        <div class="flex mt-0 mb-6">
            <div class="w-1/2 pr-3">
                <mat-form-field [style.width.%]="100" class="-mt-3">
                    <mat-label><i class="fa-solid fa-calendar-days mr-1"></i> {{
                        'features.addReservation.SELECT_START_DATE' | translate}}</mat-label>
                    <input mbsc-datepicker [mbscOptions]="datepickerOptionsStartDate" variant="outlined"
                        [formControl]="startDateCtrl" matInput>
                    <mat-error *ngIf="startDateCtrl.hasError('required')">
                        {{ 'features.addReservation.START_DATE' | translate}} {{ 'labels.IS' |
                        translate}} <strong>{{ 'labels.REQUIRED' |
                            translate}}</strong>
                    </mat-error>
                    <mat-error *ngIf="startDateCtrl.hasError('startdate_lesser_than_enddate')">
                        {{ 'features.addReservation.START_DATE_LESSER_THAN_EDN_DATE' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="w-1/2 pl-3">
                <mat-form-field [style.width.%]="100" class="-mt-3">
                    <mat-label><i class="fa-solid fa-calendar-days mr-1"></i> {{
                        'features.addReservation.SELECT_END_DATE' | translate}}</mat-label>
                    <input mbsc-datepicker [mbscOptions]="datepickerOptionsEndDate" variant="outlined"
                        [formControl]="endDateCtrl" matInput>
                    <mat-error *ngIf="endDateCtrl.hasError('required')">
                        {{ 'features.addReservation.END_DATE' | translate}} {{ 'labels.IS' |
                        translate}} <strong>{{ 'labels.REQUIRED' |
                            translate}}</strong>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex">
            <mbsc-switch themeVariant="light" class="mb-10" color="primary"
                [label]="'features.addReservation.SHOW_MY_SHIFT' | translate" *ngIf="this.shifts"
                [description]="userShiftName" [style.width.%]="100" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="isApplyShift" (ngModelChange)="onShiftChanged($event)">
            </mbsc-switch>

            <!-- <button type="button"
                class="absolute inline-flex items-center px-2 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-darker left-[140px] ml-1 mb-8 mt-[0px] h-[38px]"
                style="z-index: 10;" (click)="selectWorkingShift()">
                {{ 'features.addReservation.CHANGE' | translate }}
            </button> -->
        </div>

        <mbsc-switch themeVariant="light" color="primary" [label]="'features.reservation.RECURRING' | translate"
            description="" [ngModelOptions]="{standalone: true}" [(ngModel)]="isRecurring"
            (ngModelChange)="onRecurringChange($event)">
        </mbsc-switch>

        <app-recurring-date *ngIf="isRecurring" (recurringRule)="onRecurringRuleChange($event)" #recurringDateComponent
            [form]="reservationform"></app-recurring-date>

        <mat-form-field [style.width.%]="100" class="mt-3">
            <mat-label>{{ 'features.addReservation.COMMENT' | translate}}</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>

    </div>

    <div class="sm:shadow-none w-full left-0 fixed bottom-0  sm:relative px-4 py-3 bg-gray-50 text-right sm:px-6"
        style="z-index: 100; box-shadow: 0px -4px 10px -6px rgb(0 0 0 / 75%);">
        <button (click)="cancel()"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            {{ 'labels.CANCEL' | translate}}
        </button>

        <button (click)="save()"
            [ngClass]="{'bg-gray-400 dark:bg-gray-500 cursor-not-allowed pointer-events-none': isReservationOverlap, 'bg-green-700 hover:bg-green-800': !isReservationOverlap}"
            class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
            {{'labels.SAVE' | translate}}
        </button>

    </div>

</form>