import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@app/guards/unsaved-changes.guard';
import { DialogService } from '@app/services/common/dialog.service';
import { CategoryService } from '@app/services/data/category.service';
import { Observable, takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { Category } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { ImagePickerData } from '@shared/components/image-picker/image-picker.component';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss'],
})
export class AddCategoryComponent
  extends VfmBaseComponent
  implements OnInit, CanComponentDeactivate
{
  categoryId!: number;
  isFormSaved: boolean = false;
  pageTitle = this.translate.instant('features.addcategory.TITLE_HOME');
  pageIconClass = 'fa-solid fa-circle-plus';
  catForm!: FormGroup;
  imageUrl = '';

  constructor(
    private catService: CategoryService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {
    super();
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.catForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant('features.addcategory.CATEGORY_UNSAVED_CHANGES'),
        this.translate.instant('features.addcategory.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  ngOnInit(): void {
    this.buildCatForm();

    const catId = this.route.snapshot.paramMap.get('id');
    if (catId) {
      this.categoryId = +catId;
      this.pageIconClass = 'fa-solid fa-pen';
      this.pageTitle = this.translate.instant(
        'features.addcategory.TITLE_MODIFY'
      );
      this.loadCategory();
    }
  }

  /**
   * define the form for category entry
   */
  private buildCatForm() {
    this.catForm = this.formBuilder.group({
      id: [''],
      categoryName: ['', Validators.required],
      description: [''],
      pictureUri: [''],
      pictureName: [''],
      sitecode: [this.site.code],
    });
  }

  /**
   *  Create or update a  category
   */
  add(event: any = null) {
    event && event.preventDefault();

    this.catForm.markAllAsTouched();

    if (this.catForm.invalid) {
    } else {
      const category: Category = this.catForm.getRawValue() as Category;
      this.catService
        .saveCategory(category)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            this.isFormSaved = true;
            this.goToCategoryList();
          },
          error: (error: HttpErrorResponse) => {
            console.log('Error message', error.message);
          },
        });
    }
  }

  /**
   *
   */
  cancel() {
    this.goToCategoryList();
  }

  /**
   * Get the form control for the category Name
   */
  get catNameCtrl() {
    return this.catForm.get('categoryName') as FormControl;
  }

  get catImageUrlCtrl() {
    return this.catForm.get('pictureUri') as FormControl;
  }
  /**
   * Load and set the category data to the form
   */
  loadCategory() {
    if (this.categoryId) {
      this.catService
        .getCategoryById(this.categoryId, this.site.code)
        .pipe(takeUntil(this.destroy$))
        .subscribe((cat) => {
          this.catForm.patchValue(cat);
        });
    }
  }

  /**
   * Return to category List
   */
  private goToCategoryList() {
    this.router.navigate(['/admin/category']);
  }

  /**
   * Show preview of the load image
   * @param data
   */
  fileChanged(imData: ImagePickerData) {
    if (imData.fileName) {
      this.catForm.patchValue({
        pictureUri: imData.imgBase64Path,
        pictureName: imData.fileName,
      });
      this.catForm.markAsDirty();
    }
  }

  /**
   * Delete a category
   */
  deleteCategory() {
    this.catService
      .deleteCategory(this.site.code, this.categoryId)
      .subscribe(() => {
        this.goToCategoryList();

        this.toastrService.success(
          this.translate.instant(
            'features.category.CATEGORY_SUCCESSFULLY_DELETED'
          )
        );
      });
  }

  confirmRemoveCategory() {
    this.dialogService
      .confirm(
        this.translate.instant('features.category.CONFIRM_REMOVE_DIALOG.TITLE'),
        this.translate.instant(
          'features.category.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteCategory();
        }
      });
  }
}
