<div class="flex-1 flex">
  <div class="w-full flex md:ml-0" action="#" method="GET">
    <label for="search-field" class="sr-only">Search</label>
    <div class="relative w-full text-blue-200 focus-within:text-slate-50">
      <div
        class="absolute inset-y-0 left-0 flex items-center pointer-events-none"
      >
        <svg
          class="h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <input
        id="search-field"
        class="bg-blue-default block w-full h-full pl-8 pr-3 py-2 border-transparent text-slate-50 placeholder-white focus:outline-none focus:placeholder-text-white focus:ring-0 focus:border-transparent sm:text-base"
        placeholder="Search"
        type="search"
        name="search"
      />
    </div>
  </div>
</div>
