<div class="mx-auto py-6 px-4 sm:pt-8 pb-8 sm:px-6 lg:max-w-7xl lg:px-8">

    <div class="rounded-lg bg-white overflow-hidden shadow" *ngIf="equipment" style="border: 1px solid #dedede;">
        <div class="bg-white p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                    <div class="flex-shrink-0">
                        <img class="mx-auto h-20 w-20 rounded-md"
                            [src]="equipment.pictureUri || './assets/img/agnico-eagle-mobile_logo.png'" alt="">
                    </div>
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                            <span [ngClass]="{'bg-red-600': equipment.isActive === false}"
                                class=" h-4 w-4 rounded-full bg-green-500 inline-block" aria-hidden="true">
                            </span>
                            {{ equipment.name }}
                        </p>
                    </div>
                </div>
                <div class="mt-5 flex justify-center sm:mt-0">

                    <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                        <button type="button" (click)="addReservation()"
                            class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none ">
                            <i class="fa-solid fa-plus mr-2">
                            </i>
                            {{ 'features.equipment.ADD_RESERVATION' | translate }}
                        </button>
                    </div>
                </div>

            </div>
            <div class="mt-5 flex justify-center sm:justify-end sm:mt-0 gap-x-2">
                <div class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button type="button" (click)="toggleFavorite($event, equipment.id!)"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-balck bg-gray-300  focus:outline-none "
                        [ngClass]="{'!bg-yellow-500': isFavorite}">
                        <i class="fa-solid fa-star mr-2 ">
                        </i>
                        <span *ngIf="isFavorite">
                            {{ 'features.equipment.REMOVE_FAVORITE' | translate }}
                        </span>
                        <span *ngIf="!isFavorite">
                            {{ 'features.equipment.ADD_FAVORITE' | translate }}
                        </span>
                    </button>
                </div>
                <div *ngIf="isAdmin" class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button type="button" (click)="editRestriction()"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-blue-900 focus:outline-none ">
                        <i class="fa-solid fa-lock mr-2">
                        </i>
                        {{ 'features.equipment.COLUMN_RESTRICTION' | translate }}
                    </button>
                </div>
                <div *ngIf="isAdmin" class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button type="button" (click)="confirmRemoveEquipment($event)"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-800 focus:outline-none ">
                        <i class="fa-solid fa-trash mr-2">
                        </i>
                        {{ 'labels.DELETE' | translate }}
                    </button>
                </div>
                <div *ngIf="isAdmin" class="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    <button type="button" (click)="editEquipment()"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-blue-900 focus:outline-none ">
                        <i class="fa-solid fa-pen mr-2">
                        </i>
                        {{ 'labels.EDIT' | translate }}
                    </button>
                </div>

            </div>
        </div>
        <div
            class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-4 sm:divide-y-0 sm:divide-x">
            <div class="px-6 py-3 text-sm font-medium text-center">
                <div class="text-gray-900 font-bold">{{ 'features.equipment.CREW' | translate }}</div>
                <div class="text-gray-600">{{ crew && crew.name }}</div>
            </div>
            <div class="px-6 py-3 text-sm font-medium text-center">
                <div class="text-gray-900 font-bold">{{ 'features.equipment.COLUMN_CATEGORY' | translate }}</div>
                <div class="text-gray-600">{{ equipment.categoryName }}</div>
            </div>
            <div class="px-6 py-3 text-sm font-medium text-center">
                <div class="text-gray-900 font-bold">{{ 'features.addEquipment.EQUIPMENT_BRAND_LABEL' | translate }}
                </div>
                <div class="text-gray-600">{{ equipment.brand }}</div>
            </div>
            <div class="px-6 py-3 text-sm font-medium text-center">
                <div class="text-gray-900 font-bold">{{ 'features.addEquipment.EQUIPMENT_MODEL_LABEL' | translate }}
                </div>
                <div class="text-gray-600">{{ equipment.model }}</div>
            </div>
        </div>
    </div>

    <div class="mt-4 pb-2 pt-2 bg-[#f7f7f7]" *ngIf="equipment">
        <div class="sm:hidden">
            <select id="tabs" name="tabs" (change)="setOptionTab($event)"
                class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option value="calendar">{{ 'features.equipmentMapLocation.CALENDAR' | translate}}</option>
                <option *ngIf="equipment.isAutoPIEnabled && equipment.deviceId" value="map">{{
                    'features.equipmentMapLocation.MAP' | translate}}</option>
                <option *ngIf="equipment.isAutoPIEnabled && equipment.deviceId" value="location-history">{{
                    'features.equipmentMapLocation.LOCATION_HISTORY' | translate}}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <nav class="flex space-x-4 ml-3" aria-label="Tabs">
                <a href="javascript:void(0)" [ngClass]="{'bg-gray-300 ': selectedTab === 'calendar'}"
                    class="text-gray-600 hover:text-gray-800 rounded-md px-3 py-2 text-sm font-medium"
                    (click)="setTab('calendar')">
                    {{ 'features.equipmentMapLocation.CALENDAR' | translate}}</a>
                <a *ngIf="equipment.isAutoPIEnabled && equipment.deviceId" href="javascript:void(0)"
                    [ngClass]="{'bg-gray-300 ': selectedTab === 'map'}"
                    class="text-gray-600 hover:text-gray-800 rounded-md px-3 py-2 text-sm font-medium"
                    (click)="setTab('map')">{{ 'features.equipmentMapLocation.MAP' | translate}}</a>
                <a *ngIf="equipment.isAutoPIEnabled && equipment.deviceId" href="javascript:void(0)"
                    [ngClass]="{'bg-gray-300 ': selectedTab === 'location-history'}"
                    class="text-gray-600 hover:text-gray-800 rounded-md px-3 py-2 text-sm font-medium"
                    (click)="setTab('location-history')">{{ 'features.equipmentMapLocation.LOCATION_HISTORY' |
                    translate}}</a>
            </nav>
        </div>
    </div>

    <div class="calendar" *ngIf="selectedTab === 'calendar'">

        <mbsc-eventcalendar [invalid]="invalidDate" [locale]=" locale" [data]="myEvents" [options]="eventSettings"
            [view]="calView">
        </mbsc-eventcalendar>

        <mbsc-popup class="md-tooltip" #popup [anchor]="anchor" [options]="popupOptions">
            <div *ngIf="hoverEvent" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()">
                <div class="md-tooltip-header" [ngStyle]="{background: hoverEvent?.color!}">
                    <span class="md-tooltip-name-age">{{hoverEvent?.userName!}}</span>
                    <span class="md-tooltip-time">{{time}}</span>
                </div>
                <div class="md-tooltip-info">
                    <div class="md-tooltip-title">
                        Equipment: <span class="md-tooltip-status md-tooltip-text">{{hoverEvent?.equipmentName}}</span>
                        <div>
                            <span class="md-tooltip-title pr-1">Comment:</span>
                            <span class="md-tooltip-text">
                                {{hoverEvent?.description || 'N/A'}}
                            </span>
                        </div>
                    </div>
                    <div class="md-tooltip-title">
                        <div *ngIf="hoverEvent?.recurring">
                            <div class="rounded bg-gray-300 w-20 px-1">Recurring:</div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).FREQ" class="">
                                <span class="md-tooltip-title pr-1">Frequency: </span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).FREQ}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).INTERVAL" class="">
                                <span class="md-tooltip-title pr-1">Interval:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).INTERVAL}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).BYDAY" class="">
                                <span class="md-tooltip-title pr-1">Day:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).BYDAY}}</span>
                            </div>
                            <div *ngIf="getCustomRule(hoverEvent?.recurring).UNTIL" class="">
                                <span class="md-tooltip-title pr-1">Until:</span>
                                <span class="md-tooltip-text">{{getCustomRule(hoverEvent?.recurring).UNTIL}}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </mbsc-popup>
    </div>

    <div class="calendar" *ngIf="selectedTab === 'map'">
        <app-map [deviceId]="equipment.deviceId"></app-map>
    </div>

    <div class="calendar" *ngIf="selectedTab === 'location-history'">
        <app-equipment-location-history [deviceId]="equipment.deviceId"></app-equipment-location-history>
    </div>
</div>