<div class="ml-3 relative">
  <div>
    <button (click)="toggleMenu()" type="button"
      class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      id="user-menu-button" aria-expanded="false" aria-haspopup="true">
      <span class="sr-only">Open user menu</span>
      <svg class="w-8 h-8 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path>
      </svg>
    </button>
  </div>

  <div *ngIf="isMenu" @AnimationTrigger2
    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
    role="menu" aria-orientation="vertical" aria-labelledby="user-menu-button" tabindex="-1">

    <a href="javascript:void(0)" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
      id="user-menu-item-2" (click)="settings()">{{ 'features.settings.SETTINGS' | translate}}
    </a>
    <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1" id="user-menu-item-2"
      (click)="logout()">{{ 'labels.SIGNOUT' | translate}}</a>
  </div>
</div>