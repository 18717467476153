import { Timezone as TZ } from 'src/app/data';

export class Timezone {
  public static getTimezone(timezones: TZ[], timeOffset: string) {
    const timezone = timezones.find(
      (timezone: any) => timezone.id === timeOffset
    );

    return timezone?.displayName;
  }
}
