import { Component, OnInit } from '@angular/core';
import { MenuItem } from '@app/models/menuitem';
import { TranslateService } from '@ngx-translate/core';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent extends VfmBaseComponent implements OnInit {
  adminMenu: MenuItem[] = [];
  menuLabels = {
    ManageCategory: this.translate.instant(
      'features.admin.ADMIN_MENUS.MANAGE_CATEGORIES'
    ),
    ManageCrew: this.translate.instant(
      'features.admin.ADMIN_MENUS.MANAGE_CREWS'
    ),
    ManageRole: this.translate.instant(
      'features.admin.ADMIN_MENUS.MANAGE_ROLES'
    ),
    ManageEquipment: this.translate.instant(
      'features.admin.ADMIN_MENUS.MANAGE_EQUIPMENT'
    ),
    ManageRoles: this.translate.instant(
      'features.admin.ADMIN_MENUS.MANAGE_ROLES'
    ),
  };
  pageTitle = this.translate.instant('features.admin.TITLE_HOME');
  pageIconClass = 'fa-solid fa-house';

  constructor(
    private translate: TranslateService,
    private roleUserService: UserRoleService
  ) {
    super();
  }

  async ngOnInit() {
    this.adminMenu = [
      {
        label: 'Sites',
        routerLink: 'site',
        iconClass: 'fa-solid fa-location-dot',
        disabled: false,
      },
      {
        label: this.menuLabels.ManageCategory,
        routerLink: 'category',
        iconClass: 'fa-solid fa-folder-tree',
        disabled: false,
      },
      {
        label: this.menuLabels.ManageCrew,
        routerLink: 'crew',
        iconClass: 'fa-solid fa-people-group',
        disabled: false,
      },
      {
        label: this.menuLabels.ManageEquipment,
        routerLink: 'equipment',
        iconClass: 'fa-solid fa-truck',
        disabled: false,
      },
      // {
      //   label: this.menuLabels.ManageRole,
      //   routerLink: 'role',
      //   iconClass: 'fa-solid fa-user',
      //   disabled: false,
      // },
      {
        label: 'Reservations',
        routerLink: 'reservation',
        iconClass: 'fa-solid fa-calendar',
        disabled: false,
      },
      // {
      //   label: 'Notifications',
      //   routerLink: '',
      //   iconClass: 'fa-solid fa-bell',
      //   disabled: true,
      // },
    ];

    const roles = this.roleUserService.getRoles() || [];
    const requiredRoles = [ROLES.ADMIN];

    if (!requiredRoles.some((r) => roles.includes(r))) {
      this.adminMenu = this.adminMenu.filter(
        (obj) => obj.routerLink !== 'site'
      );
    }
  }

  toggleModal() {}
}
