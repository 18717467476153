import { MbscModule } from '@mobiscroll/angular';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SiteNavComponent } from './layout/site-nav/site-nav.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { VfmCoreModule } from '@app/core.module';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from '@app/utils/auth-factories';
import { AdminModule } from './features/admin/admin.module';
import { environment } from '@env';
import { BaseUrlInterceptor } from '@app/interceptors/base-url.interceptor';
import { NgxTranslateModule } from './translate/translate.module';
import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { UserProfileComponent } from './features/user-profile/user-profile.component';
import { ErrorHandlerInterceptor } from '@app/interceptors/error-handler.interceptor';
import { SettingsComponent } from './features/settings/settings.component';
import { ProfileComponent } from './features/profile/profile.component';
import { ProfileDetailEditComponent } from './features/profile/components/profile-detail-edit/profile-detail-edit.component';
import { WorkingShiftEditComponent } from './features/profile/components/working-shift-edit/working-shift-edit.component';
import { NotificationSettingsComponent } from './features/settings/components/notification-settings/notification-settings.component';
import { ToastrModule } from 'ngx-toastr';
import { MyUpcomingReservationsComponent } from './features/reservation/components/my-upcoming-reservations/my-upcoming-reservations.component';
import { ReservationComponent } from './features/reservation/reservation.component';
import { ReservationsListComponent } from './features/reservation/components/reservations-list/reservations-list.component';
import { ReservationSearchComponent } from './features/reservation/components/reservation-search/reservation-search.component';
import { ReservationAddComponent } from './features/reservation/components/reservation-add/reservation-add.component';
import { ReservationAddDetailComponent } from './features/reservation/components/reservation-add/components/reservation-add-detail/reservation-add-detail.component';
import { ReservationAddAvailabilityComponent } from './features/reservation/components/reservation-add/components/reservation-add-availability/reservation-add-availability.component';
import { ReservationEquipmentSelectComponent } from './features/reservation/components/reservation-add/components/reservation-equipment-select/reservation-equipment-select.component';
import { ReservationCalendarLegendComponent } from './features/reservation/components/reservation-add/components/reservation-calendar-legend/reservation-calendar-legend.component';
import { ReservationWorkingShiftDialogComponent } from './features/reservation/components/reservation-add/components/reservation-working-shift-dialog/reservation-working-shift-dialog.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UnauthorizeComponent } from './features/unauthorize/unauthorize.component';
import { UserRoleService } from 'src/app/core/services/common/user-role.service';
import { FaqComponent } from './features/faq/faq.component';
import { FaqUpdateComponent } from './features/faq/components/faq-update/faq-update.component';
import { FaqLibraryVideoComponent } from './features/faq/components/faq-library-video/faq-library-video.component';
import { FaqQuestionsComponent } from './features/faq/components/faq-questions/faq-questions.component';
import { FaqDialogComponent } from './features/faq/components/faq-dialog/faq-dialog.component';
import { MapLocationHistoryComponent } from './features/equipment/components/map-location-history/map-location-history.component';

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ContentLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SiteNavComponent,
    AuthLayoutComponent,
    SiteNavComponent,
    UserProfileComponent,
    SettingsComponent,
    ProfileComponent,
    ProfileDetailEditComponent,
    WorkingShiftEditComponent,
    NotificationSettingsComponent,
    MyUpcomingReservationsComponent,
    ReservationComponent,
    ReservationsListComponent,
    ReservationSearchComponent,
    ReservationAddComponent,
    ReservationAddDetailComponent,
    ReservationAddAvailabilityComponent,
    ReservationEquipmentSelectComponent,
    ReservationCalendarLegendComponent,
    ReservationWorkingShiftDialogComponent,
    UnauthorizeComponent,
    FaqComponent,
    FaqUpdateComponent,
    FaqLibraryVideoComponent,
    FaqQuestionsComponent,
    FaqDialogComponent,
    MapLocationHistoryComponent,
  ],
  imports: [
    MbscModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgxTranslateModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    VfmCoreModule,
    MsalModule,
    AdminModule,
    ReactiveFormsModule,
    MatDialogModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: 'BASE_API_URL',
      useValue: environment.backendBaseUrl,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'standard' },
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    UserRoleService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
