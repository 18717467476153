import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { Reservation } from 'src/app/data/index';
import { handleError } from './handleError';

const CAT_API_RESERVATION = 'reservation/site';

export interface EquipmentRequest {
  siteCode: string;
  crewId: number;
  categoryId: number;
  pictureUri: string;
  pictureName: string;
  name: string;
  unit: string;
  brand: string;
  model: string;
  description: string;
  tags: string[];
  isActive: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  constructor(private http: HttpClient) {}

  /**
   * @returns an observable of Reservation
   */
  getReservationsById(
    sitecode: string,
    id: number,
    pageSize = 0,
    pageIndex = 0
  ) {
    return this.http
      .get<Reservation>(`${CAT_API_RESERVATION}/${sitecode}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   * @returns an observable of Reservation
   */
  searchReservation(
    sitecode: string,
    searchText: string,
    CategoryId: string,
    CrewId: string,
    StartDate: string,
    EndDate: string,
    EquipmentId: string,
    UserId: string
  ) {
    return this.http
      .get<Reservation>(
        `${CAT_API_RESERVATION}/${sitecode}/search?pageSize=0&pageIndex=0&searchText=${searchText}&CategoryId=${CategoryId}&CrewId=${CrewId}&StartDate=${StartDate}&EndDate=${EndDate}&EquipmentId=${EquipmentId}&UserId=${UserId}`
      )
      .pipe(catchError(handleError));
  }
}
