import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '@env';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.clientId,
        authority: environment.authority,
        redirectUri: environment.loginRedirectUrl,
        postLogoutRedirectUri: environment.loginRedirectUrl,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE,
    },
    system: environment.production ? {} : {
        loggerOptions: {
            loggerCallback(logLevel: LogLevel, message: string) {
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}