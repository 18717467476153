<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevLink]" [showChevron]=true>
</app-page-title>
<div class="loading-spinner" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8">
    <div class="space-y-8 divide-y divide-gray-200">
        <div>
            <button type="button"
                class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600 float-right mb-8"
                (click)="addRestriction()">
                <i class="fa-solid fa-plus mr-2"></i>
                {{ 'features.restriction.ADD_RESTRICTION_BUTTON' | translate }}
            </button>
        </div>

        <table mat-table [dataSource]="dataSource" class="cat-table">
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.restriction.COLUMN_TYPE'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{getType(element.availabilityType)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.restriction.COLUMN_STARTDATE'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{getDate(element.startDate)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="recurrence">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.restriction.COLUMN_RECURRENCE'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{element.rRule}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="startHour">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.restriction.COLUMN_STARTHOUR'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{getHour(element.startDate)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="endHour">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.restriction.COLUMN_ENDHOUR'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="text-sm">{{getHour(element.endDate)}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="text-sm">{{ 'features.crew.COLUMN_CREW_ACTION'| translate }}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button type="button"
                        class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600 float-right my-3"
                        (click)="confirmRemoveRestriction(element.id, $event)">
                        <i class="fa-solid fa-trash-can mr-2"></i>
                        {{ 'features.crew.DELETE_CREW_BUTTON' | translate }}
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row (click)="editRestriction(row)" *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <mat-paginator [length]="totalCount" [pageSize]="10" [pageSizeOptions]="[10, 25, 100]"
        aria-label="select page for restrictions"></mat-paginator>
</div>