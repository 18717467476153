<aside class="hidden xs:w-25 md:order-first md:flex md:flex-col flex-shrink-0 md:w-70 lg:w-80 border-r border-gray-200">

    <nav class="flex-1 min-h-0 overflow-y-auto shadow">
        <div class="relative">
            <div
                class="z-10 sticky py-4 top-0 border-t border-b border-gray-300 bg-gray-200 px-6 text-sm font-medium text-gray-900">
                {{ 'feature.addEquipment.COLUMN_EQUIPMENT' | translate }}
            </div>

            <ul role="list" class="relative z-0 divide-y divide-gray-300" style="min-height: calc(100vh - 347px);">
                <li *ngFor="let equipment of equipments; let i = index">
                    <div (click)="selectEquipment(equipment)"
                        [ngClass]="{'bg-yellow-default selected ': (equipmentId && equipmentId === equipment.id) || (!equipmentId && i === 0)}"
                        class="relative px-6 py-5 flex items-center space-x-3 ">
                        <div class="flex-shrink-0">
                            <img class="h-10 w-14 rounded" [src]="equipment.pictureUri" alt="">
                        </div>
                        <div class="flex-1 min-w-0">
                            <a href="javascript:void(0)" class="focus:outline-none">
                                <span class="absolute inset-0" aria-hidden="true"></span>
                                <p class="text-sm font-bold text-gray-900">
                                    <span [ngClass]="{'bg-red-600': equipment.isActive === false}"
                                        class="mt-1.5 h-3 w-3 rounded-full bg-green-500 inline-block"
                                        aria-hidden="true">
                                    </span>
                                    {{ equipment.name }}
                                </p>

                                <p>
                                    <span class="px-2 py-1 ml-2 text-gray-700 text-xs font-medium">
                                        {{ equipment.categoryName }}
                                    </span>
                                </p>
                            </a>
                        </div>
                    </div>
                </li>

            </ul>

        </div>

    </nav>
</aside>