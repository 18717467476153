import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from '@app/services/data/category.service';
import { merge, startWith, Subject, takeUntil, switchMap, map } from 'rxjs';
import { Category, PaginatedItems } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { VfmBaseComponent } from '../../VfmBaseComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '@app/services/common/dialog.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent
  extends VfmBaseComponent
  implements AfterViewInit
{
  displayedColumns: string[] = ['categoryName', 'description', 'action'];
  dataSource: Category[] = [];

  @ViewChild(MatTable)
  table!: MatTable<Category>;

  pageTitle = this.translate.instant('features.category.TITLE_HOME');

  pageIconClass = 'fa-solid fa-folder-tree';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalCount: number = 0;
  isLoadingResults = true;

  constructor(
    private catService: CategoryService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialogService: DialogService,
    private toastrService: ToastrService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.loadCategories();
  }

  /**
   * Load categories with paginator data.
   */
  loadCategories() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.catService.getCategories(
            this.site.code,
            this.paginator.pageSize,
            this.paginator.pageIndex
          );
        }),
        map((resp: PaginatedItems<Category>) => {
          this.isLoadingResults = false;
          this.totalCount = resp.count;
          return resp.data;
        })
      )
      .subscribe({
        next: (data) => {
          this.dataSource = data;
        },
        error: (err: HttpErrorResponse) => {
          this.isLoadingResults = false;
        },
      });
  }

  /**
   * Redirect to add category compenent
   */
  addCategory() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  showCategory(category: Category) {
    if (category) {
      this.router.navigate(['modify', category.id], { relativeTo: this.route });
    }
  }

  /**
   * Delete a category
   */
  deleteCategory(categoryId: number) {
    this.catService.deleteCategory(this.site.code, categoryId).subscribe(() => {
      this.loadCategories();
      this.toastrService.success(
        this.translate.instant(
          'features.category.CATEGORY_SUCCESSFULLY_DELETED'
        )
      );
    });
  }

  confirmRemoveCategory(categoryId: number, event: any) {
    event.stopImmediatePropagation();

    this.dialogService
      .confirm(
        this.translate.instant('features.category.CONFIRM_REMOVE_DIALOG.TITLE'),
        this.translate.instant(
          'features.category.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteCategory(categoryId);
        }
      });
  }
}
