<div class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-calendar-days mr-2"></i>
        {{reservations.length}} Upcoming Reservations(s)
    </h3>
    <button type="button" (click)="addReservation()"
        class="relative inline-flex items-center px-4 py-2 border border-transparent shadow-lg text-sm font-medium rounded-md text-white bg-black-default hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black-default">
        <i class="fa-solid fa-plus"></i>
    </button>
</div>

<p-carousel [value]="reservations" [numVisible]="6" [numScroll]="6" [circular]="true" [showIndicators]="false"
    [responsiveOptions]="responsiveOptions">
    <ng-template let-product pTemplate="item">
        <div class="calendar-container shadow rounded-lg overflow-hidden relative text-center">
            <header>
                <div class="day">
                    <span class="calendar-day">18</span>
                </div>
                <div class="month">
                    <span class="calendar-month">August</span>
                </div>

                <div class="banner-container">
                    <div class="banner">Recurring</div>
                </div>
            </header>

            <div class="content">
                <span class="equipment-name">Equipment A</span>
            </div>
            <!-- 
            <div class="ring-left"></div>
            <div class="ring-right"></div> -->
        </div>

    </ng-template>
</p-carousel>

<div class="mb-4 mx-4">
    <div class="mt-4 mb-4" *ngIf="reservations && reservations.length === 0">No Reservation</div>
    <a href="#" [routerLink]="['/reservation']"
        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        View all
    </a>
</div>