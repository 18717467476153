import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { UserRoleService } from 'src/app/core/services/common/user-role.service';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import { UserProfile } from './data';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuService } from '@app/services/common/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends VfmBaseComponent implements OnInit {
  title = 'vfmapp';
  userProfile!: UserProfile;
  mdq: MediaQueryList;
  mediaQueryListener: () => void;

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private userRoleService: UserRoleService,
    private userProfileService: UserProfileService,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private menuService: MenuService
  ) {
    super();

    this.mdq = media.matchMedia('(max-width: 1024px)');
    this.mediaQueryListener = () => {
      changeDetectorRef.detectChanges();
      this.menuService.updateIsMobileSize(this.mdq.matches);
    };
    this.mdq.addEventListener('change', this.mediaQueryListener);

    this.menuService.updateIsMobileSize(this.mdq.matches);
  }

  ngOnInit(): void {
    this.loadMyProfile();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        localStorage.setItem(
          'VFM_LOGIN_DATE',
          moment().format('YYYY-MM-DD HH:mm:ss')
        );

        setTimeout(() => {
          this.userRoleService.fetchAccessToken();
        }, 2000);
      });
  }

  loadMyProfile() {
    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userProfile = userProfile;

        this.hasDefaultValues(this.userProfile);
      });
  }

  hasDefaultValues(userProfile: UserProfile) {
    if (
      userProfile.siteId === 0 ||
      userProfile.siteId === null ||
      userProfile.categoryId === 0
    ) {
      this.router.navigate(['/settings/profile/edit'], {
        queryParams: { nodefaultvalue: 'true' },
      });
    }
  }
}
