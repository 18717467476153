<div class="relative">
    <div
        class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center shadow flex-wrap sm:flex-nowrap">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            <i class="fa-solid fa-circle-info mr-2"></i>
            {{ 'features.equipmentMapLocation.EQUIPMENT_MAP_LOCATION' | translate}}
        </h3>
    </div>

    <div
        class="bg-gray-50 px-4 sm:px-6 lg:px-8 border border-gray-300 border-b-1 border-t-0 border-l-0 border-r-0 pb-2">

        <div class="mt-4 pb-2 pt-2 bg-[#f7f7f7]">
            <div class="sm:hidden">
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select id="tabs" name="tabs" (change)="setOptionTab($event)"
                    class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                    <option value="map">{{ 'features.equipmentMapLocation.MAP' | translate}}</option>
                    <option value="location-history">{{ 'features.equipmentMapLocation.LOCATION_HISTORY' | translate}}
                    </option>
                </select>
            </div>
            <div class="hidden sm:block">
                <nav class="flex space-x-4 ml-3" aria-label="Tabs">

                    <a href="javascript:void(0)" [ngClass]="{'bg-gray-300 ': selectedTab === 'map'}"
                        class="text-gray-600 hover:text-gray-800 rounded-md px-3 py-2 text-sm font-medium"
                        (click)="setTab('map')">{{ 'features.equipmentMapLocation.MAP' | translate}}</a>
                    <a href="javascript:void(0)" [ngClass]="{'bg-gray-300 ': selectedTab === 'location-history'}"
                        class="text-gray-600 hover:text-gray-800 rounded-md px-3 py-2 text-sm font-medium"
                        (click)="setTab('location-history')">{{ 'features.equipmentMapLocation.LOCATION_HISTORY' |
                        translate}}</a>
                </nav>
            </div>
        </div>

        <div *ngIf="selectedTab === 'map'" class="pb-[20px]">
            <app-map [deviceId]="data.deviceId"></app-map>
        </div>

        <div *ngIf="selectedTab === 'location-history'">
            <app-equipment-location-history [deviceId]="data.deviceId"></app-equipment-location-history>
        </div>
    </div>

    <div class="bg-gray-50 px-4 py-3 sm:px-6 text-right sticky bottom-0 left-0">

        <button (click)=" onNoClick()"
            class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Close
        </button>

    </div>
</div>