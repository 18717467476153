import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog) {}

  /**
   *  Ask user to confirm an action.
   * @param message to show to the user
   * @returns true (confirm) or false (no)
   */
  confirm(title?: string, message?: string): Observable<boolean> {
    return this.openDialog(title, message);
  }

  /**
   *  Open an mat dialog.
   * This is a generic dialog with a yes | no answer.
   * @param title
   * @param message to be shown to the user
   * @returns
   */
  openDialog(title?: string, message?: string): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: { title: title, message: message },
    });

    return dialogRef.afterClosed();
  }
}
