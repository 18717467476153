<mat-form-field>

    <div class="myfilebrowser">

        <mat-toolbar>

            <!-- Readonly Input to show File names -->
            <input matInput readonly name="memberContactNo" />

            <!-- Browse Button -->
            <button mat-flat-button color="primary">
                Browse
            </button>

        </mat-toolbar>

        <!-- Fetch selected filed on change -->
        <input type="file" #UploadFileInput id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*"
            (change)="fileChangeEvent($event)" />

    </div>

</mat-form-field>
<div class="imagePreview">
    <img [src]="imgBase64Path">
</div>