import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrewService } from '@app/services/data/crew.service';
import { TranslateService } from '@ngx-translate/core';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import { Member, Crew } from 'src/app/data';

@Component({
  selector: 'app-members-list',
  templateUrl: './members-list.component.html',
  styleUrls: ['./members-list.component.scss'],
})
export class MembersListComponent extends VfmBaseComponent implements OnInit {
  crewId!: number;
  prevPageLink: string = '';
  crewMembers!: Member[];
  searchCrewMembers!: Member[];
  searchmember = '';
  selectedMember: Member = {
    id: 0,
    name: '',
    objectId: '',
    email: '',
    jobTitle: '',
    department: '',
    phoneNumber: '',
    catetoryName: '',
    categoryId: 0,
    language: '',
    shift: '',
    siteId: 0,
    siteName: '',
  };
  @Output() member: EventEmitter<Member> = new EventEmitter<Member>();

  crew!: Crew;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private crewService: CrewService
  ) {
    super();
  }

  ngOnInit(): void {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (crewId) {
      this.crewId = +crewId;
      this.prevPageLink = `/admin/crew/profile/${crewId}`;
      this.loadCrewMembers();
      this.loadCrew();
    }
  }

  addMember() {
    this.router.navigate(
      [`/admin/crew/profile/${this.crewId}/members/detail/member/add`],
      {
        relativeTo: this.route,
      }
    );
  }

  loadCrewMembers() {
    this.crewService
      .getCrewMembers(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((members: any) => {
        this.crewMembers = members.data;
        this.searchCrewMembers = this.crewMembers;

        if (this.crewMembers && this.crewMembers.length > 0) {
          this.selectedMember = this.crewMembers[0];
          this.member.emit(this.selectedMember);
        }
      });
  }

  loadCrew() {
    this.crewService
      .getCrewById(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((crew: Crew) => {
        this.crew = crew;
      });
  }

  selectMember(member: Member) {
    this.selectedMember = member;
    this.member.emit(member);
  }

  search(value: string): void {
    this.searchCrewMembers = this.crewMembers.filter((val) =>
      val.name.toLowerCase().includes(value)
    );
  }

  clearSearch() {
    this.searchmember = '';
    this.search('');
  }
}
