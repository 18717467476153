import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-upcoming-reservations',
  templateUrl: './my-upcoming-reservations.component.html',
  styleUrls: ['./my-upcoming-reservations.component.scss'],
})
export class MyUpcomingReservationsComponent implements OnInit {
  reservations = [
    {
      id: 1,
      date: '2022-07-10',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 2,
      date: '2022-07-11',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 1,
      date: '2022-07-12',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 1,
      date: '2022-07-10',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 2,
      date: '2022-07-11',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 1,
      date: '2022-07-12',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 1,
      date: '2022-07-10',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 2,
      date: '2022-07-11',
      equipment: {
        name: 'equipment A',
      },
    },
    {
      id: 1,
      date: '2022-07-12',
      equipment: {
        name: 'equipment A',
      },
    },
  ];

  responsiveOptions = [
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3,
    },
    {
      breakpoint: '768px',
      numVisible: 2,
      numScroll: 2,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  constructor() {}

  ngOnInit(): void {}

  addReservation() {}
}
