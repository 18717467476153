<div class="md:mx-20 ">

    <!-- Curent Update-->
    <div class="grid grid-cols-1 gap-4 auto-rows-max">
        <div class=" block  m-5 p-6 bg-white border border-gray-200 rounded-lg shadow">
            <p> <strong >{{ 'features.faq.UPDATE.LAST_UPDATE'| translate }}</strong > {{this.updateInfo.LAST_UPDATE.DATE}}</p>
            <p> <strong >{{ 'features.faq.UPDATE.NEW'| translate }}</strong ></p>
            <ul  class="ml-5 list-disc">
                <li *ngFor="let item of updateInfo.LAST_UPDATE.NEW">{{item}}</li>
            </ul>
        </div>
    </div>

    <!-- Previous Update -->
    <div class="grid grid-cols-1 gap-4">
        <div class=" m-5">
            <mat-accordion class="example-headers-align" multi>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                <mat-panel-title>
                    <strong>{{ 'features.faq.UPDATE.PREVIOUS_UPDATE'| translate }}</strong>
                </mat-panel-title>
                
                </mat-expansion-panel-header>
                <hr>
                <div class="my-5">
                    <p> <strong >{{ 'features.faq.UPDATE.UPDATE'| translate }}</strong > {{this.updateInfo.PREVIOUS_UPDATE.DATE}}</p>
                    <p> <strong >{{ 'features.faq.UPDATE.NEW'| translate }}</strong ></p>
                    <ul  class="ml-5 list-disc">
                        <li *ngFor="let item of updateInfo.PREVIOUS_UPDATE.NEW">{{item}}</li>
                    </ul>
                </div>
                
                
            </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>

    
</div>
