<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [routerLink]="[prevLink]" [showChevron]=true>
</app-page-title>

<div class="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8 mt-24 mb-24">

    <div class="mt-10">
        <dl class="space-y-10 md:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10">
            <div class="relative mb-10">
                <div class="bg-white">
                    <div class="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 restriction-form">
                        <div class="space-y-8 divide-y">

                            <form [formGroup]="restrictionForm" (keydown.enter)="add($event)">
                                <div class="shadow-xl drop-shadow-md  sm:rounded-md sm:overflow-hidden">
                                    <div class="px-4 py-[10px] bg-yellow-default sm:px-6 text-black">
                                        <h1 *ngIf="!restrictionId" class="">
                                            <i class="fa-solid fa-calendar-xmark mr-2"></i>
                                            {{'features.addRestriction.TITLE_HOME' | translate}}
                                        </h1>
                                        <h1 *ngIf="restrictionId" class="">
                                            <i class="fa-solid fa-calendar-xmark mr-2"></i>
                                            {{'features.addRestriction.TITLE_MODIFY' | translate}}
                                        </h1>
                                    </div>

                                    <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                                        <div>
                                            <mat-form-field [style.width.%]="100">
                                                <mat-label>{{ 'features.addRestriction.TYPE' | translate}}</mat-label>
                                                <mat-select [formControl]="restrictionTypeCtrl" name="crew">
                                                    <mat-option *ngFor="let type of types" [value]="type.id">
                                                        {{type.text}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error *ngIf="restrictionTypeCtrl.hasError('required')">
                                                    {{ 'features.addRestriction.TYPE' | translate}} {{ 'labels.IS' |
                                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                                        translate}}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <app-recurring-date [form]="restrictionForm"
                                            (recurringRule)="onRecurringRuleChange($event)" #recurringDateComponent
                                            [form]="restrictionForm"></app-recurring-date>

                                        <div>
                                            <mat-form-field [style.width.%]="100">
                                                <mat-label>{{ 'features.addRestriction.STARTHOUR' | translate}} - {{
                                                    'features.addRestriction.ENDHOUR' | translate}}</mat-label>
                                                <input mbsc-datepicker [mbscOptions]="datepickerOptTime"
                                                    variant="outlined" [formControl]="restrictionStartHourEndHourCtrl"
                                                    matInput>

                                                <mat-error *ngIf="restrictionStartHourEndHourCtrl.hasError('required')">
                                                    {{ 'features.addRestriction.STARTHOUR' | translate}} {{ 'labels.IS'
                                                    |
                                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                                        translate}}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <div style="margin-top: 0 !important;">
                                            <mat-form-field [style.width.%]="100">
                                                <mat-label>From Date</mat-label>
                                                <input mbsc-datepicker [mbscOptions]="datepickerOptDate"
                                                    variant="outlined" formControlName="from" matInput>
                                                <mat-error *ngIf="restrictionFromCtrl.hasError('required')">
                                                    {{ 'features.addRestriction.STARTDATE' | translate}} {{ 'labels.IS'
                                                    |
                                                    translate}} <strong>{{ 'labels.REQUIRED' |
                                                        translate}}</strong>
                                                </mat-error>
                                            </mat-form-field>
                                        </div>

                                        <!-- <div>
                                            <mbsc-switch themeVariant="light" class="mb-10" color="primary"
                                                formControlName="active" label="Active" [style.width.%]="100">
                                            </mbsc-switch>
                                        </div> -->
                                    </div>

                                    <div class="px-4 py-3 bg-gray-50 sm:px-6 h-[62px]">
                                        <button (click)="confirmRemoveRestriction()" *ngIf="restrictionId"
                                            class="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white bg-red-700 hover:bg-red-800 focus:outline-none">
                                            {{ 'features.crew.DELETE_CREW_BUTTON' | translate }}
                                        </button>

                                        <div class="inline-block float-right">
                                            <button (click)="cancel()"
                                                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                {{ 'labels.CANCEL' | translate}}
                                            </button>

                                            <button (click)="confirmSave()" cdkFocusInitial
                                                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-700 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600">
                                                {{ 'labels.SAVE' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <!-- <div class="mt-6 ml-2">
                            <mat-checkbox class="mr-4">
                                Show all active restrictions from this equipment
                            </mat-checkbox>
                        </div> -->

                    </div>
                </div>
            </div>

            <div class="relative">
                <div class="bg-gray-50 shadow overflow-hidden sm:rounded-lg mb-8">
                    <app-add-restriction-calendar [selectedFromDate]="selectedFromDate" [selectedhours]="selectedhours"
                        [selectedType]="selectedType" [selectedDays]="selectedDays" [rRule]="reccuringRule">
                    </app-add-restriction-calendar>
                </div>
            </div>
        </dl>
    </div>
</div>