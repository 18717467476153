import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-map-location-history',
  templateUrl: './map-location-history.component.html',
  styleUrls: ['./map-location-history.component.scss'],
})
export class MapLocationHistoryComponent implements OnInit {
  selectedTab = 'map';

  constructor(
    public dialogRef: MatDialogRef<MapLocationHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  setTab(tabName: string) {
    this.selectedTab = tabName;
  }

  setOptionTab(e: any) {
    this.selectedTab = e.target.value;
  }
}
