import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import {
  map,
  startWith,
  debounceTime,
  tap,
  switchMap,
  finalize,
} from 'rxjs/operators';
import { EquipmentService } from '../../../core/services/data/equipment.service';
import { Device } from 'src/app/data/index';
import { Observable, empty } from 'rxjs';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';

@Component({
  selector: 'app-equipment-device-autocomplete',
  templateUrl: './equipment-device-autocomplete.component.html',
  styleUrls: ['./equipment-device-autocomplete.component.scss'],
})
export class EquipmentDeviceAutocompleteComponent
  extends VfmBaseComponent
  implements OnInit
{
  @Input() form!: FormGroup;
  @Input() ctrlName: string = 'control';
  @Input() ctrlId: string = 'control';
  @Input() name!: string;
  @Input() multiple: boolean = true;
  @Input() required: boolean = false;

  isLoading = false;
  isInputDisabled = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  deviceCtrl = new FormControl();
  filteredDevices: any;
  errorMsg: string = '';

  devices: any[] = [];
  allDevices: any[] = [];

  @ViewChild('deviceInput') deviceInput!: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete!: MatAutocomplete;

  constructor(
    private equipService: EquipmentService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.form.get(this.ctrlName)?.valueChanges.subscribe((val) => {
      if (val.length === 0) {
        this.devices = [];
        this.isInputDisabled = false;
      } else {
        this.devices.push({ displayName: val });

        if (!this.multiple) {
          this.isInputDisabled = true;
        }
      }
    });

    this.deviceCtrl.valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.errorMsg = '';
          this.filteredDevices = [];
          this.isLoading = true;
        }),
        switchMap(() => {
          const searchText = this.deviceInput.nativeElement.value;

          if (searchText.length >= 3) {
            return this.equipService
              .searchDevices(this.site.code, searchText)
              .pipe(finalize(() => {}));
          } else {
            return empty().pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          }
        })
      )
      .subscribe((data: any) => {
        if (data && data.data && data.data.length > 0) {
          this.isLoading = false;
          this.errorMsg = '';
          this.filteredDevices = data.data;
        } else {
          this.isLoading = false;
          this.errorMsg = this.translate.instant(
            'features.userAutoComplete.NO_USER_FOUND'
          );
          this.filteredDevices = [];
        }
      });
  }

  remove(device: Device): void {
    const index = this.devices.indexOf(device);
    this.isInputDisabled = false;

    if (index >= 0) {
      this.devices.splice(index, 1);
      this.form.get(this.ctrlName)!.setValue(this.devices);
      this.form.get(this.ctrlId)!.setValue('');
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.multiple) {
      this.isInputDisabled = true;
    }

    // this.devices.push(event.option.value);
    this.deviceInput.nativeElement.value = '';
    this.form.get(this.ctrlName)!.setValue(event.option.value.vehicle.display);
    this.form.get(this.ctrlId)!.setValue(event.option.value.id);
    this.deviceCtrl.setValue(null);
    this.filteredDevices = [];
  }

  get crewCtrl() {
    return this.form.get(this.ctrlName) as FormControl;
  }
}
