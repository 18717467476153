import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CrewService } from '@app/services/data/crew.service';
import { TranslateService } from '@ngx-translate/core';
import { map, merge, takeUntil, startWith, switchMap } from 'rxjs';
import { Crew, PaginatedItems } from 'src/app/data';
import { VfmBaseComponent } from '../../VfmBaseComponent';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';
import { DialogService } from '@app/services/common/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { JoinCrewComponent } from './components/join-crew/join-crew.component';
import { LeaveCrewComponent } from './components/leave-crew/leave-crew.component';

@Component({
  selector: 'app-crew',
  templateUrl: './crew.component.html',
  styleUrls: ['./crew.component.scss'],
})
export class CrewComponent extends VfmBaseComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'name',
    'members',
    'membership',
    'description',
    'action',
  ];
  dataSource: Crew[] = [];
  pageTitle = this.translate.instant('features.crew.TITLE_HOME');

  pageIconClass = 'fa-solid fa-folder-tree';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalCount: number = 0;
  isLoadingResults = true;
  isAdmin = false;
  isMyCrewView = false;

  @ViewChild(MatTable)
  table!: MatTable<Crew>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private crewService: CrewService,
    private roleUserService: UserRoleService,
    private dialogService: DialogService,
    private toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    super();
  }

  async ngAfterViewInit() {
    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.loadCrews();
  }

  getCrew(status:string){
    if (status === 'all'){
      this.loadCrews();
    } else if (status === 'myCrew'){
      this.loadMyCrews(false,true);
    } else if (status === 'crewManage'){
      this.loadMyCrews(true,false);
    }
  }

  /**
   * Load crews
   */
  loadCrews() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.crewService.getPaginatedCrews(
            this.site.code,
            this.paginator.pageSize,
            this.paginator.pageIndex
          );
        }),
        map((resp: PaginatedItems<Crew>) => {
          this.isLoadingResults = false;
          this.totalCount = resp.count;
          return resp.data;
        })
      )
      .subscribe(
        (data) => {
          this.dataSource = data;
        },
        (err) => {
          this.isLoadingResults = false;
        }
      );
  }

  loadMyCrews(isManage:boolean , isMember:boolean) {
    this.crewService
      .getPaginatedCrewsByUser(
        this.site.code,
        this.profile.objectId,
        false,
        isManage,
        isMember,
        0,
        0
      )
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe(
        (data) => {
          this.dataSource = data;
        },
        (err) => {
          this.isLoadingResults = false;
        }
      );
  }

  /**
   * Add a new crew
   */
  addCrew() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  /**
   * Delete a crew
   */
  deleteCrew(crewId: number) {
    this.crewService.deleteCrew(this.site.code, crewId).subscribe(() => {
      this.loadCrews();
      this.toastrService.success(
        this.translate.instant('features.crew.CREW_SUCCESSFULLY_DELETED')
      );
    });
  }

  /**
   * Show crew profile
   * @param crew to show
   */
  showCrew(crew: Crew) {
    if (crew) {
      this.router.navigate(['profile', crew.id], { relativeTo: this.route });
    }
  }

  confirmRemoveCrew(crewId: number, event: any) {
    event.stopImmediatePropagation();

    this.dialogService
      .confirm(
        this.translate.instant('features.crew.CONFIRM_REMOVE_DIALOG.TITLE'),
        this.translate.instant(
          'features.crew.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteCrew(crewId);
        }
      });
  }

  getUsermembership(crew: Crew) {
    let membershipType = '';

    const isUsermember: any = crew.members?.filter(
      (member: any) => member.objectId === this.profile.objectId
    );

    const isUsermembershipPending: any = crew.memberApprovals?.filter(
      (member: any) => member.requestorObjectId === this.profile.objectId
    );

    if (isUsermember.length > 0) {
      membershipType = 'Member';
    } else if (isUsermembershipPending.length > 0) {
      membershipType = 'Pending';
    } else {
      membershipType = 'Not a member';
    }

    return membershipType;
  }

  isNotMember(crew: Crew) {
    let isNotMember = true;

    const isUsermember: any = crew.members?.filter(
      (member: any) => member.objectId === this.profile.objectId
    );

    const isUsermembershipPending: any = crew.memberApprovals?.filter(
      (member: any) => member.requestorObjectId === this.profile.objectId
    );

    if (isUsermember.length > 0 || isUsermembershipPending.length > 0) {
      isNotMember = false;
    }

    return isNotMember;
  }

  isMember(crew: Crew) {
    let isMember = false;

    const isUsermember: any = crew.members?.filter(
      (member: any) => member.objectId === this.profile.objectId
    );

    if (isUsermember.length > 0) {
      isMember = true;
    }

    return isMember;
  }

  confirmJoinCrew(event: any, id: number) {
    event && event.stopImmediatePropagation();

    const dialogRef = this.dialog.open(JoinCrewComponent, {
      width: '550px',
      height: '350px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.joinCrew(id, result);
      }
    });
  }

  joinCrew(id: number, comment: string) {
    this.crewService
      .joinCrew(id, this.site.code, this.profile.objectId, comment)
      .subscribe((result) => {
        this.loadCrews();
      });
  }

  confirmLeaveCrew(event: any, id: number) {
    event && event.stopImmediatePropagation();

    const dialogRef = this.dialog.open(LeaveCrewComponent, {
      width: '550px',
      height: '200px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.leaveCrew(id);
      }
    });
  }

  leaveCrew(id: number) {
    this.crewService.leaveCrew(id, this.site.code).subscribe((result) => {
      this.loadCrews();
    });
  }


}
