import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  Category,
  Crew,
  PaginatedItems,
  Equipment,
  Reservation,
  UserProfile,
} from 'src/app/data';
import { EquipmentService } from '@app/services/data/equipment.service';
import { CategoryService } from '@app/services/data/category.service';
import { ReservationService } from '@app/services/data/reservation.service';
import { CrewService } from '@app/services/data/crew.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { UserProfileService } from '@app/services/data/user-profile.service';
import {
  map,
  Observable,
  startWith,
  Subject,
  takeUntil,
  debounceTime,
} from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';

@Component({
  selector: 'app-reservation-search',
  templateUrl: './reservation-search.component.html',
  styleUrls: ['./reservation-search.component.scss'],
})
export class ReservationSearchComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  @Input() toggleSearchFilters!: any;
  @Input() weekDate!: any;
  @Input() users!: any;
  @Input() isTableView!: boolean;

  @Output() reservationChange: EventEmitter<Reservation[]> = new EventEmitter<
    Reservation[]
  >();

  @Output() selectedEquipment: EventEmitter<number | ''> = new EventEmitter<
    number | ''
  >();

  @Output() selectedUser: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();

  @Output() selectedCrew: EventEmitter<number | ''> = new EventEmitter<
    number | ''
  >();

  @Output() selectedCategory: EventEmitter<number | ''> = new EventEmitter<
    number | ''
  >();

  equipments: Equipment[] | undefined;
  categories: Category[] | undefined;
  reservations!: Reservation[];
  crews: Crew[] | undefined;
  equipmentId: any = '';
  crewId: string = '';
  categoryId: string = '';
  startDate = moment().startOf('week').format('YYYY-MM-DD');
  endDate = moment().endOf('week').add(1, 'day').format('YYYY-MM-DD');
  searchText: string = '';
  term = new Subject<string>();
  userId: any = '';
  isAdmin = false;
  isSHowFilters = false;
  isFavorite = false;

  constructor(
    private router: Router,
    private crewService: CrewService,
    private catService: CategoryService,
    private equipmentService: EquipmentService,
    private reservationService: ReservationService,
    private roleUserService: UserRoleService,
    private userProfileService: UserProfileService
  ) {
    super();

    this.term.pipe(debounceTime(1000)).subscribe((val) => {
      this.searchReservations();
    });
  }

  async ngOnInit() {
    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.loadEquipments();
    this.loadCategories();
    this.loadCrews();

    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userId = this.isTableView ? userProfile.id : '';
        this.profile = userProfile;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.weekDate &&
      changes.weekDate.currentValue.startDate &&
      changes.weekDate.currentValue.endDate
    ) {
      this.startDate = changes.weekDate.currentValue.startDate;
      this.endDate = changes.weekDate.currentValue.endDate;
      this.isAdmin && this.clearUser(null);
    }

    if (
      changes.toggleSearchFilters &&
      !changes.toggleSearchFilters.firstChange
    ) {
      this.toggleFilters();
    }

    if (changes.isTableView && changes.isTableView.currentValue === true) {
      this.userId = this.profile.id;
      this.categoryId = '';
      this.crewId = '';
      this.searchText = '';
      this.equipmentId = '';

      this.selectedUser.emit(this.userId ? this.userId : '');
      this.selectedEquipment.emit('');
      this.selectedCrew.emit('');
      this.selectedCategory.emit('');

      this.searchReservations();
    } else if (
      changes.isTableView &&
      changes.isTableView.currentValue === false
    ) {
      this.userId = '';
      this.categoryId = '';
      this.crewId = '';
      this.searchText = '';
      this.equipmentId = '';

      this.selectedUser.emit(this.userId ? this.userId : '');
      this.selectedEquipment.emit('');
      this.selectedCrew.emit('');
      this.selectedCategory.emit('');

      this.searchReservations();
    }
  }

  loadCategories() {
    this.catService
      .getCategories(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadEquipments() {
    this.equipmentService
      .getEquipments(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Equipment>) => {
          return resp.data;
        })
      )
      .subscribe((equipments) => {
        this.equipments = equipments;
      });
  }

  loadCrews() {
    this.crewService
      .getPaginatedCrews(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.crews = crews;
      });
  }

  searchEquipment() {
    this.selectedEquipment.emit(this.equipmentId ? +this.equipmentId : '');
    this.searchReservations();
  }

  searchCategory() {
    this.selectedCategory.emit(this.categoryId ? +this.categoryId : '');
    this.searchReservations();
  }

  searchCrew() {
    this.selectedCrew.emit(this.crewId ? +this.crewId : '');
    this.searchReservations();
  }

  searchUser() {
    this.selectedUser.emit(this.userId ? this.userId : '');
    this.searchReservations();
  }

  searchReservations() {
    this.reservationService
      .searchReservation(
        this.site.code,
        this.searchText,
        this.categoryId,
        this.crewId,
        this.startDate,
        this.endDate,
        this.equipmentId,
        this.userId
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        this.reservations = result.data;
        this.reservationChange.emit(this.reservations);

        this.getDistincsUsers(this.reservations);
      });
  }

  getDistincsUsers(reservations: Reservation[]) {
    this.users = [
      ...new Map(
        reservations.map((item) => [
          item['userId'],
          {
            userId: item.userId,
            userName: item.userName,
          },
        ])
      ).values(),
    ];

    const myUser = this.users.find((user: any) => {
      return user.userId === this.profile.id;
    });

    if (!myUser) {
      this.users.unshift({
        userId: this.profile.id,
        userName: this.profile.name,
      });
    }
  }

  clearEquipment(e: any) {
    e.stopImmediatePropagation();
    this.equipmentId = '';
    this.selectedEquipment.emit('');

    if (this.isTableView && this.userId === '' && this.crewId === '') {
      this.userId = this.profile.id;
      this.selectedUser.emit(this.userId ? this.userId : '');
    }

    this.searchReservations();
  }

  clearSearchText(e: any) {
    e.stopImmediatePropagation();
    this.searchText = '';

    this.searchReservations();
  }

  clearCategory(e: any) {
    e.stopImmediatePropagation();
    this.categoryId = '';

    this.selectedCategory.emit('');

    this.searchReservations();
  }

  clearCrew(e: any) {
    e.stopImmediatePropagation();
    this.crewId = '';

    this.selectedCrew.emit('');

    if (
      this.isTableView &&
      this.equipmentId === '' &&
      this.equipments &&
      this.equipments.length > 0 &&
      this.userId === ''
    ) {
      this.userId = this.profile.id;
      this.selectedUser.emit(this.userId ? this.userId : '');
    }

    this.searchReservations();
  }

  clearUser(e: any) {
    if (e) {
      e.stopImmediatePropagation();
      this.userId = '';

      this.selectedUser.emit('');

      if (
        this.isTableView &&
        this.equipmentId === '' &&
        this.equipments &&
        this.equipments.length > 0 &&
        this.crewId === ''
      ) {
        this.equipmentId = this.equipments[0].id!;
        this.selectedEquipment.emit(this.equipmentId ? +this.equipmentId : '');
      }

      this.searchReservations();
    }
  }

  toggleFilters() {
    this.isSHowFilters = !this.isSHowFilters;
  }
}
