import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import {
  MbscEventcalendarOptions,
  MbscEventcalendarView,
  MbscCalendarEvent,
  Notifications,
  localeEn,
  localeFr,
  setOptions,
} from '@mobiscroll/angular';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, takeUntil } from 'rxjs';
import {
  Crew,
  Equipment,
  Reservation,
  ReservationMobiscroll,
} from 'src/app/data/index';
import { EquipmentService } from '@app/services/data/equipment.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-restriction-calendar',
  templateUrl: './add-restriction-calendar.component.html',
  styleUrls: ['./add-restriction-calendar.component.scss'],
})
export class AddRestrictionCalendarComponent
  extends VfmBaseComponent
  implements OnInit, OnChanges
{
  @ViewChild('eventCalendar', { static: false })
  eventCalendar!: MbscCalendarEvent;

  @Input() selectedDays: string[] = [];
  @Input() selectedhours: any = null;
  @Input() selectedFromDate: any = null;
  @Input() selectedType: string = '';
  @Input() rRule: string = '';

  locale = this.profile.language === 0 ? localeEn : localeFr;
  view = 'day';
  invalidDate: any = [];

  calendarMonthView = {
    responsive: {
      xsmall: {
        view: {
          calendar: { labels: true },
        },
      },
      custom: {
        // Custom breakpoint
        breakpoint: 400,
        view: {
          calendar: { labels: true },
        },
      },
    },
  };

  calendarDailyView = {
    responsive: {
      xsmall: {
        view: {
          schedule: {
            allDay: false,
            type: 'week',
            size: 1,
            startTime: '00:00',
            endTime: '24:00',
          },
        },
      },
    },
  };

  myEvents: MbscCalendarEvent[] = [];

  eventSettings: MbscEventcalendarOptions = {
    theme: 'ios',
    themeVariant: 'light',
    clickToCreate: false,
    dragToCreate: false,
    dragToMove: false,
    dragToResize: false,
    eventDelete: true,
    invalidateEvent: 'strict',
    onEventClick: (event) => {},
    onEventCreate: (event) => {},
    onEventDragEnd: (event) => {},
    onEventCreateFailed: (event) => {},
    onEventUpdateFailed: (event) => {},
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    setOptions(this.calendarDailyView);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDays && changes.selectedDays.currentValue) {
      if (this.selectedhours && this.selectedType) {
        this.setInvalidDate();
      }
    }

    if (changes.selectedhours && changes.selectedhours.currentValue) {
      if (this.selectedDays && this.selectedType) {
        this.setInvalidDate();
      }
    }

    if (changes.selectedType && changes.selectedType.currentValue) {
      if (this.selectedDays && this.selectedhours) {
        this.setInvalidDate();
      }
    }

    if (changes.rRule && changes.rRule.currentValue) {
      if (this.selectedDays && this.selectedhours) {
        this.setInvalidDate();
      }
    }

    if (changes.selectedFromDate && changes.selectedFromDate.currentValue) {
      if (this.selectedDays && this.selectedhours) {
        this.setInvalidDate();
      }
    }
  }

  setInvalidDate() {
    const invalid: any = {};
    invalid.start = moment(this.selectedhours[0], 'HH:mm').format('HH:mm');
    invalid.end = moment(this.selectedhours[1], 'HH:mm').format('HH:mm');
    invalid.title = this.selectedType;
    invalid.type = this.selectedType;
    invalid.recurring = this.rRule;

    if (this.selectedFromDate) {
      const startDate = moment(this.selectedFromDate).format('DD/MM/YY');
      invalid.start = moment(
        `${startDate}T${this.selectedhours[0]}`,
        'DD/MM/YY[T]HH:mm'
      );

      invalid.end = moment(
        `${startDate}T${this.selectedhours[1]}`,
        'DD/MM/YY[T]HH:mm'
      );
    }

    // invalid.recurring = {
    //   repeat: 'weekly',
    //   weekDays: this.selectedDays.join(','),
    //   until: '2023-01-01',
    //   from: '2022-10-10',
    // };

    console.log('invalid', invalid);

    this.invalidDate = [invalid];
  }
}
