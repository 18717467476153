import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../core/services/common/menu.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
  isSidenavCollapse = false;

  constructor(private menuService: MenuService) {
    this.menuService.getModifyToggleSidenav().subscribe((toggle) => {
      this.isSidenavCollapse = toggle;
    });
  }

  ngOnInit(): void {}
}
