import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { ToastrService } from 'ngx-toastr';
import { RejectCrewMemberRequestComponent } from '../reject-crew-member-request/reject-crew-member-request.component';
import { CrewService } from '@app/services/data/crew.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ApprovalStatus } from '@app/enums/approval-status-enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-pending-membership-request',
  templateUrl: './pending-membership-request.component.html',
  styleUrls: ['./pending-membership-request.component.scss'],
})
export class PendingMembershipRequestComponent
  extends VfmBaseComponent
  implements OnInit
{
  @Input() memberApprovals: any[] = [];
  @Output() updateCrew: EventEmitter<boolean> = new EventEmitter<boolean>();

  crewId!: number;

  constructor(
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private crewService: CrewService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const crewId = this.route.snapshot.paramMap.get('id');

    if (crewId) {
      this.crewId = +crewId;
    }
  }

  getDate(dateStr: string) {
    return moment(dateStr).format('DD/MM/YYYY');
  }

  confirmRejectCrewMemberRequest(id: number, event: any) {
    event.preventDefault();

    const dialogRef = this.dialog.open(RejectCrewMemberRequestComponent, {
      width: '550px',
      height: '350px',
      panelClass: 'equipment-select',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.RejectCrewMemberRequest(id, result);
      }
    });
  }

  RejectCrewMemberRequest(id: number, comment: string) {
    this.crewService
      .updateCrewMemberApproval(
        this.crewId,
        this.site.code,
        id,
        comment,
        ApprovalStatus.rejected
      )
      .subscribe((result) => {
        this.toastrService.success(
          this.translate.instant(
            'features.crew.CREW_SUCCESSFULLY_REJECT_REQUEST'
          )
        );

        this.updateCrew.emit(true);
      });
  }

  ApproveCrewMemberRequest(id: number, event: any) {
    event.preventDefault();

    this.crewService
      .updateCrewMemberApproval(
        this.crewId,
        this.site.code,
        id,
        '',
        ApprovalStatus.approved
      )
      .subscribe((result) => {
        this.toastrService.success(
          this.translate.instant(
            'features.crew.CREW_SUCCESSFULLY_APPROVE_REQUEST'
          )
        );

        this.updateCrew.emit(true);
      });
  }
}
