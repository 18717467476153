import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentService } from '@app/services/data/equipment.service';
import { merge, startWith, Subject, takeUntil, switchMap, map } from 'rxjs';
import { Category, PaginatedItems } from 'src/app/data';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService } from '@app/services/common/dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Equipment, Reservation } from 'src/app/data/index';
import * as moment from 'moment';

@Component({
  selector: 'app-restriction',
  templateUrl: './restriction.component.html',
  styleUrls: ['./restriction.component.scss'],
})
export class RestrictionComponent
  extends VfmBaseComponent
  implements OnInit, AfterViewInit
{
  displayedColumns: string[] = [
    'type',
    'startDate',
    'recurrence',
    'startHour',
    'endHour',
    'action',
  ];

  dataSource: Reservation[] = [];

  @ViewChild(MatTable)
  table!: MatTable<Category>;

  pageTitle = this.translate.instant('features.restriction.TITLE_HOME');
  pageIconClass = 'fa-solid fa-calendar-xmark';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  totalCount: number = 0;
  equipId: number | null = null;
  isLoadingResults = true;
  prevLink = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialogService: DialogService,
    private toastrService: ToastrService,
    private equipmentService: EquipmentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.equipId = +this.route.snapshot.paramMap.get('equipmentId')!;
    this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
  }

  ngAfterViewInit(): void {
    this.loadRestrictions();
  }

  loadRestrictions() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.equipmentService.getAvailabilitiesByEquipmentId(
            this.site.code,
            this.equipId!
          );
        }),
        map((resp: PaginatedItems<Reservation>) => {
          this.isLoadingResults = false;
          this.totalCount = resp.count;
          return resp.data;
        })
      )
      .subscribe(
        (data) => {
          this.dataSource = data;
        },
        (err) => {
          this.isLoadingResults = false;
        }
      );
  }

  addRestriction() {
    this.router.navigate(['new'], { relativeTo: this.route });
  }

  editRestriction(restriction: Category) {
    if (restriction) {
      this.router.navigate(['modify', restriction.id], {
        relativeTo: this.route,
      });
    }
  }

  deleteRestriction(restrictionId: number) {
    this.equipmentService
      .deleteAvailability(this.site.code, this.equipId!, restrictionId)
      .subscribe(() => {
        this.loadRestrictions();
        this.toastrService.success(
          this.translate.instant('features.addRestriction.DELETE_SUCCESS')
        );
      });
  }

  confirmRemoveRestriction(restrictionId: number, event: any) {
    event.stopImmediatePropagation();

    this.dialogService
      .confirm(
        this.translate.instant('features.addRestriction.CONFIRM_DELETE.TITLE'),
        this.translate.instant(
          'features.addRestriction.CONFIRM_DELETE.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteRestriction(restrictionId);
        }
      });
  }

  getDate(date: any) {
    return moment(date).format('YYYY-MM-DD');
  }

  getHour(date: any) {
    return moment(date).format('HH:mm');
  }

  getType(typeId: number) {
    let type = '';

    switch (typeId) {
      case 1:
        type = 'ALL RESTRICTED';
        break;

      case 2:
        type = 'CREW RESTRICTED';
        break;

      case 3:
        type = 'SOFT RESTRICTED';
        break;
    }

    return type;
  }
}
