import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ImagePickerData } from '@shared/components/image-picker/image-picker.component';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { Category, Crew, PaginatedItems } from 'src/app/data';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { CategoryService } from '@app/services/data/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from '@app/services/common/dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { EquipmentService } from '@app/services/data/equipment.service';
import { Equipment } from 'src/app/data/Equipment.model';
import { CrewService } from '@app/services/data/crew.service';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-equipment',
  templateUrl: './add-equipment.component.html',
  styleUrls: ['./add-equipment.component.scss'],
})
export class AddEquipmentComponent
  extends VfmBaseComponent
  implements OnInit, AfterViewInit
{
  separatorKeysCodes: number[] = [ENTER, COMMA];
  crewId!: number;
  equipId!: number;
  isFormSaved: boolean = false;
  pageTitle = this.translate.instant('features.addEquipment.TITLE_HOME');
  pageIconClass = 'fa-solid fa-circle-plus';
  equipForm!: FormGroup;
  prevLink = '';
  tagCtrl = new FormControl('');
  filteredTags: Observable<string[]> | undefined;
  tags: string[] = [];
  allTags: string[] = [];
  categories: Category[] | undefined;
  crews: Crew[] | undefined;
  allCrews: Crew[] | undefined;
  sharedCrews: Crew[] | undefined;
  managers = '';
  imageBase64 = '';
  isDuplicated = false;
  isEdit = false;

  @ViewChild('tagInput') tagInput!: ElementRef<HTMLInputElement>;

  constructor(
    private equipService: EquipmentService,
    private crewService: CrewService,
    private catService: CategoryService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {
    super();
    this.filteredTags = this.tagCtrl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) =>
        tag ? this._filter(tag) : this.allTags.slice()
      )
    );
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.tags.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.tagCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter((tag) =>
      tag.toLowerCase().includes(filterValue)
    );
  }

  get equipImageUrlCtrl() {
    return this.equipForm.get('pictureUri') as FormControl;
  }

  /**
   * Return true if the user has not made any changes still unsaved
   */
  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.equipForm.dirty && !this.isFormSaved) {
      return this.dialogService.confirm(
        this.translate.instant(
          'features.addEquipment.CATEGORY_UNSAVED_CHANGES'
        ),
        this.translate.instant('features.addEquipment.CONFIRM_CANCEL')
      );
    }

    return true;
  }

  ngOnInit(): void {
    this.buildEquipForm();
    this.setEventListners();

    const equipId = this.route.snapshot.paramMap.get('id');
    const crewId = this.route.snapshot.paramMap.get('crewId');

    if (crewId) {
      this.crewId = +crewId;
    }

    if (equipId) {
      this.equipId = +equipId;
      this.pageIconClass = 'fa-solid fa-pen';
      this.pageTitle = this.translate.instant(
        'features.addEquipment.TITLE_MODIFY'
      );
      this.loadEquipment();

      if (crewId) {
        this.prevLink = this.router.url.split('/').slice(0, -3).join('/');
      } else {
        this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
      }
    } else {
      if (this.router.url.includes('new')) {
        this.prevLink = this.router.url.split('/').slice(0, -1).join('/');
      } else {
        this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
      }
    }

    if (this.router.url.includes('/modify')) {
      this.isEdit = true;
    }

    if (this.router.url.includes('/duplicate')) {
      this.isDuplicated = true;
      this.pageTitle = this.translate.instant(
        'features.addEquipment.DUPLICATE_EQUIPMENT'
      );
    }

    this.crewCtrl.valueChanges.subscribe(() => {
      this.loadShareCrews();
    });
  }

  ngAfterViewInit(): void {
    this.loadCategories();
    this.loadCrews();
  }

  /**
   * define the form for category entry
   */
  private buildEquipForm() {
    this.equipForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      brand: ['', Validators.required],
      model: ['', Validators.required],
      unit: ['', Validators.required],
      categoryId: ['', Validators.required],
      crew: ['', Validators.required],
      sharedCrewIds: [],
      crewId: [''],
      description: [''],
      pictureUri: [''],
      pictureName: [''],
      jdeNumber: [''],
      deviceId: new FormControl({ value: [''], disabled: true }),
      tags: [],
      isActive: [true],
      isAutoPIEnabled: [false],
    });
  }

  /**
   * Show preview of the load image
   * @param data
   */
  fileChanged(imData: ImagePickerData) {
    if (imData.fileName) {
      this.equipForm.patchValue({
        pictureUri: imData.imgBase64Path,
        pictureName: imData.fileName,
      });
      this.equipForm.markAsDirty();
    }
  }

  /**
   *  Create or update an equipment
   */
  add(event: any = null) {
    event && event.preventDefault();

    this.equipForm.markAllAsTouched();

    if (this.equipForm.invalid) {
    } else {
      const formValues = this.equipForm.getRawValue();

      formValues.tags = this.tags;

      const equipment: Equipment = {
        ...formValues,
      } as Equipment;

      if (this.imageBase64) {
        equipment.pictureUri = this.imageBase64;
      }

      if (this.isDuplicated) {
        equipment.id = null;
      }

      this.equipService
        .saveEquipment(this.site.code, equipment)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.isFormSaved = true;
          this.goToEquipmentList();
        });
    }
  }

  /**
   *
   */
  cancel() {
    this.goToEquipmentList();
  }

  /**
   * Get the form control for the category
   */
  get categoryCtrl() {
    return this.equipForm.get('categoryId') as FormControl;
  }

  /**
   * Get the form control for the crew
   */
  get crewCtrl() {
    return this.equipForm.get('crew') as FormControl;
  }

  /**
   * Get the form control for the crew
   */
  get ShareCrewCtrl() {
    return this.equipForm.get('sharedCrewIds') as FormControl;
  }

  /**
   * Get the form control for the name
   */
  get nameCtrl() {
    return this.equipForm.get('name') as FormControl;
  }

  /**
   * Get the form control for the brand
   */
  get brandCtrl() {
    return this.equipForm.get('brand') as FormControl;
  }

  /**
   * Get the form control for the brand
   */
  get unitCtrl() {
    return this.equipForm.get('unit') as FormControl;
  }

  /**
   * Get the form control for the brand
   */
  get modelCtrl() {
    return this.equipForm.get('model') as FormControl;
  }

  /**
   * Load and set the category data to the form
   */
  loadEquipment() {
    if (this.equipId) {
      this.equipService
        .getEquipmentById(this.equipId, this.site.code)
        .pipe(takeUntil(this.destroy$))
        .subscribe((equip: Equipment) => {
          this.tags = equip.tags;
          this.equipForm.patchValue(equip);
          this.managers = equip.crewManagers.map((c) => c.name).join(',');
          this.crewCtrl.setValue(equip.crewId);
          this.ShareCrewCtrl.setValue(equip.sharedCrewIds);

          if (equip.pictureUri) this.getBase64Image(equip.pictureUri);
        });
    }
  }

  loadCategories() {
    this.catService
      .getCategories(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Category>) => {
          return resp.data;
        })
      )
      .subscribe((categories) => {
        this.categories = categories;
      });
  }

  loadShareCrews() {
    if (this.crewCtrl.value) {
      this.sharedCrews = this.allCrews?.filter((crew) => {
        return crew.id !== this.crewCtrl.value;
      });

      const shareCrews = this.ShareCrewCtrl?.value.filter(
        (shareCrews: number) => {
          return shareCrews !== this.crewCtrl.value;
        }
      );

      this.ShareCrewCtrl.setValue(shareCrews);
    } else {
      this.sharedCrews = this.allCrews;
    }
  }

  loadAllCrews() {
    this.crewService
      .getPaginatedCrews(this.site.code, 0, 0)
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.allCrews = crews;

        this.loadShareCrews();
      });
  }

  loadCrews() {
    this.crewService
      .getPaginatedCrewsByUser(
        this.site.code,
        this.profile.objectId,
        true,
        true,
        false,
        0,
        0
      )
      .pipe(
        takeUntil(this.destroy$),
        map((resp: PaginatedItems<Crew>) => {
          return resp.data;
        })
      )
      .subscribe((crews) => {
        this.crews = crews;

        this.loadAllCrews();

        if (this.crewId) {
          this.crewCtrl.setValue(this.crewId);
        }
      });
  }

  private setEventListners() {
    this.crewCtrl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((crewId) => {
        this.equipForm.controls['crewId'].setValue(crewId);
        const crew = this.crews?.find((cr) => cr.id === crewId);
        if (crew) {
          this.managers = crew.managers?.map((c) => c.name).join(',') || '';
        }
      });
  }

  /**
   * Return to category List
   */
  private goToEquipmentList() {
    this.router.navigate([this.prevLink]);
  }

  private getBase64Image(url: string) {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const dataURL = canvas.toDataURL('image/png');
      this.imageBase64 = dataURL;
    };

    img.src = url;
  }

  deleteEquipment(equipmentId: number) {
    this.equipService
      .deleteEquipment(this.site.code, equipmentId)
      .subscribe(() => {
        this.goToEquipmentList();
        this.toastrService.success(
          this.translate.instant(
            'features.equipment.EQUIPMENT_SUCCESSFULLY_DELETED'
          )
        );
      });
  }

  confirmRemoveEquipment() {
    this.dialogService
      .confirm(
        this.translate.instant(
          'features.equipment.CONFIRM_REMOVE_DIALOG.TITLE'
        ),
        this.translate.instant(
          'features.equipment.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.deleteEquipment(this.equipId);
        }
      });
  }
}
