<div class="bg-gray-100">
    <div class="space-y-6 max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-8 ">

        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Contact Detail</h3>
                    <p class="mt-1 text-sm text-gray-500">Define an Email Address and a Mobile Phone where you can
                        receive notifications</p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form action="#" method="POST">
                        <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-6 sm:col-span-3">
                                <label for="first-name" class="block text-sm font-medium text-gray-700">First
                                    Email</label>
                                <input type="text" name="first-name" id="first-name" autocomplete="given-name"
                                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                            </div>

                            <div class="col-span-6 sm:col-span-3">
                                <label for="last-name" class="block text-sm font-medium text-gray-700">Mobile
                                    Phone</label>
                                <input type="text" name="last-name" id="last-name" autocomplete="family-name"
                                    class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications CREW</h3>
                    <p class="mt-1 text-sm text-gray-500">Decide which communications you'd like to receive and how.</p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form class="space-y-6" action="#" method="POST">
                        <fieldset class="">

                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">Invitation Request
                            </div>
                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="invitation-request-email" value="" class="hidden peer"
                                        required="">
                                    <label for="invitation-request-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="invitation-request-sms" value="" class="hidden peer">
                                    <label for="invitation-request-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="invitation-request-push" value="" class="hidden peer">
                                    <label for="invitation-request-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">Fleet Info (from
                                Auto PI)
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="fleet-info-email" value="" class="hidden peer"
                                        required="">
                                    <label for="fleet-info-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="fleet-info-sms" value="" class="hidden peer">
                                    <label for="fleet-info-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="fleet-info-push" value="" class="hidden peer">
                                    <label for="fleet-info-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">Equipment Info
                                (new, delete,
                                active, inactive)
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="equipment-info-email" value="" class="hidden peer"
                                        required="">
                                    <label for="equipment-info-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="equipment-info-sms" value="" class="hidden peer">
                                    <label for="equipment-info-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="equipment-info-push" value="" class="hidden peer">
                                    <label for="equipment-info-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>

        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications EQUIPMENT</h3>
                    <p class="mt-1 text-sm text-gray-500">Decide which communications you'd like to receive and how.</p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form class="space-y-6" action="#" method="POST">
                        <fieldset class="">

                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">Status Changed
                            </div>
                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="status-changed-email" value="" class="hidden peer"
                                        required="">
                                    <label for="status-changed-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="status-changed-smsm" value="" class="hidden peer">
                                    <label for="status-changed-smsm"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="status-changed-push" value="" class="hidden peer">
                                    <label for="status-changed-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                Warning / Info (from Auto PI)
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="warning-info-email" value="" class="hidden peer"
                                        required="">
                                    <label for="warning-info-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="warning-info-sms" value="" class="hidden peer">
                                    <label for="warning-info-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="warning-info-push" value="" class="hidden peer">
                                    <label for="warning-info-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>

        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications RESERVATION</h3>
                    <p class="mt-1 text-sm text-gray-500">Decide which communications you'd like to receive and how.</p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form class="space-y-6" action="#" method="POST">
                        <fieldset class="">

                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                Created / Edited / Cancelled
                            </div>
                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="created-edited-cancelled-email" value=""
                                        class="hidden peer" required="">
                                    <label for="created-edited-cancelled-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="created-edited-cancelled-sms" value=""
                                        class="hidden peer">
                                    <label for="created-edited-cancelled-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="created-edited-cancelled-push" value=""
                                        class="hidden peer">
                                    <label for="created-edited-cancelled-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                Reminder
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="reminder-email" value="" class="hidden peer" required="">
                                    <label for="reminder-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="reminder-sms" value="" class="hidden peer">
                                    <label for="reminder-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="reminder-push" value="" class="hidden peer">
                                    <label for="reminder-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                    </form>
                </div>
            </div>
        </div>

        <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
            <div class="md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications SITE</h3>
                    <p class="mt-1 text-sm text-gray-500">Decide which communications you'd like to receive and how.</p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <form class="space-y-6" action="#" method="POST">
                        <fieldset class="">

                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                New Category
                            </div>
                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="new-category-email" value="" class="hidden peer"
                                        required="">
                                    <label for="new-category-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="new-category-sms" value="" class="hidden peer">
                                    <label for="new-category-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="new-category-push" value="" class="hidden peer">
                                    <label for="new-category-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                General Info
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="general-info-email" value="" class="hidden peer"
                                        required="">
                                    <label for="general-info-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="general-info-sms" value="" class="hidden peer">
                                    <label for="general-info-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="general-info-push" value="" class="hidden peer">
                                    <label for="general-info-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                Boradcast
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="broadcast-email" value="" class="hidden peer"
                                        required="">
                                    <label for="broadcast-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="broadcast-sms" value="" class="hidden peer">
                                    <label for="broadcast-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="broadcast-push" value="" class="hidden peer">
                                    <label for="broadcast-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>

                        <fieldset class="">
                            <div class="text-base font-medium text-gray-900 mb-4" aria-hidden="true">
                                Site Stats
                            </div>

                            <ul class="grid gap-6 md:grid-cols-3">
                                <li>
                                    <input type="checkbox" id="site-stats-email" value="" class="hidden peer"
                                        required="">
                                    <label for="site-stats-email"
                                        class="inline-flex justify-between items-center py-2 px-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base">
                                                <i class="fa-solid fa-envelope mx-2"></i>
                                                Email
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="site-stats-sms" value="" class="hidden peer">
                                    <label for="site-stats-sms"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-comment-sms mx-2"></i>
                                                SMS
                                            </div>
                                        </div>
                                    </label>
                                </li>
                                <li>
                                    <input type="checkbox" id="site-stats-push" value="" class="hidden peer">
                                    <label for="site-stats-push"
                                        class="inline-flex justify-between items-center p-2 w-full text-gray-500 bg-white rounded-lg border-2 border-gray-200 cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-blue-600 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
                                        <div class="block">
                                            <div class="w-full text-base ">
                                                <i class="fa-solid fa-mobile-screen-button mx-2"></i>
                                                Push Notification
                                            </div>
                                        </div>
                                    </label>
                                </li>
                            </ul>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>

        <div class="flex justify-end">
            <button type="button"
                class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Cancel</button>
            <button type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">Save</button>
        </div>
    </div>
</div>