import { Component, Input, OnInit } from '@angular/core';
import { EquipmentService } from '../../../../core/services/data/equipment.service';
import { VfmBaseComponent } from '../../../VfmBaseComponent';
import { takeUntil } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-equipment-location-history',
  templateUrl: './equipment-location-history.component.html',
  styleUrls: ['./equipment-location-history.component.scss'],
})
export class EquipmentLocationHistoryComponent
  extends VfmBaseComponent
  implements OnInit
{
  @Input() deviceId: string = '';

  geoFences: any;
  momentJS = moment;

  constructor(private equipService: EquipmentService) {
    super();
  }

  ngOnInit(): void {
    this.equipService
      .getGeofencesByDeviceId(this.site.code, this.deviceId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((device: any) => {
        console.log('device', device);
        this.geoFences = device.reverse();
      });
  }
}
