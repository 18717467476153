<div class="px-4 py-2.5 sm:px-6 bg-yellow-default flex justify-between items-center flex-wrap sm:flex-nowrap">
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-calendar-days mr-2"></i>
        {{ 'features.addReservation.RESERVATION_AVAILABILITY' | translate}}
    </h3>
</div>

<div class="mx-auto py-6 px-4 sm:pt-8 pb-8 sm:px-6 lg:max-w-7xl lg:px-8">
    <!-- Product -->
    <div class="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-5 xl:gap-x-16" *ngIf="equipment">
        <!-- Product image -->
        <div class="lg:row-end-1 lg:col-span-3">
            <div class="aspect-w-4 aspect-h-3 rounded-lg bg-gray-100 ">
                <img class="object-center" [src]="equipment.pictureUri || './assets/img/agnico-eagle-mobile_logo.png'"
                    alt="">
            </div>
        </div>

        <!-- Product details -->
        <div class="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-3 lg:col-span-4">
            <div class="flex flex-col-reverse">
                <div class="mt-4">
                    <h1 class="text-2xl mb-3 font-extrabold tracking-tight text-gray-900 sm:text-3xl">{{ equipment.name
                        }}</h1>
                    <div class="flex">
                        <div class="mr-6">{{ 'features.addReservation.CREW' | translate}}:</div>
                        <div class="text-gray-500">
                            {{ crew && crew.name }}
                        </div>
                    </div>
                    <div class="flex">
                        <div class="mr-5">{{ 'features.addReservation.BRAND' | translate}}:</div>
                        <div class=" text-gray-500">{{ equipment.brand }}</div>
                    </div>
                    <div class="flex">
                        <div class="mr-5">{{ 'features.addReservation.MODEL' | translate}}:</div>
                        <div class=" text-gray-500">{{ equipment.model }}</div>
                    </div>

                    <p class="text-sm text-gray-500 mt-2">

                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="calendar mt-6 shadow-lg">

        <mbsc-eventcalendar [locale]="locale" #eventCalendar [data]="myEvents" [options]="eventSettings"
            [invalid]="invalidDate" [headerTemplate]="customTemplate">
            <ng-template #customTemplate>
                <mbsc-calendar-nav class="cal-header-nav"></mbsc-calendar-nav>
                <div class="cal-header-picker ml-8 hidden sm:block ">
                    <mbsc-segmented-group themeVariant="light" [(ngModel)]="view" (change)="changeView($event)">
                        <mbsc-segmented value="month">{{ 'features.addReservation.MONTH' | translate}}</mbsc-segmented>
                        <mbsc-segmented value="day">{{ 'features.addReservation.DAY' | translate}}</mbsc-segmented>
                    </mbsc-segmented-group>
                </div>
                <mbsc-calendar-prev class="cal-header-prev"></mbsc-calendar-prev>
                <mbsc-calendar-today class="cal-header-today"></mbsc-calendar-today>
                <mbsc-calendar-next class="cal-header-next"></mbsc-calendar-next>
            </ng-template>
        </mbsc-eventcalendar>

    </div>
</div>