import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';

@Component({
  selector: 'app-join-crew',
  templateUrl: './join-crew.component.html',
  styleUrls: ['./join-crew.component.scss'],
})
export class JoinCrewComponent extends VfmBaseComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<JoinCrewComponent>,
    private formBuilder: FormBuilder
  ) {
    super();
  }

  joinCrewForm!: FormGroup;

  ngOnInit(): void {
    this.buildSiteForm();
  }

  /**
   * define the form
   */
  private buildSiteForm() {
    this.joinCrewForm = this.formBuilder.group({
      comment: ['', Validators.required],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (!this.joinCrewForm.invalid) {
      this.dialogRef.close(this.joinCrewForm.controls['comment'].value);
    }
  }

  /**
   * Get the form control for the comment
   */
  get commentCtrl() {
    return this.joinCrewForm.get('comment') as FormControl;
  }
}
