import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VfmBaseComponent } from '../../../../VfmBaseComponent';
import { CrewService } from '@app/services/data/crew.service';
import { Observable, takeUntil } from 'rxjs';
import { Member, PaginatedItems } from 'src/app/data';
import { ToastrService } from 'ngx-toastr';
import { DialogService } from '@app/services/common/dialog.service';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
})
export class AddMemberComponent extends VfmBaseComponent implements OnInit {
  @ViewChild('drawer') drawer!: MatSidenav;

  pageTitle = this.translate.instant('features.member.MEMBERS');
  user = this.translate.instant('labels.USER');
  prevLink = '';
  pageIconClass = 'fa-solid fa-users';
  form!: FormGroup;
  crewId!: number;
  crewMembers!: Member[];
  selectedMember!: any;
  isMemberSelected = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private crewService: CrewService,
    private toastrService: ToastrService,
    private dialogService: DialogService
  ) {
    super();
    this.prevLink = this.router.url.split('/').slice(0, -2).join('/');
  }

  ngOnInit(): void {
    this.crewId = +this.route.snapshot.paramMap.get('id')!;
    this.buildForm();
    this.loadCrewMembers();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      objectIds: ['', Validators.required],
    });
  }

  loadCrewMembers() {
    this.crewService
      .getCrewMembers(this.crewId, this.site.code)
      .pipe(takeUntil(this.destroy$))
      .subscribe((members: any) => {
        this.crewMembers = members.data;
      });
  }

  addCrewMember() {
    this.form.markAllAsTouched();

    const objectIds = this.form.controls['objectIds'].value.map(
      (a: any) => a.id
    );

    if (objectIds && objectIds.length > 0) {
      this.crewService
        .addCrewMember(this.crewId, this.site.code, objectIds)
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.form.controls['objectIds'].setValue([]);
          this.form.markAsUntouched();
          this.loadCrewMembers();
          this.toastrService.success('The member was successfully added!');
        });
    }
  }

  removeCrewMember(memberId: number) {
    this.crewService
      .deleteCrewMember(this.crewId, this.site.code, memberId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loadCrewMembers();
        this.toastrService.success('The member was successfully removed!');
      });
  }

  confirmRemoveCrewMember(memberId: number, event: any) {
    event.stopImmediatePropagation();

    this.dialogService
      .confirm(
        this.translate.instant(
          'features.addMember.CONFIRM_REMOVE_DIALOG.TITLE'
        ),
        this.translate.instant(
          'features.addMember.CONFIRM_REMOVE_DIALOG.DESCRIPTION'
        )
      )
      .subscribe((result: any) => {
        if (result) {
          this.removeCrewMember(memberId);
        }
      });
  }

  selectMember(member: any) {
    this.isMemberSelected = true;
    this.selectedMember = member;
    this.drawer.toggle();
  }

  closeDrawer() {
    this.drawer.toggle();
  }
}
