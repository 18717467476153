<!-- Description list -->
<div class="profile-detail" *ngIf="userProfile">
    <div class="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">

        <div>
            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                <i class="fa-solid fa-user mr-2"></i>
                {{ 'features.profile.PERSONAL_DETAILS' | translate }}
            </h3>
        </div>

        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.DISPLAY_NAME' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.name }}</dd>
            </div>

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.EMAIL' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.email }}</dd>
            </div>

        </dl>

        <div class="mt-10">
            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                <i class="fa-solid fa-user-tie mr-2"></i>
                {{ 'features.profile.PROFESSIONAL_INFORMATION' | translate }}
            </h3>
        </div>

        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.JOB_TITLE' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.jobTitle || 'N/A' }}</dd>
            </div>

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.DEPARTMENT' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.department || 'N/A' }}</dd>
            </div>

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.PHONE' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.phoneNumber || 'N/A' }}</dd>
            </div>

        </dl>

        <div class="mt-10">
            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                <i class="fa-solid fa-gear mr-2"></i>
                {{ 'features.profile.SETTINGS' | translate }}
                <button *ngIf="isSettingsPage" (click)="showEdit()" type="button"
                    class="ml-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-darker">
                    <i class="fa-solid fa-pen">
                    </i>
                </button>

            </h3>
        </div>

        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.LANGUAGE' | translate }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ getLanguage(userProfile.language) || 'N/A' }}</dd>
            </div>

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.DEFAULT_WORKING_SITE' | translate }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.siteName || 'N/A' }}</dd>
            </div>

            <div class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-800">{{ 'features.profile.DEFAULT_EQUIPMENT_CATEGORY' | translate
                    }}</dt>
                <dd class="mt-1 text-sm text-gray-900">{{ userProfile.categoryName || 'N/A' }}</dd>
            </div>

        </dl>

        <div class="mt-10">
            <h3 class="text-lg leading-6 font-bold text-gray-900 mb-6">
                <i class="fa-solid fa-calendar-days mr-2"></i>
                {{ 'features.profile.MY_WORKING_SHIFT' | translate }}
                <button *ngIf="isSettingsPage" (click)="showEditWorkingShift()" type="button"
                    class="ml-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-darker hover:bg-blue-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-blue-darker">
                    <i class="fa-solid fa-pen">
                    </i>
                </button>
            </h3>
        </div>

        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 lg:grid-cols-2 mt-8">
            <div class="sm:col-span-1">
                <div>{{ userShiftName }}</div>

                <div class="calendar" *ngIf="userProfile.shiftTemplateId">
                    <mbsc-eventcalendar [locale]="locale" [options]="eventSettings"></mbsc-eventcalendar>
                </div>
                <dd *ngIf="!userProfile.shiftTemplateId" class="mt-1 text-sm text-gray-900">N/A</dd>
            </div>
        </dl>
    </div>

</div>