<div class="device-autocomplete-component">
    <label class="crew-managers-title">{{ name }}</label> <sup *ngIf="required"> * </sup>
    <i class="fa-solid fa-circle-info relative ml-1" style="z-index: 10;" matTooltipPosition="right"
        matTooltip="{{ 'features.addCrew.TYPE_MIN_CHAR_USER_SEARCH' | translate }}"></i>
    <mat-form-field class="device-autocomplete pt-0" [formGroup]="form">
        <mat-chip-list #chipList>
            <mat-chip *ngFor="let device of devices" [selectable]="selectable" [removable]="removable"
                (removed)="remove(device)">
                {{device.displayName}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input [disabled]="isInputDisabled" #deviceInput [formControl]="deviceCtrl" [matAutocomplete]="auto"
                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur" />
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option disabled *ngIf="isLoading" class="is-loading">{{ 'features.userAutoComplete.LOADING' | translate
                }}</mat-option>
            <mat-option disabled *ngIf="errorMsg" class="is-loading">{{ errorMsg }}</mat-option>
            <ng-container *ngIf="!isLoading">
                <mat-option *ngFor="let device of filteredDevices" [value]="device" class="device-autocomplete-select">
                    <div>
                        <i class="fa-solid fa-user user-autocomplete-avatar"></i>
                        {{device.vehicle.display}}
                        <span class="device-autocomplete-id">({{device.id}})</span>
                    </div>
                </mat-option>
            </ng-container>
        </mat-autocomplete>
    </mat-form-field>
</div>