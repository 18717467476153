import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Site, PaginatedItems, Timezone } from 'src/app/data';
import { handleError } from './handleError';

export type SiteCreateRequest = Omit<Site, 'id' | 'sitecode'>;
export type SiteUpdateRequest = Omit<Site, 'sitecode'>;

const SITE_API_ENDPOINT = 'site';

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private http: HttpClient) {}

  /**
   * Get PaginatedItems Result
   * @returns an observable of PaginatedItems<Site>
   */
  getSites(pageSize = 10, pageIndex = 0) {
    return this.http
      .get<PaginatedItems<Site>>(
        `${SITE_API_ENDPOINT}/?pageSize=${pageSize}&pageIndex=${pageIndex}`
      )
      .pipe(catchError(handleError));
  }

  /**
   * Get Timezones
   * @returns an observable of Timezones
   */
  getTimezones() {
    return this.http
      .get<Timezone>(`${SITE_API_ENDPOINT}/timezones`)
      .pipe(catchError(handleError));
  }

  /**
   * Save or update a site
   * @param category to save
   * @returns an observable of the saved site
   */
  saveSite(site: Site) {
    if (site.id) {
      return this.updateSite(site);
    }
    return this.createSite(site);
  }

  getSiteById(id: number) {
    return this.http
      .get<Site>(`${SITE_API_ENDPOINT}/${id}`)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param site
   * @returns
   */
  private createSite(site: Site) {
    const newSite: SiteCreateRequest = {
      name: site.name,
      description: site.description,
      code: site.code,
      managerObjectIds: site.managerObjectIds,
      planerObjectIds: site.planerObjectIds,
      shiftTemplateIds: site.shiftTemplateIds,
      timeOffset: site.timeOffset,
    };

    return this.http
      .post<SiteCreateRequest>(`${SITE_API_ENDPOINT}`, newSite)
      .pipe(catchError(handleError));
  }

  /**
   *
   * @param site
   * @returns
   */
  private updateSite(site: Site) {
    const existingSite: SiteUpdateRequest = {
      id: site.id,
      name: site.name,
      description: site.description,
      code: site.code,
      managerObjectIds: site.managerObjectIds,
      planerObjectIds: site.planerObjectIds,
      shiftTemplateIds: site.shiftTemplateIds,
      timeOffset: site.timeOffset,
    };

    return this.http
      .put<SiteUpdateRequest>(`${SITE_API_ENDPOINT}/${site.id}`, existingSite)
      .pipe(catchError(handleError));
  }
}
