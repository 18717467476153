<section class="h-full gradient-form bg-gray-200 md:h-screen">
  <div class="py-12 px-6 h-full">
    <div class="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
      <div class="">
        <div class="block bg-white shadow-lg rounded-lg">
          <div class="lg:flex lg:flex-wrap g-0">
            <div class="pt-10 sm:pt-0 lg:w-6/12 px-4 md:px-0 min-w-[340px]">
              <div class="md:pb-12 md:pt-[76px] md:mx-12">
                <div class="text-center">
                  <img class="mx-auto h-20 w-auto" src="./assets/img/AELogo_1_Standard_YB_B.png" />
                </div>
                <router-outlet></router-outlet>
              </div>
            </div>
            <div class="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none bg-yellow-default">
              <div class="text-black px-4 py-6 mx-auto md:p-12 md:mx-12">
                <img class="mx-auto h-[200px] w-auto" src="./assets/img/ETRS-Logo3_ETRS-03-inv.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>