<aside class="hidden xs:w-25 md:order-first md:flex md:flex-col flex-shrink-0 md:w-70 lg:w-80 border-r border-gray-200">

    <nav class="flex-1 min-h-0 overflow-y-auto shadow" aria-label="Directory">
        <div class="relative">
            <div
                class="z-10 sticky py-4 top-0 border-t border-b border-gray-300 bg-gray-200 px-6 text-sm font-medium text-gray-900">
                {{ 'features.reservation.TITLE_HOME' | translate }}
            </div>

            <div *ngIf="reservations && reservations.length === 0"
                class="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
                <span class="mt-2 block text-sm font-medium text-gray-900"> {{
                    'features.reservation.NO_RESERVATION_FOUND' |
                    translate}} </span>
            </div>

            <ul role="list" class="reservations-list relative z-0 divide-y divide-gray-200"
                style="height:calc(100vh - 262px); overflow-y: auto;">
                <li *ngFor="let reservation of reservations" class="relative overflow-hidden">
                    <div (click)="selectReservation(reservation)"
                        [ngClass]="{'bg-yellow-default selected': reservationId === reservation.id}"
                        class="relative px-6 py-5 flex items-center space-x-3">
                        <div class="flex-shrink-0">
                            <img class="h-10 w-14 rounded"
                                [src]="reservation.equipmentPictureUri || './assets/img/agnico-eagle-mobile_logo.png'"
                                alt="">
                        </div>
                        <div class="flex-1 min-w-0">
                            <a href="javascript:void(0)" class="focus:outline-none">
                                <!-- Extend touch target to entire panel -->
                                <span class="absolute inset-0" aria-hidden="true"></span>
                                <p class="text-sm font-bold text-gray-900 mb-1">
                                    <span class="mt-1.5 h-3 w-3 rounded-full bg-green-500 inline-block"
                                        aria-hidden="true">
                                    </span>
                                    {{ reservation.equipmentName || 'N/A' }}
                                </p>
                                <p class="ml-0.5 text-[12px]">
                                    <i class="fa-solid fa-calendar-plus mr-2"></i>
                                    <span>{{ getDate(reservation.startDate) }}</span>
                                </p>
                                <p class="ml-0.5 text-[12px]">
                                    <i class="fa-solid fa-calendar-xmark mr-2"></i>
                                    <span>{{ getDate(reservation.endDate) }}</span>
                                </p>
                                <p class="mt-1 ml-0.5 text-[12px]">
                                    <i class="fa-solid fa-user mr-2"></i>
                                    <b>{{ reservation.userName }}</b>
                                </p>
                            </a>
                        </div>
                    </div>
                    <div class="banner-container" *ngIf="reservation.rRule">
                        <div class="banner">Recurring</div>
                    </div>
                    <div class="banner-container pending" *ngIf="reservation.approvalStatus === 0">
                        <div class="banner">Pending</div>
                    </div>
                </li>
            </ul>

        </div>
    </nav>
</aside>