<div class="px-4 py-[14px] sm:px-6 bg-yellow-default">
    <h3 class="text-base sm:text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-clipboard-check mr-2 "></i>
        {{ 'features.member.CREW_PENDING_MEMBERSHIP_REQUEST' | translate }}
        <span *ngIf="memberApprovals && memberApprovals.length > 0"
            class="inline-flex items-center justify-center px-4 py-2 -mt-1 font-bold float-right absolute right-[20px] shadow-md leading-none text-red-100 bg-red-600 rounded-full">
            {{memberApprovals.length}}
        </span>
    </h3>
</div>
<div *ngIf="memberApprovals && memberApprovals.length > 0"
    class="border-t border-gray-200 sm:p-0 max-h-[250px] overflow-y-auto shadow w-full">
    <div class="bg-white overflow-hidden">
        <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let member of memberApprovals">
                <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="flex-shrink-0">
                            <i class="fa-solid fa-user h-12 w-12 rounded-full bg-gray-300"
                                style="font-size: 23px; padding-top: 12px; padding-left: 15px;"></i>
                        </div>
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                            <div>
                                <p class="text-sm font-semibold text-blue-darker ">
                                    {{member.requestorName}}
                                </p>
                                <p class="mt-1 flex items-center text-xs text-gray-500">
                                    <i class="fa-solid fa-calendar mr-1.5"></i>
                                    <span class="">{{ getDate(member.requestDate) }}</span>
                                </p>
                                <p class="mt-1 flex items-center text-xs text-gray-500">
                                    <i class="fa-solid fa-comment mr-1.5"></i>
                                    <span class="">{{ member.requestComment }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="button" (click)="ApproveCrewMemberRequest(member.id, $event)"
                            class="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            Approve
                        </button>
                        <button type="button" (click)="confirmRejectCrewMemberRequest(member.id, $event)"
                            class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Reject
                        </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="mb-4 mx-4">
    <div *ngIf="memberApprovals && memberApprovals.length === 0"
        class="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
        <p class="mt-6 block text-sm font-medium text-gray-900"> {{ 'features.member.CREW_HAS_NO_PENDING_MEMBERSHIP' |
            translate}} </p>
        <p class="mt-2 block text-sm font-medium text-gray-500"> &nbsp;</p>
    </div>

    <!-- <a *ngIf="crewMembers && crewMembers.length > 0" href="#" [routerLink]="['members/detail']"
        class="w-full flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
        View all
    </a> -->
</div>