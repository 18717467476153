import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';
import * as video_tutorial_en from 'src/app/json/faq/video_tutorial_en.json';
import * as video_tutorial_fr from 'src/app/json/faq/video_tutorial_fr.json';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-faq-library-video',
  templateUrl: './faq-library-video.component.html',
  styleUrls: ['./faq-library-video.component.scss']
})
export class FaqLibraryVideoComponent implements OnInit {

  constructor(public dialog: MatDialog, private translate: TranslateService) { }

  videoTutorial = Object.assign({},this.translate.currentLang === 'en' ? video_tutorial_en : video_tutorial_fr)

  ngOnInit(): void {
  }
  openDialog(url:string) {
    const dialogRef = this.dialog.open(FaqDialogComponent , {
      width: '1000px',
      data: 
      {
        video:{
          url: url
        }
      }
    }
    );

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
