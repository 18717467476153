<app-page-title [iconClass]="pageIconClass" [title]="pageTitle" [showChevron]=false></app-page-title>
<div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 sm:py-20 page-admin">
    <ul role="list" class="mt-6 border-t border-b border-gray-200 py-6 grid grid-cols-1 gap-6 sm:grid-cols-3">
        <li *ngFor="let menuItem of adminMenu; index as i" class="flow-root"
            [ngClass]="{'pointer-events-none ': menuItem.disabled}">
            <div class="relative -m-2 p-2 flex items-center space-x-4 rounded-xl hover:bg-gray-50">
                <div class="flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-yellow-default">
                    <i [class]="menuItem.iconClass + ' icon-admin-menu text-2xl'"></i>
                </div>
                <div>
                    <h3 class="text-sm font-medium text-gray-900">
                        <a [routerLink]="[menuItem.routerLink]">
                            <span class="absolute inset-0" aria-hidden="true"></span>
                            <b class="text-base">{{ menuItem.label }}</b>
                        </a>
                    </h3>
                </div>
            </div>
        </li>
    </ul>
</div>