import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Equipment, Reservation, UserProfile } from 'src/app/data/index';
import { VfmBaseComponent } from 'src/app/features/VfmBaseComponent';
import { Observable, take, takeUntil } from 'rxjs';
import { UserProfileService } from '@app/services/data/user-profile.service';
import { ReservationService } from '@app/services/data/reservation.service';
import * as moment from 'moment';
import { UserRoleService } from '@app/services/common/user-role.service';
import { ROLES } from '@app/constants/roles.constants';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss'],
})
export class ReservationComponent extends VfmBaseComponent implements OnInit {
  pageIconClass = 'fa-solid fa-calendar-days';
  pageTitle = 'Reservations';
  reservations!: Reservation[];
  reservation!: Reservation;
  reservationId!: number;
  isAdminMenu = false;
  isAdmin = false;
  prevPageLink = '/home';
  startDate = moment().startOf('week').format('YYYY-MM-DD');
  endDate = moment().endOf('week').add(1, 'day').format('YYYY-MM-DD');
  weekDate = '';
  userId: any = '';
  crewId: any = '';
  users!: any[];
  toggleSearchFilters = false;
  equipId: any = '';
  categoryId: any = '';
  isTableView = true;

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private reservationService: ReservationService,
    private roleUserService: UserRoleService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.router.url.includes('/admin')) {
      this.isAdminMenu = true;
      this.prevPageLink = '/admin';
    }

    const roles = this.roleUserService.getRoles() || [];
    const siteManagerRole = ROLES.SITE_MANAGER.replace(
      '{siteId}',
      this.site.id
    );
    const sitePlanerRole = ROLES.SITE_PLANER.replace('{siteId}', this.site.id);
    const requiredRoles = [siteManagerRole, sitePlanerRole, ROLES.ADMIN];

    if (requiredRoles.some((r) => roles.includes(r))) {
      this.isAdmin = true;
    }

    this.userProfileService
      .getMyUserProfile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((userProfile: UserProfile) => {
        this.userId = this.isTableView ? userProfile.id : '';
        this.profile = userProfile;
        this.getReservations();
      });
  }

  getReservations() {
    this.reservationService
      .searchReservation(
        this.site.code,
        '',
        this.categoryId,
        this.crewId,
        this.startDate,
        this.endDate,
        this.equipId,
        this.userId
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe((result: any) => {
        console.log('result', result);
        this.reservations = result.data;

        this.getDistincsUsers(this.reservations);
      });
  }

  selectedReservation(reservation: Reservation) {
    this.reservation = reservation;
  }

  selectedReservationId(id: number) {
    this.reservationId = id;
  }

  deletedReservationId(id: number) {
    this.reservations = this.reservations.filter(
      (reservation) => reservation.id !== id
    );
  }

  isTableViewSelected(isTableView: boolean) {
    this.isTableView = isTableView;
  }

  selectedCategory(categoryId: any) {
    this.categoryId = categoryId;
  }

  selectedEquipment(equipId: any) {
    this.equipId = equipId;
  }

  selectedUser(userId: any) {
    this.userId = userId;
  }

  selectedCrew(crewId: any) {
    this.crewId = crewId;
  }

  reservationSearch(reservations: Reservation[]) {
    this.reservations = reservations;
  }

  selectedWeek(weekDate: any) {
    this.weekDate = weekDate;
    this.startDate = weekDate.startDate;
    this.endDate = weekDate.endDate;

    this.getReservations();
  }

  getDistincsUsers(reservations: Reservation[]) {
    this.users = [
      ...new Map(
        reservations.map((item) => [
          item['userId'],
          {
            userId: item.userId,
            userName: item.userName,
          },
        ])
      ).values(),
    ];

    const myUser = this.users.find((user) => {
      return user.userId === this.profile.id;
    });

    if (!myUser) {
      this.users.unshift({
        userId: this.profile.id,
        userName: this.profile.name,
      });
    }
  }

  showAdd(event: any) {
    event.stopImmediatePropagation();

    if (this.isAdmin) {
      this.router.navigate(['admin/reservation/new']);
    } else {
      this.router.navigate(['reservation/new']);
    }
  }

  toggleFilters(event: any) {
    event.stopImmediatePropagation();

    this.toggleSearchFilters = !this.toggleSearchFilters;
  }
}
