<div class="px-4 py-[14px] sm:px-6 bg-yellow-default relative">
    <h3 class="text-base sm:text-lg leading-6 font-medium text-gray-900">
        <i class="fa-solid fa-clipboard-check mr-2"></i>
        {{ 'features.member.CREW_PENDING_RESERVATION_REQUEST' |
        translate}}
        <span *ngIf="reservationApprovals && reservationApprovals.length > 0"
            class="inline-flex items-center justify-center px-4 py-2 -mt-1 text-base font-bold float-right absolute right-[20px] !sm:relative  shadow-md leading-none text-red-100 bg-red-600 rounded-full">
            {{reservationApprovals && reservationApprovals.length}}
        </span>
    </h3>
</div>
<div class="border-t border-gray-200 sm:p-0 h-[250px] overflow-y-auto w-full"
    *ngIf="reservationApprovals && reservationApprovals.length > 0">
    <div class="bg-white shadow overflow-hidden">
        <ul role="list" class="divide-y divide-gray-200">
            <li *ngFor="let reservationApproval of reservationApprovals" class="relative">
                <div class="flex items-center px-4 py-4 sm:px-6">
                    <div class="min-w-0 flex-1 flex items-center">
                        <div class="flex-shrink-0">
                            <img [src]=" reservationApproval.reservation.equipmentPictureUri || './assets/img/agnico-eagle-mobile_logo.png'"
                                alt="" class=" h-12 w-12 rounded-full">
                        </div>
                        <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-1 md:gap-4">
                            <div>
                                <p class="text-sm font-semibold text-blue-darker ">
                                    {{ reservationApproval.reservation.equipmentName }}
                                </p>
                                <p class="mt-1 text-xs text-gray-500">
                                    <i class="fa-solid fa-calendar mr-1.5"></i>
                                    <span class="font-bold">
                                        {{ getDate(reservationApproval.reservation.startDate) }}
                                    </span>
                                    <span class="ml-1 mr-1"> from: </span>
                                    <span class="font-bold">
                                        {{ getHour(reservationApproval.reservation.startDate) }}
                                    </span>
                                    <span class="ml-1 mr-1"> to:</span>
                                    <span class="font-bold">
                                        {{ getHour(reservationApproval.reservation.endDate) }}
                                    </span>
                                </p>
                                <p class="mt-1 flex items-center text-xs text-gray-500"
                                    *ngIf="reservationApproval.reservation.rRule">

                                    <span class="ml-2.5 mr-1">frequency: </span>
                                    <span class="font-bold">
                                        {{getCustomRule(reservationApproval.reservation.rRule).FREQ}}
                                    </span>
                                    <span class="ml-2.5 mr-1">days: </span>
                                    <span class="font-bold">
                                        {{getCustomRule(reservationApproval.reservation.rRule).BYDAY}}
                                    </span>
                                </p>
                                <p class="mt-1 flex items-center text-xs text-gray-500"
                                    *ngIf="reservationApproval.reservation.rRule">

                                    <span class="ml-2.5 mr-1">Interval: </span>
                                    <span class="font-bold">
                                        {{getCustomRule(reservationApproval.reservation.rRule).INTERVAL}}
                                    </span>
                                    <span class="ml-2.5 mr-1">until: </span>
                                    <span class="font-bold">
                                        {{getCustomRule(reservationApproval.reservation.rRule).UNTIL || 'N/A'}}
                                    </span>
                                </p>
                                <p class="mt-0.5 flex items-center text-xs text-gray-500">
                                    <i class="fa-solid fa-user mr-1.5"></i>
                                    <span class="">{{ reservationApproval.requestorName }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="button" (click)="approveReservationRequest(reservationApproval, $event)"
                            class="mr-2 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                            Approve
                        </button>
                        <button type="button" (click)="confirmRejectReservationRequest(reservationApproval, $event)"
                            class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                            Reject
                        </button>
                    </div>
                </div>
                <div class="banner-container" *ngIf="reservationApproval.reservation.rRule">
                    <div class="banner">Recurring</div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="mb-4 mx-4" *ngIf="reservationApprovals && reservationApprovals.length === 0">
    <div
        class="relative block w-auto border-2 border-gray-300 border-dashed rounded-lg m-4 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
        <i class="fa-solid fa-circle-exclamation mx-auto text-5xl text-gray-400"></i>
        <p class="mt-6 block text-sm font-medium text-gray-900"> {{ 'features.member.CREW_HAS_NO_PENDING_RESERVATION' |
            translate}} </p>
        <p class="mt-2 block text-sm font-medium text-gray-500"> &nbsp; </p>
    </div>
</div>