import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CanComponentDeactivate } from '@app/guards/unsaved-changes.guard';
import { DialogService } from '@app/services/common/dialog.service';
import { Observable, takeUntil } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';
import { TranslateService } from '@ngx-translate/core';
import { Equipment, ReservationMobiscroll, UserProfile } from 'src/app/data';

@Component({
  selector: 'app-reservation-add',
  templateUrl: './reservation-add.component.html',
  styleUrls: ['./reservation-add.component.scss'],
})
export class ReservationAddComponent implements OnInit {
  pageIconClass = 'fa-solid fa-circle-plus';
  pageTitle = this.translate.instant('features.addReservation.TITLE_HOME');
  prevPageLink = '/reservation';
  equipment!: Equipment;
  startDate!: string;
  endDate!: string;
  userName!: string;
  selectedUser!: UserProfile;
  isDisplayShift: boolean = false;
  dateUpdated: any;
  reservationMobiscroll!: ReservationMobiscroll;
  isAdmin = false;
  equipmentId: number | null = null;
  isReservationOverlap = false;
  isRecurring = false;
  toggleReservationLegend = false;
  recurringRule: any = null;
  reccurenceExclusionsArray: any = [];
  modificationDate:any;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const equipmentId = this.route.snapshot.paramMap.get('equipmentId');
    this.modificationDate = this.route.snapshot.queryParamMap.get('dateofmodification');

    if (equipmentId) {
      this.equipmentId = +equipmentId;
    }

    if (this.router.url.includes('/admin')) {
      this.isAdmin = true;

      if (this.equipmentId) {
        this.prevPageLink = this.router.url.split('/').slice(0, -2).join('/');
      } else {
        this.prevPageLink = '/admin/reservation';
      }
    } else {
      if (this.equipmentId) {
        this.prevPageLink = this.router.url.split('/').slice(0, -2).join('/');
      } else {
        this.prevPageLink = '/reservation';
      }
    }
  }

  selectUsername(username: string) {
    this.userName = username;
  }

  selectUser(user: UserProfile) {
    this.selectedUser = user;
  }

  selectEquipment(equipment: Equipment) {
    this.equipment = equipment;
  }

  selectEndDate(endDate: string) {
    this.endDate = endDate;
  }

  selectStartDate(startDate: string) {
    this.startDate = startDate;
  }

  selectDisplayShift(isDisplayShift: boolean) {
    this.isDisplayShift = isDisplayShift;
  }

  selectRecurringRule(event: any) {
    console.log('HELLO HERE WE ARE!', event);
    this.recurringRule = event;
  }

  recurring(event: any) {
    this.isRecurring = event;
  }

  reccurenceExclusions(event: any) {
    this.reccurenceExclusionsArray = event;
  }

  udpateDate(date: any) {
    this.dateUpdated = date;
  }

  reservationOverlap(isReservationOverlap: any) {
    this.isReservationOverlap = isReservationOverlap;
  }

  toggleLegend(event: any) {
    event.stopImmediatePropagation();

    this.toggleReservationLegend = !this.toggleReservationLegend;
  }
}
